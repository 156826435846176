import { Box } from '@mui/system';
import { Colors } from '../../shared/colors';

interface StatusTraducaoIaProps {
  status: boolean;
}

export function StatusTraducaoIa(props: StatusTraducaoIaProps) {
  return (
    <>
      {!props.status ? (
        <Box sx={{ color: Colors.gray6, fontWeight: '600' }}>• Não</Box>
      ) : (
        <Box sx={{ color: 'lightgreen', fontWeight: '600' }}>• Sim</Box>
      )}
    </>
  );
}
