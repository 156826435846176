import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { GeralWrapper, Header, HeaderFunctions } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { Routes } from '../../routes';
import useProcessoService from '../../services/processo.service';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridAndamentos } from './GridAndamentos';

export const Andamentos = () => {
  const [clientSearch, setClientSearch] = useState('');

  const { obterGridAndamento } = useProcessoService();

  const carregar = (
    query: OptGridRequest,
    ClientSearchRequest: PaginatedSearchRequest = { termoBuscado: clientSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: ClientSearchRequest,
    };
    return obterGridAndamento(request);
  };

  function ProcurarCliente(data?: string) {
    setClientSearch(data ?? '');
  }

  // function refreshGrid() {
  //   if (ref.current) {
  //     ref.current.refresh();
  //   }
  // }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [clientSearch]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Andamentos" goBackRoute={Routes.VisaoGeral.Principal} />
      <GeralWrapper>
        <OptLayoutProvider theme={Theme().insideTheme}>
          <Header>
            <h3>Andamentos</h3>
            <HeaderFunctions>
              <OptSearchField placeholder="Buscar por processo" onSearch={ProcurarCliente} />
            </HeaderFunctions>
          </Header>
        </OptLayoutProvider>
        <GridAndamentos carregar={carregar} ref={ref} />
      </GeralWrapper>
    </OptSideLayoutContent>
  );
};
