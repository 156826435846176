import { debounce, Grid, TextField } from '@mui/material';
import { OptGridRef } from '@optsol/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormAutoComplete } from '../../components/Form/FormAutocomplete';
import { Advogado } from '../../models/Advogado/Advogado.model';
import { AdvogadoSearchResponse } from '../../models/dtos/AdvogadoSearchResponse.model';
import { AdicionarAdvogadoProcessoModel } from '../../models/Processo/Processo.model';
import useAdvogadoService from '../../services/advogado.service';
import useProcessoService from '../../services/processo.service';
import { GridEditarAdvogado } from './GridEditarAdvogado';

interface AndamentoFormProps {
  toggleDrawer: () => void;
  processoId: string;
}

export function EditarAdvogadoForm({ toggleDrawer, processoId }: AndamentoFormProps) {
  const [searchValue, setSearchValue] = useState<string>();
  const [responseOptions, setResponseOptions] = useState<readonly Advogado[]>([]);
  const [advogadosCadastrados, setAdvogadosCadastrados] = useState<AdvogadoSearchResponse[]>([]);

  const { enviarAdvogado, obterAdvogadosProcesso, removerAdvogadosProcesso } = useProcessoService();
  const { obterGridAdvogados } = useAdvogadoService();

  const { control } = useForm<AdicionarAdvogadoProcessoModel>({
    // defaultValues: defaultAndamento,
    // resolver: yupResolver(ProcessoFormModelSchema),
  });

  async function adicionarAdvogados(props: string) {
    try {
      await enviarAdvogado(processoId, props);
      adicionarAdvogadosGrid();
    } catch (error) {
      console.log(error);
    }
  }

  function obterAdvogados() {
    obterGridAdvogados({
      page: 0,
      pageSize: 50,
      search: { termoBuscado: searchValue ?? '' },
    })
      .then((res) => {
        compararAdvogados(res.data, advogadosCadastrados);
      })
      .catch((err) => {
        console.log(err);
        setResponseOptions([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  async function compararAdvogados(array1: Advogado[], array2: Advogado[]) {
    const uniqueAdvogados: Advogado[] = [];
    try {
      adicionarAdvogadosGrid();
      // Itera sobre o primeiro array de advogados e verifica se o advogado não está presente no segundo array
      for (const advogado of array1) {
        if (!array2.some((a) => a.cpf === advogado.cpf) && !uniqueAdvogados.some((a) => a.cpf === advogado.cpf)) {
          uniqueAdvogados.push(advogado);
        }
      }
    } finally {
      setResponseOptions(uniqueAdvogados);
    }
  }
  async function removerAdvogado(advogadoId: string) {
    try {
      await removerAdvogadosProcesso(processoId, advogadoId);
      ref.current?.refresh();
      adicionarAdvogadosGrid();
    } catch {
      console.log('error');
    }
  }
  async function adicionarAdvogadosGrid() {
    const data = await obterAdvogadosProcesso(processoId);

    setAdvogadosCadastrados(data.data);
  }

  useEffect(() => {
    obterGridAdvogados({
      page: 0,
      pageSize: 50,
      search: { termoBuscado: searchValue ?? '' },
    })
      .then((res) => {
        compararAdvogados(res.data, advogadosCadastrados);
      })
      .catch((err) => {
        console.log(err);
        setResponseOptions([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    refreshGrid();
  }, [advogadosCadastrados, searchValue]);

  const changeHandler = useCallback(
    (value: any) => {
      setSearchValue(value);
    },
    [searchValue],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const ref = useRef<OptGridRef>();

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }

  useEffect(() => {
    adicionarAdvogadosGrid();
  }, []);
  return (
    <>
      <Grid container item columnSpacing={5} rowSpacing={2} px={2.6} display={'flex'} justifyContent={'space-between'}>
        <Grid item xs={12} mt={4}>
          <h3 style={{ marginTop: '-4px' }}>Adicionar advogado:</h3>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Controller
            name="advogadoId"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <FormAutoComplete
                onOpen={() => obterAdvogados()}
                options={responseOptions}
                noOptionsText="Nenhum resuldado encontrado"
                value={value ?? null}
                onBlur={onBlur}
                onChange={(event, value: any) => {
                  onChange(value);
                  if (value) {
                    adicionarAdvogados(value.id);
                  }
                }}
                onInputChange={(event, value) => {
                  debounceChangeHandler(value);
                }}
                getOptionLabel={(option: any) => `${option.nome} | ${option.oab.toUpperCase()}`}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder="Buscar por advogado"
                    label="Buscar por advogado"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <GridEditarAdvogado data={advogadosCadastrados} ref={ref} removerAdvogado={removerAdvogado} />
        </Grid>
      </Grid>
    </>
  );
}
