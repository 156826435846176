import * as Yup from 'yup';

export interface Nps {
  id: string;
  pergunta: string | null;
}

export type NpsFormModel = Partial<Nps>;

export const NPS_DEFAULT: NpsFormModel = {
  id: '',
  pergunta: '',
};

export const NpsFormModelSchema: Yup.SchemaOf<NpsFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  pergunta: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
});
