import React from 'react';

interface Props {
  tipo: number;
}
export const TipoDocumentoMask = ({ tipo }: Props) => {
  const render = () => {
    if (tipo === 1) return 'Petições';
    if (tipo === 2) return 'Procurações';
    if (tipo === 3) return 'Provas';
    if (tipo === 4) return 'Comprovantes';
  };

  return <>{render()}</>;
};
