import { mdiWindowClose } from '@mdi/js';
import { Box, Grid, Typography } from '@mui/material';
import { IconButton } from '../../components/Button/IconButton';
import { CompromissoSearchResponse } from '../../models/dtos/AgendamentoSearchResponse.model';
import { Colors } from '../../shared/colors';
import * as S from './index';

interface Props {
  compromisso: CompromissoSearchResponse[] | null;
  removerCompromisso: (id: string) => void;
  /*   
  marcarCompromisso: (id: string) => void;
  totalNotificacoes: (props: any) => void; */
  loading: boolean;
}

const CompromissosList = ({
  compromisso: Compromisso,
  removerCompromisso,
  /*   removerCompromisso,
  marcarCompromisso,
  totalNotificacoes, */
  loading,
}: Props) => {
  /*   const themeColor = Theme().insideTheme.light?.primary; */

  /*   const dummy = useRef() as React.MutableRefObject<HTMLInputElement>;

  const isVisible = useOnScreen(dummy); */

  /*   useEffect(() => {
    if (isVisible) {
      totalNotificacoes((prev: any) => prev + 10);
    }
  }, [isVisible, totalNotificacoes]); */

  const getDateStatus = (date: Date) => {
    const currentDate = new Date();
    const inputDateTime = date.getTime();
    const currentDateTime = currentDate.getTime();

    if (inputDateTime < currentDateTime) {
      return 'Past';
    } else if (inputDateTime > currentDateTime) {
      return 'Future';
    } else {
      return 'Present';
    }
  };

  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'}>
        {Compromisso
          ? Compromisso.map((item, index) => {
              const bgColor = index % 2 === 0 ? Colors.gray10 : Colors.white;
              const dataFormatInicio = new Date(item.inicio);
              const dataFormatTermino = new Date(item.termino);
              const statusDate = getDateStatus(dataFormatInicio);
              return (
                <S.styledBox container key={item.id}>
                  <Grid item xs={11.3}>
                    <Box bgcolor={bgColor} key={item.id} display="flex" height="100%">
                      <Box
                        sx={{
                          backgroundColor: `${
                            statusDate === 'Past'
                              ? Colors.gray7
                              : statusDate === 'Present'
                              ? Colors.yellow
                              : statusDate === 'Future'
                              ? Colors.green
                              : ''
                          }`,
                          height: '100%',
                          width: '1%',
                          border: `${
                            statusDate === 'Past'
                              ? Colors.gray7
                              : statusDate === 'Present'
                              ? Colors.yellow
                              : statusDate === 'Future'
                              ? Colors.green
                              : ''
                          }`,
                        }}></Box>
                      <Grid container pt={1} pb={2} sx={{ paddingLeft: '0.5rem' }}>
                        <Grid
                          xs={8}
                          display="flex"
                          sx={{
                            alignItems: 'start',
                            justifyContent: 'flex-end',
                            flexDirection: 'column',
                            gap: '3',
                          }}>
                          <p>
                            <Typography fontWeight={800} textOverflow="ellipsis">
                              {item.tipo}
                            </Typography>
                          </p>
                          <Typography fontSize={15}>
                            Início:{' '}
                            {dataFormatInicio.getDate() === new Date().getDate()
                              ? dataFormatInicio.toLocaleTimeString('pt-Br', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                              : dataFormatInicio.toLocaleTimeString('pt-Br', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                          </Typography>
                        </Grid>
                        <Grid xs={4} display="flex" alignItems="flex-end">
                          <Typography fontSize={15}>
                            Término:{' '}
                            {dataFormatTermino.getDate() === new Date().getDate()
                              ? dataFormatTermino.toLocaleTimeString('pt-Br', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                              : dataFormatTermino.toLocaleTimeString('pt-Br', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={0.7} sx={{ backgroundColor: bgColor }}>
                    <S.styledDiv color={bgColor}>
                      <IconButton
                        size={0.8}
                        icon={mdiWindowClose}
                        onClick={() => {
                          removerCompromisso(item.id);
                        }}
                        color={'#ff8080'}
                      />
                    </S.styledDiv>
                  </Grid>
                </S.styledBox>
              );
            })
          : null}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '0.5rem',
        }}>
        {/* <S.styledButton onClick={() => setTotalNotificacoes(totalNotificacoes + 10)} color={themeColor}>
          Mostrar mais notificações
        </S.styledButton> */}
        {/* <div ref={dummy}>{loading ? <OptLoading size={20} /> : null}</div> */}
      </Box>
    </Box>
  );
};

export default CompromissosList;
