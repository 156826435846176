import ptLocale from '@fullcalendar/core/locales/pt';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box } from '@mui/material';
import { OptGridRef, OptLoading } from '@optsol/react';
import { transparentize } from 'polished';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { AgendamentoFormModel, EventoModel } from '../../models/Agendamento/Agendamento.model';
import useAgendamentoService from '../../services/agendamento.service';
import { tolocalISOString } from '../../shared/functions';
import Theme from '../../shared/theme';

export interface CalendarioProps {
  editarEvento: (id: string) => void;
  criarAgendamento: (dataInicio: Date, dataTermino?: Date) => void;
}

export const Calendario = forwardRef<OptGridRef | undefined, CalendarioProps>(
  ({ editarEvento, criarAgendamento }, ref) => {
    const [eventos, setEventos] = useState<EventoModel[]>([]);

    const { obterCompromissosMes, alterar } = useAgendamentoService();

    const themeColor = Theme().insideTheme.light?.primary;
    const todayCellColor = transparentize(0.75, themeColor!);

    const onDrag = async (id: string, data: AgendamentoFormModel) => {
      let submitData = data;

      const dataCopy: any = data;
      const inicioFormatado = tolocalISOString(dataCopy.inicio);
      const terminoFormatado = tolocalISOString(dataCopy.termino);
      submitData.inicio = new Date(inicioFormatado);
      submitData.termino = new Date(terminoFormatado);

      try {
        await alterar(id, submitData);
      } catch {
        console.log('error');
      }
    };

    async function obterCompromissos(dataInicio?: Date, dataTermino?: Date) {
      const data = await obterCompromissosMes({
        inicio: dataInicio ? dataInicio : new Date(),
        termino: dataTermino ? dataTermino : new Date(),
      });
      data.data.forEach((props) => {
        return setEventos((prev) => [
          ...prev,
          {
            title: props.tipo,
            start: props.inicio,
            end: props.termino,
            id: props.id,
            clienteId: props.clienteId,
            processoId: props.processoId,
          },
        ]);
      });
    }

    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    useEffect(() => {
      document.documentElement.style.setProperty('--fc-today-bg-color', todayCellColor);
      //document.documentElement.style.setProperty('--fc-button-bg-color', themeColor!);
      //document.documentElement.style.setProperty('--fc-button-active-bg-color', todayCellColor);
    }, [todayCellColor]);

    useEffect(() => {
      obterCompromissos();
    }, []);

    return (
      <Box marginY="auto" width={1}>
        <FullCalendar
          contentHeight={windowSize.current[1] / 1.4}
          locale={ptLocale}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="timeGridWeek"
          weekends={false}
          select={(data) => {
            criarAgendamento(data.start, data.end);
          }}
          slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }}
          eventDrop={(event) => {
            const eventData: any = event;
            console.log(eventData);
            onDrag(eventData.event._def.publicId, {
              inicio: event.event.start,
              termino: event.event.end,
              id: event.event._def.publicId,
              clienteId: event.event._def.extendedProps.clienteId,
              processoId: event.event._def.extendedProps.processoId,
              tipo: event.event._def.title,
            });
          }}
          eventResize={(event) => {
            const eventData: any = event;
            console.log(eventData.event.end);
            onDrag(eventData.event._def.publicId, {
              inicio: event.event.start,
              termino: event.event.end,
              id: event.event._def.publicId,
              clienteId: event.event._def.extendedProps.clienteId,
              processoId: event.event._def.extendedProps.processoId,
              tipo: event.event._def.title,
            });
          }}
          events={eventos}
          datesSet={(event) => {
            obterCompromissos(event.start, event.end);
          }}
          themeSystem="standard"
          selectable
          loading={(isLoading) => {
            if (isLoading) return <OptLoading />;
          }}
          allDaySlot={false}
          droppable
          editable
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth',
          }}
          eventClick={(data) => editarEvento(data.event._def.publicId)}
        />
      </Box>
    );
  },
);
