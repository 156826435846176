import { Box } from '@mui/material';
import { OptGridRef, OptLayoutProvider, OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import InfoCabecalho from '../../../components/Layout/InfoCabecalho';
import InfoFinanceiro from '../../../components/Layout/InfoFinanceiro';
import { GeralWrapper } from '../../../components/Layout/Layout';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { FileModel } from '../../../models/Advogado/File.model';
import { Documento } from '../../../models/Documento/Documento.model';
import { FinanceiroSearchResponse } from '../../../models/dtos/FinanceiroSearchResponse.model';
import { Routes } from '../../../routes';
import useFinanceiroService from '../../../services/financeiro.service';
import { Colors } from '../../../shared/colors';
import { objectToFormData } from '../../../shared/functions';
import Theme from '../../../shared/theme';
import { GridResumo } from './GridResumo';
import * as S from './Styles';

export default function FinanceiroDetalhes() {
  const [gridColumnsData, setGridColumnsData] = useState<FileModel[]>([]);
  const [gridFile, setGridFile] = useState<File[]>([]);
  const [conteudo, setConteudo] = useState<FinanceiroSearchResponse>();
  const [arquivosGrid, setArquivosGrid] = useState<Documento[]>();

  const { id } = useParams<{ id: string }>();
  const { obter, enviarDocumento, obterTodosDocumentos, baixarDocumento, removerDocumento } = useFinanceiroService();

  async function obterDocumentos() {
    try {
      const documentosData = await obterTodosDocumentos(id);
      setArquivosGrid(documentosData.data);
    } finally {
      refreshGrid();
    }
  }

  async function removerDocumentoGrid(idDocumento: string) {
    try {
      await removerDocumento(id, idDocumento);
      obterDocumentos();
    } catch (error) {
      console.log(error);
    }
  }

  async function baixarDocumentoGrid(idDocumento: string, documentoNome: string) {
    try {
      baixarDocumento(id, idDocumento, documentoNome);
    } catch (error) {
      console.log(error);
    }
  }
  async function obterFinanceiro() {
    const data = await obter(id);
    setConteudo(data.data);
  }
  const handleChange = (files: File[]) => {
    const newFiles = files.map(async (data: File) => {
      const formData = objectToFormData({
        file: data,
      });
      return enviarDocumento(id, formData);
    });
    Promise.all(newFiles).then(() => obterDocumentos());
  };

  function modelTransform(props: File[]) {
    const newModel = [];
    for (let i = 0; i < props.length; i++) {
      newModel.push({
        id: '',
        file: props[i],
      });
    }
    return newModel;
  }
  useEffect(() => {
    const columnData = modelTransform(gridFile);
    setGridColumnsData([...gridColumnsData, ...columnData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFile]);

  const ref = useRef<OptGridRef>();
  const refreshGrid = () => {
    if (ref.current) {
      ref.current.refresh();
    }
  };

  useEffect(() => {
    obterFinanceiro();
    obterDocumentos();
  }, []);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Resumo financeiro" goBackRoute={Routes.Financeiro.FinanceiroGrid} />
      <OptLayoutProvider theme={Theme().insideTheme}>
        <GeralWrapper>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
            <Box>
              <p style={{ color: Colors.gray5 }}>
                Cliente: <strong style={{ color: Colors.black }}>{conteudo?.nomeCliente}</strong>
              </p>
              <p style={{ color: Colors.gray5 }}>
                Processo: <strong style={{ color: Colors.black }}>{conteudo?.numeroProcesso}</strong>
              </p>
            </Box>
            <S.InputDiv>
              <input
                type="file"
                style={{ display: 'none' }}
                id="contained-button-file"
                multiple={true}
                onChange={(e) => {
                  if (e.target.files) {
                    const fileArray = Array.from(e.target.files);
                    handleChange(fileArray);
                  }
                }}
              />
              <label htmlFor="contained-button-file">
                <Button span label="Adicionar documentos" variant="primary" />
              </label>
            </S.InputDiv>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <div>
              <InfoCabecalho titulo={'Resumo Financeiro'} />
              {conteudo ? <InfoFinanceiro data={conteudo} /> : <OptLoading />}
            </div>
            {arquivosGrid ? (
              <GridResumo
                ref={ref}
                data={arquivosGrid}
                removerDocumento={removerDocumentoGrid}
                baixarDocumento={baixarDocumentoGrid}
              />
            ) : (
              <OptLoading />
            )}
          </Box>
        </GeralWrapper>
      </OptLayoutProvider>
    </OptSideLayoutContent>
  );
}
