import { mdiDelete, mdiPencil } from '@mdi/js';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { PerguntasFrequentesSearchResponse } from '../../models/dtos/PerguntasFrequentesSearchResponse.model';
import { PerguntasFrequentes } from '../../models/PerguntasFrequentes/PerguntasFrequentes.model';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    PerguntasFrequentesSearchResponse?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<PerguntasFrequentesSearchResponse>>;

  removerPergunta: (perguntaId: string) => void;
  editarPergunta(perguntaId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridPerguntasFrequentes = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, editarPergunta, removerPergunta }, ref) => {
    const columns: OptGridColumn<PerguntasFrequentes>[] = [
      {
        title: 'id',
        field: 'id',
        hidden: true,
      },

      {
        title: 'Perguntas',
        field: 'pergunta',
      },
      {
        title: 'Respostas',
        field: 'resposta',
      },
    ];

    return (
      <>
        <OptLayoutProvider theme={Theme().insideTheme}>
          <OptGrid
            title=""
            ref={ref as any}
            data={carregar}
            options={options}
            columns={columns}
            headerTitlePosition="start"
            actionsPosition={'end'}
            actions={[
              (rowData) => ({
                icon: { path: mdiPencil },
                tooltip: 'Editar',
                onClick: () => editarPergunta(rowData.id),
                disabled: false,
              }),
              (rowData) => ({
                icon: { path: mdiDelete },
                tooltip: 'Deletar',
                onClick: () => removerPergunta(rowData.id),
                disabled: false,
              }),
            ]}
          />
        </OptLayoutProvider>
      </>
    );
  },
);
