import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Colors } from '../../shared/colors';

interface ButtonMuiProps extends LoadingButtonProps {
  $backgroundColor: string | undefined;
}

export default function ButtonMui({ children, ...props }: PropsWithChildren<ButtonMuiProps>) {
  return <Button {...props}>{children}</Button>;
}

const Button = styled(LoadingButton)<ButtonMuiProps>`
  color: white;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  text-transform: capitalize;
  font-size: 15px;
  &:hover {
    background-color: ${({ $backgroundColor }) => $backgroundColor};
  }
  &:disabled {
    background-color: ${Colors.gray8};
  }
`;
