import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, CircularProgress } from '@mui/material';
import { OptLayoutProvider, OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useParams } from 'react-router-dom';
import ButtonMui from '../../../components/Button/ButtonMui';
import { Header } from '../../../components/Layout/Layout';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { AssinaturaSearchResponse } from '../../../models/dtos/AssinaturaSearchResponse';
import { Routes } from '../../../routes';
import useAssinaturaSerice from '../../../services/assinatura.service';
import { Colors } from '../../../shared/colors';
import Theme from '../../../shared/theme';

export const DocumentoAssinaturas = () => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [assinatura, setAssinatura] = useState<AssinaturaSearchResponse>();
  const [file, setFile] = useState<string>();

  const { id } = useParams<{ id: string }>();

  const { buscarDocumento, assinarEnviar, obter } = useAssinaturaSerice();

  async function obterAssinatura(id: string) {
    const dataDocumento = await obter(id);
    const data = await buscarDocumento({
      documentoId: id,
    });
    const files = new File([data], 'file.pdf');
    const url = window.URL.createObjectURL(files);
    setFile(url);
    setAssinatura(dataDocumento.data);
  }

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  function nextPage() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }
  function prevPage() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }
  useEffect(() => {
    obterAssinatura(id);
  }, []);

  async function assinarDocumento(props: string) {
    try {
      const link: any = await assinarEnviar({
        documentoId: props,
      });
      window.open(link.data.urlAssinatura);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <OptSideLayoutContent>
      <Toolbar title="Assinaturas" goBackRoute={Routes.AssinaturaEletronica.Assinaturas} />
      <OptSideLayoutContent>
        <OptLayoutProvider theme={Theme().insideTheme}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: ' 20px 210px',
              backgroundColor: Colors.gray10,
              margin: '-25px 0px',
            }}>
            <Header style={{ paddingBottom: '20px' }}>
              <h4 style={{ display: 'flex', width: '100%' }}>Documento 121341</h4>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '10px',
                  width: '90%',
                  margin: '20px 0',
                }}>
                <ButtonMui
                  sx={{ padding: '10px 40px' }}
                  disabled={assinatura?.statusDocumento === 1 ? false : true}
                  $backgroundColor={Theme().insideTheme.light?.primary}
                  onClick={() => {
                    assinarDocumento(id);
                  }}
                  style={{ textTransform: 'none' }}
                  loadingIndicator={<CircularProgress color={'warning'} size={16} />}
                  loading={false}>
                  Assinar e Enviar
                </ButtonMui>
              </Box>
            </Header>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={<OptLoading />}>
                  <Page pageNumber={pageNumber} width={900} />
                </Document>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}>
                  <ButtonMui
                    $backgroundColor={'transparent'}
                    onClick={() => prevPage()}
                    loadingIndicator={<CircularProgress color={'warning'} size={16} />}
                    loading={false}>
                    <Icon path={mdiChevronLeft} size={1} color={Theme().insideTheme.light?.secondary} />
                  </ButtonMui>
                  <p>
                    Página{' '}
                    <strong>
                      {' '}
                      {pageNumber} of {numPages}
                    </strong>
                  </p>
                  <ButtonMui
                    $backgroundColor={'transparent'}
                    onClick={() => nextPage()}
                    loadingIndicator={<CircularProgress color={'warning'} size={16} />}
                    loading={false}>
                    <Icon path={mdiChevronRight} size={1} color={Theme().insideTheme.light?.secondary} />
                  </ButtonMui>
                </Box>
              </Box>
            </Box>
          </Box>
        </OptLayoutProvider>
      </OptSideLayoutContent>
    </OptSideLayoutContent>
  );
};
