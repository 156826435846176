import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as S from './styles';
export interface ProgressBarProps {
  title: string;
  description: string | number;
  value: number;
  color: string;
  maxValue: number;
}

export default function ProgressBar({ title, description, value, color, maxValue }: ProgressBarProps) {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>{title}</Typography>
        <Typography>{description}</Typography>
      </Box>
      <Box color={color} sx={{ display: 'flex' }}>
        <S.StyledProgress color={color} value={value} max={maxValue}></S.StyledProgress>
      </Box>
    </Box>
  );
}
