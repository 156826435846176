import { Cliente } from '../models/Cliente/Cliente.model';
import { EscritorioSearchResponse } from '../models/dtos/EscritorioSearchResponse.model';
import { Escritorio, EscritorioFormModel } from '../models/Escritorio/Escritorio.model';
import { objectToFormData } from '../shared/functions';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useEscritorioService() {
  const { get, post, put, remove, gridSearch, getFile } = useApi();

  const baseApi = 'api/escritorio';

  const obter = async (id: string) => {
    return get<ApiResponse<Escritorio>>(`${baseApi}/${id}`);
  };

  const obterGridEscritorios = async (data: SearchRequest<{}>) => {
    return gridSearch<EscritorioSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterTodosEscritorios = async () => {
    return get<Cliente>(`${baseApi}`);
  };

  const enviar = async (data: EscritorioFormModel) => {
    return post(`${baseApi}`, data);
  };

  const alterar = async (id: string, data: EscritorioFormModel) => {
    return put<Escritorio>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<Escritorio>(`${baseApi}/${id}`);
  };

  //White-label :

  const enviarArquivo = async (id: string, file: File) => {
    const formData = objectToFormData({
      file,
    });

    return post(`${baseApi}/${id}/imagem`, formData);
  };

  const alterarArquivo = async (id: string, file: File) => {
    const formData = objectToFormData({
      file,
    });

    return put(`${baseApi}/${id}/imagem`, formData);
  };

  const obterArquivo = async (id: string) => {
    return getFile<Blob>(`${baseApi}/${id}/imagem`, {
      responseType: 'blob',
    });
  };

  return {
    enviarArquivo,
    alterarArquivo,
    obterArquivo,
    obter,
    enviar,
    alterar,
    remover,
    obterTodosEscritorios,
    obterGridEscritorios,
  };
}
