import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import Theme from '../../shared/theme';
import * as S from './Button.styles';

interface Props {
  onClick: () => void;
  color?: boolean | string;
  icon?: string;
  p?: string;
  size?: number;
}
export const IconButton = (props: Props) => {
  return (
    <S.StyledPlusButton onClick={props.onClick} p={props.p ? props.p : ''}>
      <Icon
        path={props.icon ? props.icon : mdiPlusCircle}
        size={props.size ? props.size : 1}
        color={
          typeof props.color === 'boolean'
            ? Theme().insideTheme.light?.primary
            : typeof props.color === 'string'
            ? props.color
            : '#757985'
        }
      />
    </S.StyledPlusButton>
  );
};
