import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { GeralWrapper, Header, HeaderFunctions } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import useClienteService from '../../services/cliente.service';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridClientesDocumentos } from './GridClientesDocumentos';

export const Documentos = () => {
  const [clientSearch, setClientSearch] = useState('');

  const { obterGridClientes } = useClienteService();

  const carregar = (
    query: OptGridRequest,
    ClientSearchRequest: PaginatedSearchRequest = { termoBuscado: clientSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: ClientSearchRequest,
    };
    return obterGridClientes(request);
  };

  function ProcurarCliente(data?: string) {
    setClientSearch(data ?? '');
  }

  // function refreshGrid() {
  //   if (ref.current) {
  //     ref.current.refresh();
  //   }
  // }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [clientSearch]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Gerenciador eletrônico de documentos" />
      <GeralWrapper>
        <Header>
          <h3>Documentos</h3>
          <HeaderFunctions>
            <OptLayoutProvider theme={Theme().insideTheme}>
              <OptSearchField placeholder="Buscar por cliente" onSearch={ProcurarCliente} />
            </OptLayoutProvider>
          </HeaderFunctions>
        </Header>
        <GridClientesDocumentos carregar={carregar} ref={ref} />
      </GeralWrapper>
    </OptSideLayoutContent>
  );
};
