import { Box } from '@mui/system';
import { Colors } from '../../shared/colors';

interface StatusUsuarioProps {
  status: number;
}

export function StatusUsuario(props: StatusUsuarioProps) {
  return (
    <>
      {props.status === 0 ? (
        <Box sx={{ color: Colors.gray6, fontWeight: '600' }}>• Inativo</Box>
      ) : (
        <Box sx={{ color: 'lightgreen', fontWeight: '600' }}>• Ativo</Box>
      )}
    </>
  );
}
