import { yupResolver } from '@hookform/resolvers/yup';
import { mdiCheck, mdiChevronDown, mdiWindowClose } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import Button from '../../components/Button/Button';
import ButtonMui from '../../components/Button/ButtonMui';
import { IconButton } from '../../components/Button/IconButton';
import { FormSelect, FormTextField } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { FormSwitch } from '../../components/Form/FormSwitch';
import InfoCabecalho from '../../components/Layout/InfoCabecalho';
import { TraducaoAndamentosFormModelSchema } from '../../models/Andamentos/TraducaoAndamento.model';
import { AndamentosProcessoModel } from '../../models/Processo/Processo.model';
import useEscritorioService from '../../services/escritorio.service';
import useTraducaoService from '../../services/traducao.service';
import { Colors } from '../../shared/colors';
import Theme from '../../shared/theme';

interface AndamentoFormProps {
  defaultAndamento: AndamentosProcessoModel;
  toggleDrawer: () => void;
  refreshGrid: () => void;
  notificacao?: boolean;
}

export function AndamentoForm({ defaultAndamento, toggleDrawer, refreshGrid, notificacao }: AndamentoFormProps) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const { enviar, alterar } = useTraducaoService();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [showTraducaoIa, setShowTraducaoIa] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [traducaoAutomatica, setTraducaoAutomatica] = useState(false);
  const [traducaoIaInputValue, setTraducaoIaInputValue] = useState('');
  const [imgUpload, setImgUpload] = useState<File | null>(null);
  const { handleSubmit, control, getValues, watch, setValue } = useForm<AndamentosProcessoModel>({
    defaultValues: defaultAndamento,
    resolver: yupResolver(TraducaoAndamentosFormModelSchema),
  });

  const { obter } = useEscritorioService();

  const idTraducao = getValues('id');
  const andamendoVisivel = watch('visivel');
  const traducaoIa = watch('traducaoInteligenciaArtificial');
  const escritorioId = getValues('escritorioId');
  const traducao = getValues('traducao');

  async function obterEscritorioData() {
    try {
      if (escritorioId) {
        const data = await obter(escritorioId);
        setTraducaoAutomatica(
          data.data.aceitaTraducaoAndamentoAutomatica ? data.data.aceitaTraducaoAndamentoAutomatica : false,
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (traducaoAutomatica && traducaoIa) {
        setValue('traducao', traducaoIa);
      }
    }
  }

  function toggleAccordion() {
    setExpanded((prev) => !prev);
  }

  const onSubmit = async (data: AndamentosProcessoModel) => {
    try {
      setBotaoDesabilitado(true);

      await enviar(data);

      toggleDrawer();
      refreshGrid();
    } catch {
      console.log('error');
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const onEdit = async (data: AndamentosProcessoModel) => {
    if (idTraducao) {
      try {
        setBotaoDesabilitado(true);
        await alterar(idTraducao, data);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };

  const onErrors = (data: FieldErrors<AndamentosProcessoModel>) => {
    console.log('onErros', data);
  };
  useEffect(() => {
    obterEscritorioData();
    if (traducaoIa) setTraducaoIaInputValue(traducaoIa);
    if (traducao) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, []);

  console.log(watch());

  return (
    <>
      <Grid container item px={2.6}>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}>
          <InfoCabecalho titulo="Andamento:" />
        </Grid>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}>
          <Box bgcolor={Colors.gray10} gap={2} px={2} pt={2} pb={2} display={'flex'} justifyContent="space-between">
            <Typography color={defaultAndamento?.traducao && defaultAndamento?.traducao.length ? '' : 'red'}>
              {defaultAndamento?.textoOriginal}
            </Typography>
          </Box>

          <Grid item xs={12}>
            <Controller
              name="iconeAndamento"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Box bgcolor={Colors.gray10} gap={2} px={2} pb={3} pt={1} display={'flex'}>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      id="file"
                      multiple={false}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const files = event.target.files;
                        if (files && files.length > 0) {
                          const file = files[0];
                          setImgUpload(file);
                          field.onChange(file);
                        }
                      }}
                    />
                    <label htmlFor="file">
                      <Button span label="Anexar Imagem:" variant="primary" />
                    </label>

                    <label>{imgUpload ? imgUpload.name : defaultAndamento.nomeImagem}</label>
                  </Box>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <FormSelect
              control={control}
              name="prioridadeProcesso"
              label="Prioridade dos Processo"
              placeholder="Prioridade dos Processo">
              <MenuItem value={1}>Prioridade Baixa</MenuItem>
              <MenuItem value={2}>Prioridade Média</MenuItem>
              <MenuItem value={3}>Prioridade Alta</MenuItem>
            </FormSelect>
          </Grid>
        </Grid>

        {traducaoIa && showTraducaoIa && (
          <Grid item xs={12} mt={3}>
            <Accordion expanded={expanded} onChange={toggleAccordion}>
              <AccordionSummary
                expandIcon={<Icon path={mdiChevronDown} size={1} />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>Tradução gerada por inteligência artificial</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  variant="outlined"
                  disabled
                  value={traducaoIaInputValue}
                  label="Tradução gerada por inteligência artificial">
                  {traducaoIa}
                </TextField>
                <Box display="flex" gap={2} mt={3}>
                  <Typography>Deseja utilizar a tradução gerada por IA?</Typography>
                  <IconButton
                    size={0.8}
                    icon={mdiCheck}
                    onClick={() => {
                      if (traducaoIa) {
                        setValue('traducao', traducaoIa);
                        setShowTraducaoIa(false);
                      }
                    }}
                    color={Colors.green}
                  />

                  <IconButton
                    size={0.8}
                    icon={mdiWindowClose}
                    onClick={() => {
                      setShowTraducaoIa(false);
                    }}
                    color={'#ff8080'}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        <Grid item xs={12} mt={3}>
          <FormTextField
            control={control}
            name={'traducao'}
            label="Tradução do andamento"
            placeholder="Tradução do andamento"
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12} mt={3}>
          <FormTextField
            control={control}
            name={'eAgora'}
            label="E agora?"
            placeholder="E agora?"
            multiline
            minRows={1}
          />
        </Grid>

        <Grid item xs={12} mt={3}>
          <FormTextField
            control={control}
            name={'saibaMais'}
            label="Saiba Mais:"
            placeholder="Saiba Mais..."
            multiline
            minRows={1}
          />
        </Grid>

        <Grid item xs={12}>
          <Tooltip
            title={
              andamendoVisivel
                ? 'Ao salvar o cliente será notificado deste andamento'
                : 'Ao salvar o cliente NÃO terá acesso e NÃO será notificado deste andamento'
            }
            placement="bottom-start"
            open={openTooltip}
            onOpen={handleOpenTooltip}
            onClose={handleCloseTooltip}>
            <Box>
              <FormSwitch label="Visível para o cliente" name="visivel" control={control} />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <SubmitWrapper>
        {notificacao && (
          <ButtonMui
            $backgroundColor={Theme().insideTheme.light?.primary}
            onClick={handleSubmit(onSubmit, onErrors)}
            loadingIndicator={<CircularProgress color={'warning'} size={16} />}
            loading={botaoDesabilitado}>
            Notificar cliente
          </ButtonMui>
        )}
        <ButtonMui
          $backgroundColor={Theme().insideTheme.light?.primary}
          onClick={handleSubmit(idTraducao ? onEdit : onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}
