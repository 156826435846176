import Icon from '@mdi/react';
import { Box, ButtonBase } from '@mui/material';
import { Colors } from '../../shared/colors';

interface Props {
  titulo: string;
  subtitulo?: string;
  valor: number;
  icon: string;
  onClick?: () => void;
}

export const NavPaper = ({ titulo, subtitulo, valor, icon, onClick }: Props) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        width: '300px',
        height: '80px',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0 16px',
        borderRadius: '6px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 1px 2px 10px 0px',
      }}>
      <Box
        sx={{
          width: '48px',
          height: '48px',
          backgroundColor: Colors.gray10,
          borderRadius: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Icon path={icon} size={1} color={Colors.gray5} />
      </Box>
      <Box
        ml={1.8}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        <Box mb={1} sx={{ fontWeight: 600, fontSize: '16px' }}>
          {titulo}
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box mr={1}>{valor.toString().padStart(2, '0')}</Box>
          <Box>{subtitulo}</Box>
        </Box>
      </Box>
    </ButtonBase>
  );
};
