import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Grid, MenuItem, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldErrors, get, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import ButtonMui from '../../components/Button/ButtonMui';
import { ErrorMessage, FormNumberFormat, FormSelect, FormTextField } from '../../components/Form';
import { AutoComplete } from '../../components/Form/AutoComplete';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { FullscreenLoading } from '../../components/FullscreenLoading/FullscreenLoading';
import { ENDERECO_DEFAULT } from '../../models/Endereco.model';
import { Escritorio, EscritorioFormModel, EscritorioFormModelSchema } from '../../models/Escritorio/Escritorio.model';
import useEscritorioService from '../../services/escritorio.service';
import Theme from '../../shared/theme';
import { useCEP, useCPNJ } from '../../shared/utils/functions';

interface EscritorioFormProps {
  defaultEscritorio: Partial<Escritorio>;
  toggleDrawer: () => void;
  refreshGrid(): void;
}

export function EscritorioForm({ defaultEscritorio, toggleDrawer, refreshGrid }: EscritorioFormProps) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numeroContaFormat, setNumeroContaFormat] = useState('');
  const [formStep, setFormStep] = useState(0);
  const bancos = [
    { value: 'Banco do Brasil', nome: 'Banco do Brasil' },
    { value: 'Banco Itaú', nome: 'Banco Itaú' },
    { value: 'Bradesco', nome: 'Bradesco' },
    { value: 'Banco Santander', nome: 'Banco Santander' },
    { value: 'C6 Bank', nome: 'C6 Bank' },
    { value: 'Caixa econômica', nome: 'Caixa econômica' },
    { value: 'Inter', nome: 'Inter' },
    { value: 'Nubank', nome: 'Nubank' },
    { value: 'Neon', nome: 'Neon' },
    { value: 'Next', nome: 'Next' },
  ];

  const { enviar, alterar } = useEscritorioService();

  const { setValue, handleSubmit, control, getValues, formState, watch } = useForm<EscritorioFormModel>({
    defaultValues: defaultEscritorio,
    resolver: yupResolver(EscritorioFormModelSchema),
  });

  const telefone = watch('telefone.celular');
  const telefoneLenght = telefone ? telefone.replace(/[^\d]+/g, '').length : 0;
  const steps = ['Informações do escritório', 'Informações bancárias'];

  const numeroConta = watch(`dadosFinanceiros.numeroConta`);
  const numeroContaLenght = numeroConta ? numeroConta.replace(/[^\d]+/g, '').length : 0;

  const { buscar } = useCEP();
  const { buscarCNPJ } = useCPNJ();

  const idEscritorio = getValues('id');

  const onSubmit = async (data: EscritorioFormModel) => {
    try {
      setBotaoDesabilitado(true);
      await enviar(data);
      toggleDrawer();
      refreshGrid();
    } catch {
      console.log('error');
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const onEdit = async (data: EscritorioFormModel) => {
    if (idEscritorio) {
      try {
        setBotaoDesabilitado(true);
        await alterar(idEscritorio, data);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };

  const onErrors = (data: FieldErrors<EscritorioFormModel>) => {
    console.log('onErros', data);
  };

  const checkCEP = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const cep = e.target.value;
      setLoading(true);
      buscar(cep)
        .then((endereco) => {
          if (endereco) {
            setValue('endereco', endereco);
            setValue('endereco.cep', cep);
          }
        })
        .catch(() => {
          setValue('endereco', { ...ENDERECO_DEFAULT, cep });
        })
        .finally(() => setLoading(false));
    },
    [buscar, setValue],
  );

  const checkCNPJ = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const cnpj = e.target.value;
      setLoading(true);
      buscarCNPJ(cnpj)
        .then((res) => {
          if (res) {
            setValue('endereco', res.endereco);

            if (res.nomeEmpresa) {
              setValue('nome', res.nomeEmpresa);
            } else if (res.razaoSocial) {
              setValue('nome', res.razaoSocial);
            }
            setValue('email', res.email);

            if (res.telefone.length === 10) setValue('telefone.fixo', res.telefone);
            if (res.telefone.length > 10) setValue('telefone.celular', res.telefone);
          }
        })
        .catch(() => {
          setValue('endereco', { ...ENDERECO_DEFAULT });
        })
        .finally(() => setLoading(false));
    },
    [buscarCNPJ, setValue],
  );

  const toggleStep = (submit: any) => {
    if (formStep === 0) {
      submit();
    } else {
      setFormStep(0);
    }
  };

  const formatNumeroConta = () => {
    if (numeroContaLenght < 6) {
      setNumeroContaFormat('####-##');
    } else if (numeroContaLenght < 7) {
      setNumeroContaFormat('#####-##');
    } else if (numeroContaLenght < 8) {
      setNumeroContaFormat('######-##');
    } else if (numeroContaLenght < 9) {
      setNumeroContaFormat('#######-##');
    } else if (numeroContaLenght < 10) {
      setNumeroContaFormat('########-##');
    } else if (numeroContaLenght < 11) {
      setNumeroContaFormat('#########-##');
    } else {
      setNumeroContaFormat('###########-#');
    }
  };
  useEffect(() => {
    formatNumeroConta();
  }, [numeroContaLenght]);

  return (
    <>
      {loading && <FullscreenLoading />}
      <Box>
        <Stepper activeStep={formStep} alternativeLabel>
          {steps.map((label) => (
            <Step
              key={label}
              onClick={() => toggleStep(handleSubmit(() => setFormStep(1), onErrors))}
              sx={{ cursor: 'pointer' }}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {formStep === 0 ? (
        <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
          <Grid item xs={6}>
            <Controller
              name="cnpj"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <NumberFormat
                    format="##.###.###/####-##"
                    fullWidth
                    customInput={TextField}
                    value={value ?? ''}
                    label="CNPJ"
                    onValueChange={(values, source) => {
                      if (source.event) {
                        const { event } = source;
                        const { floatValue: value } = values;
                        onChange({ ...event, target: { ...event.target, value } });
                      }
                    }}
                    onBlur={checkCNPJ}
                  />
                </>
              )}
            />
            <ErrorMessage error={get(formState.errors, 'cnpj')} />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="nome"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    onChange={onChange}
                    value={value ?? ''}
                    label="Nome do Escritório"
                    variant="outlined"
                    fullWidth
                  />
                </>
              )}
            />
            <ErrorMessage error={get(formState.errors, 'nome')} />
          </Grid>

          <Grid item xs={6}>
            <FormNumberFormat
              control={control}
              format={telefoneLenght < 11 ? '(##) ####-#####' : '(##) # ####-####'}
              name="telefone.celular"
              label="Celular"
              placeholder="(00) 0 0000-0000"
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumberFormat
              control={control}
              format="(##) ####-####"
              name="telefone.fixo"
              label="Telefone fixo"
              placeholder="(00) 0000-0000"
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumberFormat
              control={control}
              format="(##) # ####-####"
              name="whatsapp"
              label="Whatsapp"
              placeholder="(00) 0 0000-0000"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="email" label="E-mail" placeholder="email@mail.com" />
          </Grid>
          <Grid item xs={6}>
            <FormNumberFormat
              control={control}
              format="#####-###"
              name="endereco.cep"
              label="CEP"
              placeholder="CEP"
              onBlur={checkCEP}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="endereco.estado" label="Estado" placeholder="Estado" />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="endereco.cidade" label="Cidade" placeholder="Cidade" />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="endereco.bairro" label="Bairro" placeholder="Bairro" />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="endereco.rua" label="Rua" />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="endereco.complemento"
              label="Complemento"
              placeholder="Complemento"
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField control={control} name="redesSociais.facebook" label="Facebook" placeholder="Facebook" />
          </Grid>
          <Grid item xs={12}>
            <FormTextField control={control} name="redesSociais.instagram" label="Instagram" placeholder="Instagram" />
          </Grid>
          <Grid item xs={12}>
            <FormTextField control={control} name="redesSociais.linkedin" label="Linkedin" placeholder="Linkedin" />
          </Grid>
          <Grid item xs={12}>
            <FormTextField control={control} name="site" label="Site" placeholder="Site" />
          </Grid>
        </Grid>
      ) : (
        <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
          <Grid item xs={6}>
            <AutoComplete
              options={bancos}
              control={control}
              label="Nome do banco"
              placeholder="Nome do banco"
              name={`dadosFinanceiros.nomeBanco`}
            />
            <ErrorMessage error={get(formState.errors, `dadosFinanceiros.nomeBanco`)} />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              control={control}
              name="dadosFinanceiros.tipoConta"
              label="Tipo da conta"
              placeholder="Tipo da conta">
              <MenuItem value={1}>Conta corrente</MenuItem>
              <MenuItem value={2}>Conta de poupança</MenuItem>
            </FormSelect>
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="dadosFinanceiros.nomeTitular"
              label="Nome do titular"
              placeholder="Nome do titular"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="dadosFinanceiros.chavePix"
              label="Chave PIX"
              placeholder="Chave PIX"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="dadosFinanceiros.agencia"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <NumberFormat
                    format="####"
                    fullWidth
                    customInput={TextField}
                    value={value ?? ''}
                    label="Agência"
                    onValueChange={(values, source) => {
                      if (source.event) {
                        const { event } = source;
                        const { floatValue: value } = values;
                        onChange({ ...event, target: { ...event.target, value } });
                      }
                    }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumberFormat
              control={control}
              format={numeroContaFormat}
              name="dadosFinanceiros.numeroConta"
              label="Número da conta"
              placeholder="Número da conta"
            />
          </Grid>
        </Grid>
      )}

      <SubmitWrapper>
        {formStep === 0 ? (
          <ButtonMui
            $backgroundColor={Theme().insideTheme.light?.primary}
            onClick={handleSubmit(() => setFormStep(1), onErrors)}
            loadingIndicator={<CircularProgress color={'warning'} size={16} />}
            loading={botaoDesabilitado}
            disabled={loading}>
            Continuar
          </ButtonMui>
        ) : (
          <>
            <ButtonMui
              $backgroundColor={Theme().insideTheme.light?.primary}
              onClick={handleSubmit(idEscritorio ? onEdit : onSubmit, onErrors)}
              loadingIndicator={<CircularProgress color={'warning'} size={16} />}
              loading={botaoDesabilitado}>
              Salvar
            </ButtonMui>
          </>
        )}
      </SubmitWrapper>
    </>
  );
}
