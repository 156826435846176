import { UserInfo } from '../../services/auth/UserInfo';
import { LoadableData } from '../../shared/types/LoadableData';

export interface AuthenticationState {
  userInfo: LoadableData<UserInfo | undefined>;
  tenantId?: string;
  token?: string;
  notificationToken?: string;
}

export const AUTHENTICATION_INITIAL_STATE: AuthenticationState = {
  userInfo: { data: undefined, loading: false },
};
