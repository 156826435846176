import { Box } from '@mui/system';
import { Colors } from '../../shared/colors';

interface TraducaoProps {
  status: boolean;
}

export function StatusTextTraducao(props: TraducaoProps) {
  return (
    <>
      {props.status === true ? (
        <Box sx={{ color: Colors.green, fontWeight: '600' }}>• Vísivel</Box>
      ) : (
        <Box sx={{ color: Colors.gray6, fontWeight: '600' }}>• Privado</Box>
      )}
    </>
  );
}
