import { mdiDelete } from '@mdi/js';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { StatusText } from '../../components/StatusText/StatusText';
import { AssinaturaSearchResponse } from '../../models/dtos/AssinaturaSearchResponse';
import { Routes } from '../../routes';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    AssinaturaSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<AssinaturaSearchResponse>>;
  removerCliente: (id: string) => void;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridAssinaturas = forwardRef<OptGridRef | undefined, GridProps>(({ carregar, removerCliente }, ref) => {
  const columns: OptGridColumn<AssinaturaSearchResponse>[] = [
    {
      title: 'id',
      field: 'assinaturaId',
      hidden: true,
    },

    {
      title: 'Documento',
      field: 'nome',
    },
    {
      title: 'Data de criação',
      field: 'dataCriacao',
      align: 'center',
      render: (data) => {
        const diaCriacao = new Date(data.dataCriacao);
        return (
          <>
            {diaCriacao.toLocaleTimeString('pt-Br', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        );
      },
    },
    {
      title: 'Status',
      field: 'statusDocumento',
      align: 'center',
      render: (data) => {
        return (
          <StatusText
            status={
              data.statusDocumento === 1 ? 'Novo' : data.statusDocumento === 2 ? 'Pendente' : 'Assinado'
            }></StatusText>
        );
      },
    },
  ];
  const history = useHistory();
  return (
    <OptLayoutProvider theme={Theme().insideTheme}>
      <OptGrid
        title=""
        ref={ref as any}
        data={carregar}
        options={options}
        headerTitlePosition={'center'}
        columns={columns}
        onRowClick={(rowData) => {
          history.push(Routes.AssinaturaEletronica.DocumentoAssinatura(rowData.assinaturaId));
        }}
        actionsPosition={'end'}
        actions={[
          (rowData) => ({
            icon: { path: mdiDelete },
            tooltip: 'Deletar',
            onClick: () => removerCliente(rowData.assinaturaId),
            disabled: false,
          }),
        ]}
      />
    </OptLayoutProvider>
  );
});
