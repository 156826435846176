import {
  OptGrid,
  OptGridOptions,
  OptGridRef,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Routes } from '../../../routes';
import Theme from '../../../shared/theme';


interface GridProps {
ArrayPasta: {
peticoes: number,
procuracoes: number,
provas:number,
comprovantes: number

}
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridProcessosPastas = forwardRef<OptGridRef | undefined, GridProps>(({ ArrayPasta}, ref) => {
  const { documentoId, processoId } = useParams<{ documentoId: string; processoId: string }>();

  const LOCAL_DATA = [
    {
      nome: 'Petições',
      id: '1',
      arquivos: `${ArrayPasta.peticoes} Arquivos`,
    },
    {
      nome: 'Procurações',
      id: '2',
      arquivos:`${ArrayPasta.procuracoes} Arquivos`,
    },
    {
      nome: 'Provas',
      id: '3',
      arquivos: `${ArrayPasta.provas} Arquivos`,
    },
    {
      nome: 'Comprovantes',
      id: '4',
      arquivos: `${ArrayPasta.comprovantes} Arquivos`,
    },
  ];


  
  let history = useHistory();

  return (
    <>
      <OptLayoutProvider theme={Theme().insideTheme}>
        <OptGrid
          title=""
          ref={ref as any}
          onRowClick={(rowData) => {
            history.push(Routes.Documentos.ProcessoArquivos(documentoId, processoId, rowData.id));
          }}
          data={LOCAL_DATA}
          options={options}
          columns={[
            {
              title: 'Id',
              field: 'id',
              hidden: true,
            },
            {
              title: 'Pastas',
              field: 'nome',
            },
            {
              title: 'Arquivos',
              field: 'arquivos',
            },
          ]}
          headerTitlePosition="start"
          actionsPosition={'end'}
        />
      </OptLayoutProvider>
    </>
  );
});
