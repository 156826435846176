import { mdiPen, mdiSignal, mdiThumbDown, mdiThumbUp } from '@mdi/js';
import { Box, Grid } from '@mui/material';
import { OptGridRequest, OptLayoutProvider, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PieChart } from '../../../components/Charts/PieChart/PieChart';
import { GeralWrapper } from '../../../components/Layout/Layout';
import { NoDataResponse } from '../../../components/NoDataResponse/NoDataResponse';
import { NavPaper } from '../../../components/Paper/NavPaper';
import ProgressBar from '../../../components/ProgressBar/Progressbar';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { NpsResposta, NpsSearchResponse } from '../../../models/dtos/NpsSearchResponse.model';
import { Routes } from '../../../routes';
import useNpsService from '../../../services/nps.service';
import { Colors } from '../../../shared/colors';
import { TransformarModeloNps } from '../../../shared/functions';
import { npsDefault } from '../../../shared/mock';
import Theme from '../../../shared/theme';
import { ApiResponse } from '../../../shared/types/ApiResponse';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';
import { GridNpsDetalhes } from './NpsGridDetalhes';
import { StyledBox } from './Style';

export default function NpsDetalhes() {
  const [data, setData] = useState<NpsSearchResponse>(npsDefault);
  const { id } = useParams<{ id: string }>();
  const { obter, obterGridRespostas } = useNpsService();

  const carregar = async (query: OptGridRequest, npsSearchRequest: PaginatedSearchRequest = { termoBuscado: '' }) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: npsSearchRequest,
    };
    const dataNps = await obterGridRespostas(request, id);
    return TransformarModeloNps(dataNps as unknown as ApiResponse<NpsResposta>);
  };

  async function obterNpsAtual(id: string) {
    try {
      const npsAtual = await obter(id);
      setData(npsAtual.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    obterNpsAtual(id);
  }, []);
  console.log(data);
  return (
    <OptSideLayoutContent>
      <Toolbar title="Gestão de NPS" goBackRoute={Routes.Nps.Nps} />
      <OptSideLayoutContent>
        <GeralWrapper>
          <OptLayoutProvider theme={Theme().insideTheme}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '30px 0px' }}>
              <NavPaper titulo={'Total de respostas'} valor={data.resultado.totalRespostas} icon={mdiPen} />
              <NavPaper titulo={'Média de avaliação'} valor={data.resultado.mediaAvaliacao} icon={mdiSignal} />
              <NavPaper titulo={'Bem avaliadas'} valor={data.resultado.qtdBom} icon={mdiThumbUp} />
              <NavPaper titulo={'Mal avaliadas'} valor={data.resultado.qtdRuim} icon={mdiThumbDown} />
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <StyledBox>
                  <h3>Nível de avaliações</h3>
                  <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <ProgressBar
                      title="0 a 6"
                      description={data.resultado.qtdRuim}
                      value={data.resultado.qtdRuim}
                      color={Colors.red}
                      maxValue={10}></ProgressBar>
                    <ProgressBar
                      title="7 a 8"
                      description={data.resultado.qtdMedio}
                      value={data.resultado.qtdMedio}
                      color={Colors.yellow}
                      maxValue={data.resultado.totalRespostas}></ProgressBar>
                    <ProgressBar
                      title="9 a 10"
                      description={data.resultado.qtdBom}
                      value={data.resultado.qtdBom}
                      color={Colors.green}
                      maxValue={data.resultado.totalRespostas}></ProgressBar>
                  </Box>
                </StyledBox>
              </Grid>
              <Grid item xs={6}>
                <StyledBox>
                  <h3>Índice de avaliações</h3>
                  {data.resultado.totalRespostas > 0 ? (
                    <PieChart
                      data={[
                        {
                          value: data.resultado.qtdRuim,
                          color: Colors.red,
                          label: 'Ruim',
                          id: '1',
                        },
                        {
                          value: data.resultado.qtdMedio,
                          color: Colors.yellow,
                          label: 'Médio',
                          id: '2',
                        },
                        {
                          value: data.resultado.qtdBom,
                          color: Colors.green,
                          label: 'Bom',
                          id: '3',
                        },
                      ]}
                    />
                  ) : (
                    <NoDataResponse mensagem="Não há dados a serem exibidos" />
                  )}
                </StyledBox>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: '30px' }}>
              <GridNpsDetalhes carregar={carregar} />
            </Box>
          </OptLayoutProvider>
        </GeralWrapper>
      </OptSideLayoutContent>
    </OptSideLayoutContent>
  );
}
