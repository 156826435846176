export const Colors = {
  primary: '#BBC1CD', //

  primaryContrast: '#ffb630',

  primaryTints: {
    tint1: '#ffb630',
    tint2: '#ffc14f',
    tint3: '#ffcb6b',
    tint4: '#ffd485',
    tint5: '#fcdda4',
  },

  secondary: '#42545C',

  gray20p: 'rgb(234, 238, 244, .2)', //
  gray40p: 'rgb(234, 238, 244, .4)', //
  gray60p: 'rgba(234, 238, 244, 0.6)', //

  gray: '#25282D',
  gray1: '#333333',
  gray2: '#454854',
  gray3: '#757575',
  gray4: '#828282',
  gray5: '#b5b5b5',
  gray6: '#cccccc',
  gray7: '#d9d9d9',
  gray8: '#ebebeb',
  gray9: '#EAEEF4',
  gray10: '#F1F2F8',

  white: '#FFFFFF',

  blue: '#2F80ED',

  green: '#7ec47e',

  red: '#E20000',

  yellow: '#f7f01b',

  black: '#000000',
} as const;
