import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import { FormTextField } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import InfoCabecalho from '../../components/Layout/InfoCabecalho';
import { NpsFormModel, NpsFormModelSchema } from '../../models/Nps/Nps.model';
import { PerguntasFrequentes } from '../../models/PerguntasFrequentes/PerguntasFrequentes.model';
import useNpsService from '../../services/nps.service';
import Theme from '../../shared/theme';

interface NpsFormProps {
  defaultNpsForm?: Partial<NpsFormModel>;
  toggleDrawer: () => void;
  refreshGrid: () => void;
}

export function NpsForm({ defaultNpsForm, toggleDrawer, refreshGrid }: NpsFormProps) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);

  const { handleSubmit, control, getValues } = useForm<NpsFormModel>({
    defaultValues: defaultNpsForm,
    resolver: yupResolver(NpsFormModelSchema),
  });
  const { enviar, alterar } = useNpsService();
  const idPergunta = getValues('id');

  const onSubmit = async (data: NpsFormModel) => {
    try {
      setBotaoDesabilitado(true);
      await enviar(data);
      toggleDrawer();
      refreshGrid();
    } catch {
      console.log('error');
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const onEdit = async (data: NpsFormModel) => {
    if (idPergunta) {
      try {
        setBotaoDesabilitado(true);
        await alterar(idPergunta, data);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };

  const onErrors = (data: FieldErrors<PerguntasFrequentes>) => {
    console.log('onErros', data);
  };

  return (
    <>
      <Grid container item columnSpacing={5} px={2.6} display={'flex'} justifyContent={'space-between'}>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}>
          <InfoCabecalho titulo="Digite a pesquisa que deseja lançar:" />
        </Grid>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}></Grid>
        <Grid item xs={12} mt={7}>
          <FormTextField
            control={control}
            name={'pergunta'}
            label="Em uma escala de 0 a 10:"
            placeholder="Em uma escala de 0 a 10:"
            multiline
            minRows={1}
          />
        </Grid>
      </Grid>
      <SubmitWrapper>
        <ButtonMui
          $backgroundColor={Theme().insideTheme.light?.primary}
          onClick={handleSubmit(idPergunta ? onEdit : onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}
