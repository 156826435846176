import * as Yup from 'yup';

export interface TraducaoAndamentos {
  id: string;
  textoOriginal?: string | null;
  traducao?: string;
  escritorioId?: string;
  traducaoInteligenciaArtificial?: string;
}

export type TraducaoAndamentosFormModel = Partial<TraducaoAndamentos>;

export const TRADUCAO_ANDAMENTO_DEFAULT: TraducaoAndamentosFormModel = {
  id: '',
  textoOriginal: '',
  traducao: '',
  escritorioId: '',
};

export const TraducaoAndamentosFormModelSchema: Yup.SchemaOf<TraducaoAndamentosFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  escritorioId: Yup.string().notRequired(),
  textoOriginal: Yup.string().notRequired().typeError('Campo obrigatório'),
  traducao: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  traducaoInteligenciaArtificial: Yup.string().notRequired(),
});
