import { OptSideLayoutPortalContainer, OptSideLayoutPortalContent } from '@optsol/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from '.';
import { useAuthenticationContext } from '../contexts/authentication/authenticationContext';
import { SectionsClaims } from '../contexts/claims/sectionsClaims';
import MobileRedirect from '../pages/MobileRedirect/MobileRedirect';
import { Advogados } from '../pages/advogados/Advogados';
import { Agendamentos } from '../pages/agendamentos/Agendamentos';
import { AndamentoDetalhado } from '../pages/andamentos/AndamentoDetalhado/AndamentoDetalhado';
import { Andamentos } from '../pages/andamentos/Andamentos';
import { Assinaturas } from '../pages/assinaturas/Assinaturas';
import { DocumentoAssinaturas } from '../pages/assinaturas/DocumentoAssinatura/DocumentoAssinatura';
import { Clientes } from '../pages/clientes/Clientes';
import { Configuracoes } from '../pages/configuracoes/Configuracoes';
import { ProcessosArquivos } from '../pages/documentos/ArquivosProcessos/ProcessosArquivos';
import { DocumentosDetalhes } from '../pages/documentos/Detalhes/DocumentosDetalhes';
import { Documentos } from '../pages/documentos/Documentos';
import { ProcessosDocPastas } from '../pages/documentos/Processos/ProcessosDocPastas';
import { Escritorios } from '../pages/escritorios/Escritorios';
import FinanceiroDetalhes from '../pages/financeiro/DetalhesFinanceiros/FinanceiroDetalhes';
import { Financeiro } from '../pages/financeiro/Financeiro';
import { Nps } from '../pages/nps/Nps';
import NpsDetalhes from '../pages/nps/NpsDetalhes/NpsDetalhe';

import PerguntasFrequentes from '../pages/perguntasFrequentes/PerguntasFrequentes';
import { Usuario } from '../pages/usuarios/Usuario';
import { Processos } from '../pages/visaoGeralProcessos/Processos';
import { ResumoProcesso } from '../pages/visaoGeralProcessos/ResumoProcesso/ResumoProcesso';

export const GeralRoutes = () => {
  const { hasAccess } = useAuthenticationContext();

  const hasAccessToContent = (access: SectionsClaims) => {
    if (Array.isArray(access)) {
      return access.some((x) => hasAccess(x));
    } else {
      return hasAccess(access);
    }
  };

  const hasAccesToAdmin = hasAccessToContent(SectionsClaims.ADMIN);

  const hasAccesToAdminMaster = hasAccessToContent(SectionsClaims.ADMIN_MASTER);

  const hasAccesToGestor = hasAccessToContent(SectionsClaims.GESTOR);

  const hasAccesToCliente = hasAccessToContent(SectionsClaims.CLIENTE);

  const hasAccesToAdvogado = hasAccessToContent(SectionsClaims.ADVOGADO);

  const hasAccesToAdvogadoSocio = hasAccessToContent(SectionsClaims.ADVOGADO_SOCIO);

  const hasAccesToEstagiario = hasAccessToContent(SectionsClaims.ESTAGIARIO);

  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Routes.Home}>
            {hasAccesToAdminMaster ? (
              <Redirect to={Routes.Escritorios} />
            ) : hasAccesToGestor ? (
              <Redirect to={Routes.Advogados} />
            ) : (
              <Redirect to={Routes.VisaoGeral.Principal} />
            )}
          </Route>
          <Route exact path={Routes.VisaoGeral.ResumoProcesso()} component={ResumoProcesso} />
          <Route exact path={Routes.acessoCliente} component={MobileRedirect} />
          <Route exact path={Routes.Agendamentos} component={Agendamentos} />
          <Route exact path={Routes.AndamentosGerais.Andamentos} component={Andamentos} />
          <Route exact path={Routes.AndamentosGerais.AndamentoDetalhado()} component={AndamentoDetalhado} />
          <Route exact path={Routes.Documentos.Principal} component={Documentos} />
          <Route exact path={Routes.Documentos.Detalhes()} component={DocumentosDetalhes} />
          <Route exact path={Routes.Documentos.Processos()} component={ProcessosDocPastas} />
          <Route exact path={Routes.Documentos.ProcessoArquivos()} component={ProcessosArquivos} />
          <Route exact path={Routes.Clientes} component={Clientes} />
          <Route exact path={Routes.AssinaturaEletronica.Assinaturas} component={Assinaturas} />
          <Route exact path={Routes.AssinaturaEletronica.DocumentoAssinatura()} component={DocumentoAssinaturas} />
          <Route exact path={Routes.Financeiro.FinanceiroDetalhes()} component={FinanceiroDetalhes} />
          <Route exact path={Routes.Nps.NpsDetalhes()} component={NpsDetalhes} />

          <Route exact path={Routes.VisaoGeral.Principal}>
            {hasAccesToCliente || hasAccesToAdminMaster ? <Redirect to="/acesso-cliente" /> : <Processos />}
          </Route>

          <Route exact path={Routes.Financeiro.FinanceiroGrid}>
            {hasAccesToGestor || hasAccesToAdmin || hasAccesToAdminMaster || hasAccesToAdvogadoSocio ? (
              <Financeiro />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path={Routes.Escritorios}>
            {hasAccesToAdminMaster ? <Escritorios /> : <Redirect to="/" />}
          </Route>
          <Route exact path={Routes.Advogados}>
            {hasAccesToGestor || hasAccesToAdmin || hasAccesToAdvogadoSocio ? <Advogados /> : <Redirect to="/" />}
          </Route>
          <Route exact path={Routes.Usuarios}>
            {hasAccesToAdmin ||
            hasAccesToAdminMaster ||
            hasAccesToAdvogado ||
            hasAccesToAdvogadoSocio ||
            hasAccesToEstagiario ? (
              <Usuario />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path={Routes.Configuracoes}>
            {hasAccesToAdmin || hasAccesToAdvogadoSocio ? <Configuracoes /> : <Redirect to="/" />}
          </Route>
          <Route exact path={Routes.Faq}>
            {hasAccesToAdmin || hasAccesToAdvogadoSocio ? <PerguntasFrequentes /> : <Redirect to="/" />}
          </Route>
          <Route exact path={Routes.Nps.Nps}>
            {hasAccesToAdmin || hasAccesToAdvogadoSocio ? <Nps /> : <Redirect to="/" />}
          </Route>
          <Route exact path={Routes.Nps.NpsDetalhes()}>
            {hasAccesToAdmin || hasAccesToAdvogadoSocio ? <Nps /> : <Redirect to="/" />}
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
