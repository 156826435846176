import { mdiCheck, mdiWindowClose } from '@mdi/js';
import { Box, Grid, Typography } from '@mui/material';
import { OptLoading } from '@optsol/react';
import { useEffect, useRef } from 'react';
import { NotificacaoSearchResponse } from '../../models/dtos/NotificacaoSearchResponse.model';
import { Colors } from '../../shared/colors';
import { useOnScreen } from '../../shared/functions';
import Theme from '../../shared/theme';
import { IconButton } from '../Button/IconButton';
import * as S from './styles';

interface Props {
  notificacao: NotificacaoSearchResponse[] | null;
  removerNotificacao: (id: string) => void;
  marcarNotificacao: (id: string) => void;
  totalNotificacoes: (props: any) => void;
  loading: boolean;
}

const NotificacaoField = ({
  notificacao,
  removerNotificacao,
  marcarNotificacao,
  totalNotificacoes,
  loading,
}: Props) => {
  const themeColor = Theme().insideTheme.light?.primary;

  const dummy = useRef() as React.MutableRefObject<HTMLInputElement>;

  const isVisible = useOnScreen(dummy);

  useEffect(() => {
    if (isVisible) {
      totalNotificacoes((prev: any) => prev + 10);
    }
  }, [isVisible, totalNotificacoes]);

  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'} sx={{ marginTop: '4rem' }}>
        {notificacao
          ? notificacao.map((item, index) => {
              const bgColor = index % 2 === 0 ? Colors.gray10 : Colors.white;
              const dataFormat = new Date(item.dataCriacao);
              return (
                <S.styledBox container key={item.id}>
                  <Grid item xs={11.3}>
                    <Box bgcolor={bgColor} key={item.id} sx={{ paddingLeft: '1.5rem' }} pt={1} pb={2}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>
                          <b>{item.titulo}</b>
                        </p>
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                          {item.lido === false ? (
                            <Box
                              sx={{
                                backgroundColor: themeColor,
                                borderRadius: '20px',
                                color: '#fff',
                                padding: '3px 8px',
                                paddingBottom: '5px',
                                fontSize: '0.8em',
                              }}>
                              Novo
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>{item.descricao}</Typography>
                        <Box sx={{ marginRight: '0.3em' }}>
                          <p style={{ fontWeight: 500 }}>
                            {dataFormat.getDate() === new Date().getDate()
                              ? dataFormat.toLocaleTimeString('pt-Br', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                              : dataFormat.toLocaleTimeString('pt-Br', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={0.7} sx={{ backgroundColor: bgColor }}>
                    <S.styledDiv color={bgColor}>
                      {item.lido ? null : (
                        <IconButton size={0.8} icon={mdiCheck} onClick={() => marcarNotificacao(item.id)} />
                      )}
                      <IconButton
                        size={0.8}
                        icon={mdiWindowClose}
                        onClick={() => removerNotificacao(item.id)}
                        color={'#ff8080'}
                      />
                    </S.styledDiv>
                  </Grid>
                </S.styledBox>
              );
            })
          : null}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '0.5rem',
        }}>
        {/* <S.styledButton onClick={() => setTotalNotificacoes(totalNotificacoes + 10)} color={themeColor}>
          Mostrar mais notificações
        </S.styledButton> */}
        <div ref={dummy}>{loading ? <OptLoading size={20} /> : null}</div>
      </Box>
    </Box>
  );
};

export default NotificacaoField;
