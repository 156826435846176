import { Box, CircularProgress, Grid, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ButtonMui from '../../components/Button/ButtonMui';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import InfoCabecalho from '../../components/Layout/InfoCabecalho';
import { andamentosProcesso } from '../../models/dtos/ProcessoSearchResponse.model';
import useProcessoService from '../../services/processo.service';
import { Colors } from '../../shared/colors';
import Theme from '../../shared/theme';

interface AndamentoFormProps {
  defaultAndamento: andamentosProcesso | undefined;
  toggleDrawer: () => void;
  refreshGrid: () => void;
  notificacao?: boolean;
  processoId: string;
}

export function AndamentoProcessoDetalheForm({
  defaultAndamento,
  toggleDrawer,
  refreshGrid,
  notificacao,
  processoId,
}: AndamentoFormProps) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const { alterarTraducao } = useProcessoService();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [traducao, setTraducao] = useState('');

  const dataAndamento = defaultAndamento?.data
    ? new Date(defaultAndamento?.data).toLocaleTimeString('pt-Br', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';

  const onEdit = async () => {
    if (defaultAndamento?.id) {
      try {
        setBotaoDesabilitado(true);
        await alterarTraducao(processoId, defaultAndamento?.id, {
          traducao,
          visivel: switchValue,
        });

        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };
  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  useEffect(() => {
    setTraducao(defaultAndamento?.andamento.traducao ? defaultAndamento?.andamento.traducao.traducao : '');
    setSwitchValue(defaultAndamento?.andamento.traducao ? defaultAndamento.andamento.traducao.visivel : false);
  }, []);

  return (
    <>
      <Grid container item columnSpacing={5} px={2.6} display={'flex'} justifyContent={'space-between'}>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}>
          <InfoCabecalho titulo="Andamento do processo" />
        </Grid>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box bgcolor={Colors.gray10} px={2.5} pt={1} pb={2}>
              <Typography fontWeight={500} my={1}>
                {dataAndamento}
              </Typography>
              <Typography
                color={
                  defaultAndamento?.andamento.traducao && defaultAndamento?.andamento.traducao.traducao.length
                    ? ''
                    : 'red'
                }>
                {defaultAndamento?.andamento.descricao}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} mt={7}>
          <TextField
            value={traducao}
            onChange={(e) => {
              setTraducao(e.target.value);
            }}
            label="Tradução do andamento"
            placeholder="Tradução do andamento"
            multiline
            fullWidth
            minRows={3}
          />
          <Tooltip
            title={
              switchValue
                ? 'Ao salvar o cliente será notificado deste andamento'
                : 'Ao salvar o cliente NÃO terá acesso e NÃO será notificado deste andamento'
            }
            placement="bottom-start"
            open={openTooltip}
            onOpen={handleOpenTooltip}
            onClose={handleCloseTooltip}>
            <Box display="flex" alignItems="center">
              <Typography color={Theme().insideTheme.light?.primary}>Visível para o cliente</Typography>
              <Switch
                value={switchValue}
                onChange={() => {
                  setSwitchValue((prev) => !prev);
                }}
                checked={switchValue}
              />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <SubmitWrapper>
        {/* {notificacao && (
          <ButtonMui
            $backgroundColor={Theme().insideTheme.light?.primary}
            onClick={handleSubmit(onSubmit, onErrors)}
            loadingIndicator={<CircularProgress color={'warning'} size={16} />}
            loading={botaoDesabilitado}>
            Notificar cliente
          </ButtonMui>
        )} */}
        <ButtonMui
          $backgroundColor={Theme().insideTheme.light?.primary}
          disabled={traducao.length === 0}
          onClick={() => onEdit()}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}
