import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { NpsResposta } from '../../../models/dtos/NpsSearchResponse.model';
import Theme from '../../../shared/theme';
import { PaginatedSearchRequest } from '../../../shared/utils/searchRequest';

interface GridProps {
  carregar: (query: OptGridRequest, npsSearchRequest?: PaginatedSearchRequest) => Promise<OptGridResponse<NpsResposta>>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridNpsDetalhes = forwardRef<OptGridRef | undefined, GridProps>(({ carregar }, ref) => {
  const columns: OptGridColumn<NpsResposta>[] = [
    {
      title: 'Clientes',
      field: 'nomeCliente',
    },
    {
      title: 'Notas',
      field: 'nota',
    },
    {
      title: 'Comentários',
      field: 'comentario',
    },
    {
      title: 'Data de envio',
      field: 'dataResposta',
      render: (data) => {
        const termino = new Date(data.dataResposta);

        return (
          <>
            {termino.toLocaleTimeString('pt-Br', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        );
      },
    },
  ];
  return (
    <OptLayoutProvider theme={Theme().insideTheme}>
      <OptGrid
        title=""
        ref={ref as any}
        data={carregar}
        options={options}
        columns={columns}
        headerTitlePosition="start"
        actionsPosition={'end'}
      />
    </OptLayoutProvider>
  );
});
