import { mdiDelete, mdiPencil } from '@mdi/js';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ProcessoSearchResponse } from '../../models/dtos/ProcessoSearchResponse.model';
import { Routes } from '../../routes';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';
import * as S from './styles';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    ProcessoSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<ProcessoSearchResponse>>;
  removerProcesso(processoId: string): Promise<void>;
  editarProcesso(processoId: string): Promise<void>;
  setProcessoArray(processoId: string[]): void;
}

const options: OptGridOptions = {
  search: true,
  selection: true,
  pageSize: 10,
};

export const GridProcessos = forwardRef<OptGridRef | undefined, GridProps>(
  ({ removerProcesso, editarProcesso, carregar, setProcessoArray }, ref) => {
    const columns: OptGridColumn<ProcessoSearchResponse>[] = [
      {
        title: 'id',
        field: 'id',
        hidden: true,
      },
      {
        title: 'Processo',
        field: 'numero',
      },
      {
        title: 'Nome do cliente',
        field: 'clientes',
        render: (data: ProcessoSearchResponse) => {
          return (
            <>
              {data.clientes[0] && data.clientes[0].nome
                ? data.clientes[0].nome
                : data.clientes[0] && data.clientes[0].nomeEmpresa
                ? data.clientes[0].nomeEmpresa
                : ''}
            </>
          );
        },
      },
      {
        title: 'Andamentos',
        field: 'quantidadeAndamentos',
      },
      {
        title: 'Tipo de ação',
        field: 'tipoAcao',
        render: (data) => {
          return <>{data.tipoAcao ? data.tipoAcao : ''}</>;
        },
      },
      // {
      //   title: 'Compromissos a vencer',
      //   field: 'compromissos',
      // },
      {
        title: 'Lembretes',
        field: 'lembretes',
        render: (data: ProcessoSearchResponse) => {
          return <>{data.lembretes > 0 ? `${data.lembretes} Dias` : null}</>;
        },
      },
    ];

    const history = useHistory();

    return (
      <OptLayoutProvider theme={Theme().insideTheme}>
        <S.GridContainer>
          <OptGrid
            title=""
            ref={ref as any}
            data={carregar}
            options={options}
            columns={columns}
            headerTitlePosition="start"
            actionsPosition={'end'}
            onRowClick={(rowData) => history.push(Routes.VisaoGeral.ResumoProcesso(rowData.id))}
            actions={[
              (rowData) => ({
                icon: { path: mdiPencil },
                tooltip: 'Editar',
                onClick: () => editarProcesso(rowData.id),
                disabled: false,
              }),
              (rowData) => ({
                icon: { path: mdiDelete },
                tooltip: 'Deletar',
                onClick: () => removerProcesso(rowData.id),
                disabled: false,
              }),
            ]}
            onSelect={(rowData) => {
              setProcessoArray(rowData.map((data) => data.id));
            }}
          />
        </S.GridContainer>
      </OptLayoutProvider>
    );
  },
);
