import { Box } from '@mui/material';
import { OptLoading } from '@optsol/react';

export function FullscreenLoading() {
  return (
    <Box
      position="absolute"
      right={5}
      zIndex={99999}
      left={5}
      overflow="hidden"
      top={5}
      bottom={5}
      maxWidth={'100%'}
      maxHeight="100%"
      sx={{
        backdropFilter: 'blur(3px)', // Aplica o efeito de desfoque em tudo que está atrás
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // Define uma cor de fundo semi-transparente para o Box
      }}>
      <OptLoading />
    </Box>
  );
}
