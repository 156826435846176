import { Documento } from '../models/Documento/Documento.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { downloadBlob } from '../shared/utils/functions';

export default function useDocumentoService() {
  const { get, post, remove, getFile } = useApi();

  const baseApi = 'api/cliente';

  const obter = async (id: string, documentoId: string, nomeArquivo: string) => {
    return getFile<Blob>(`${baseApi}/${id}/documento/${documentoId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    }).then((blob) => {
      downloadBlob(blob, nomeArquivo);
    });
  };

  const obterTodosDocumentos = async (id: string) => {
    return get<ApiResponse<Documento[]>>(`${baseApi}/${id}/documentos`);
  };

  const enviar = async (id: string, data: FormData) => {
    return post(`${baseApi}/${id}/documento`, data);
  };

  const remover = async (id: string, documentoId: string) => {
    return remove<Documento>(`${baseApi}/${id}/documento/${documentoId}`);
  };

  return { obter, enviar, remover, obterTodosDocumentos };
}
