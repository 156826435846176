import axios from 'axios';
import { useAuthenticationContext } from '../contexts/authentication/authenticationContext';
import { NotificacaoTotalSearchResponse } from '../models/dtos/NotificacaoSearchResponse.model';
import { GrupoUsuario, GrupoUsuarioFormModel } from '../models/Usuario/grupoUsuario.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';

export default function useNotificacaoService() {
  const { get, post, put, remove, gridSearch } = useApi('https://dev-notificacao-ms-api.azurewebsites.net');

  const baseApi = 'api/notificacao';
  const {
    state: { token },
  } = useAuthenticationContext();
  const obter = async (id: string) => {
    return get<ApiResponse<GrupoUsuario>>(`${baseApi}/${id}`);
  };

  const obterTodasNotificacoes = async (data: { page: number; pageSize: number; search: {} }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_ACESSOJURIS_NOTIFICACAO_URL}/api/notificacao/paginated`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const marcarTodasLidas = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_ACESSOJURIS_NOTIFICACAO_URL}/api/notificacao/marcar-todas-lidas`,
      {},
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };
  const marcarLida = async (data: { notificacoes: string[] }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_ACESSOJURIS_NOTIFICACAO_URL}/api/notificacao/marcar-lida`,
      data,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const removerNotificacao = async (id: string) => {
    const response = await axios.delete(`${process.env.REACT_APP_ACESSOJURIS_NOTIFICACAO_URL}/api/notificacao/${id}`, {
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const obterNumeroNotificacoes = async () => {
    const response = await axios.get<ApiResponse<NotificacaoTotalSearchResponse>>(
      `${process.env.REACT_APP_ACESSOJURIS_NOTIFICACAO_URL}/api/notificacao/total-nao-lidas`,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const enviar = async (data: GrupoUsuarioFormModel) => {
    return post(`${baseApi}`, data);
  };

  const alterar = async (id: string, data: GrupoUsuarioFormModel) => {
    return put<GrupoUsuario>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<GrupoUsuario>(`${baseApi}/${id}`);
  };

  return {
    obter,
    enviar,
    alterar,
    remover,
    obterTodasNotificacoes,
    removerNotificacao,
    obterNumeroNotificacoes,
    marcarTodasLidas,
    marcarLida,
  };
}

