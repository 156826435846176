import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { ButtonWrapper } from '../../components/Button/Button.styles';
import { IconButton } from '../../components/Button/IconButton';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { EscritorioForm } from '../../features/EscritorioForm/EscritorioForm';
import { ESCRITORIO_DEFAULT } from '../../models/Escritorio/Escritorio.model';
import useEscritorioService from '../../services/escritorio.service';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridEscritorios } from './GridEscritorios';

export const Escritorios = () => {
  const [escritorioSearch, setEscritorioSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [defaultEscritorio, setDefaultEscritorio] = useState(ESCRITORIO_DEFAULT);

  const { obterGridEscritorios, remover, obter } = useEscritorioService();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const carregar = (
    query: OptGridRequest,
    EscritorioSearchRequest: PaginatedSearchRequest = { termoBuscado: escritorioSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: EscritorioSearchRequest,
    };
    return obterGridEscritorios(request);
  };

  async function removerEscritorios(escritorioId: string) {
    try {
      await remover(escritorioId);
      ref.current?.refresh();
    } catch {
      console.log('error');
    }
  }
  function criarEscritorios() {
    setDefaultEscritorio(ESCRITORIO_DEFAULT);
    toggleDrawer();
  }

  async function editarEscritorios(escritorioId: string) {
    try {
      const escritorioData = await obter(escritorioId);
      setDefaultEscritorio(escritorioData.data);
      toggleDrawer();
    } catch {}
  }

  function ProcurarEscritorios(data?: string) {
    setEscritorioSearch(data ?? '');
  }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [escritorioSearch]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Gestão de Escritórios" />
      <OptLayoutProvider theme={Theme().insideTheme}>
        <GeralWrapper>
          <HeaderContent titulo="Escritórios" height="100%">
            <ButtonWrapper pb="1.4rem">
              <IconButton color onClick={toggleDrawer} />
            </ButtonWrapper>
            <FormDrawer isOpen={isOpen} toggleDrawer={criarEscritorios} title={'Cadastro de novo escritório:'}>
              <EscritorioForm
                toggleDrawer={toggleDrawer}
                refreshGrid={refreshGrid}
                defaultEscritorio={defaultEscritorio}
              />
            </FormDrawer>
            <OptSearchField placeholder="Buscar por escritorios" onSearch={ProcurarEscritorios} />
          </HeaderContent>
          <GridEscritorios
            carregar={carregar}
            ref={ref}
            removerEscritorios={removerEscritorios}
            editarEscritorios={editarEscritorios}
          />
        </GeralWrapper>
      </OptLayoutProvider>
    </OptSideLayoutContent>
  );
};
