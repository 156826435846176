import { Grid, TextField } from '@mui/material';
import { OptConfirmationDialog, OptLoading } from '@optsol/react';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { FinanceiroPagamentoFormModel } from '../../models/Financeiro/FinanceiroPagamento.model';
import useFinanceiroService from '../../services/financeiro.service';

interface FinanceiroFormProps {
  toggleDrawer: () => void;
  refreshGrid(): void;
  isOpen: boolean;
  defaultFinanceiro?: FinanceiroPagamentoFormModel;
}

export function FinanceiroPagamentoForm({ toggleDrawer, refreshGrid, isOpen, defaultFinanceiro }: FinanceiroFormProps) {
  const [valorPagamento, setValorPagamento] = useState<number>(0);

  const { enqueueSnackbar } = useSnackbar();

  const { alterarPagamento } = useFinanceiroService();

  const onEdit = async () => {
    if (defaultFinanceiro && defaultFinanceiro.id) {
      try {
        await alterarPagamento(defaultFinanceiro.id, {
          valorPago: valorPagamento,
        });
        toggleDrawer();
        refreshGrid();
      } catch (erro) {
        console.log(erro);
      } finally {
      }
    }
  };

  return (
    <>
      <OptConfirmationDialog
        open={isOpen}
        children={
          defaultFinanceiro ? (
            <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
              <Grid item xs={6}>
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator={true}
                  prefix={'R$'}
                  disabled
                  value={defaultFinanceiro.valorOriginal}
                  name="valorOriginal"
                  label="Valor Original"
                  placeholder="16.000,00"
                />
              </Grid>
              <Grid item xs={6}>
                <NumberFormat
                  customInput={TextField}
                  decimalScale={2}
                  fixedDecimalScale
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={'R$'}
                  onValueChange={(values) => {
                    setValorPagamento(values.floatValue ?? 0);
                  }}
                  name="valorPago"
                  label="Valor Pago"
                  placeholder="R$16.000,00"
                />
              </Grid>
            </Grid>
          ) : (
            <OptLoading />
          )
        }
        onConfirm={() => {
          if (valorPagamento && valorPagamento > 0) {
            onEdit();
          } else {
            // handleClick();
            enqueueSnackbar('Valor de pagamento inválido.', { variant: 'error' });
          }
        }}
        onCancel={() => {
          toggleDrawer();
        }}
        title={'Pagamento:'}
      />
    </>
  );
}

