import { mdiDelete } from '@mdi/js';
import { OptGrid, OptGridColumn, OptGridRef } from '@optsol/react';
import { forwardRef } from 'react';
import { TipoDocumentoMask } from '../../components/TipoDocumentoMask';
import { FileProcessoModel } from '../../models/Processo/FileProcesso.model';

interface DocumentosGridProps {
  data: FileProcessoModel[];
  editandoProcesso: boolean;
  removerDocumento: (id: string) => void;
}

export const GridDocumentosProcesso = forwardRef<OptGridRef | undefined, DocumentosGridProps>(
  ({ data, editandoProcesso, removerDocumento }, ref) => {
    const columns: OptGridColumn<FileProcessoModel>[] = [
      {
        title: 'Id',
        field: 'id',
        hidden: true,
      },
      {
        title: 'Documento',
        field: 'arquivo',
        render: (data: FileProcessoModel) => {
          if (typeof data.arquivo === 'string') {
            return <>{data.arquivo}</>;
          } else return <>{data.arquivo.name}</>;
        },
      },
      {
        title: 'Tipo',
        field: 'tipo',
        render: (data: FileProcessoModel) => {
          return <TipoDocumentoMask tipo={data.tipo} />;
        },
      },
    ];

    return (
      <>
        <OptGrid
          title=""
          columns={columns}
          data={data}
          ref={ref as any}
          actions={
            !editandoProcesso
              ? [
                  (rowData) => ({
                    icon: { path: mdiDelete },
                    tooltip: 'Deletar documento',
                    onClick: () => {
                      if (rowData.id) {
                        return removerDocumento(rowData.id);
                      }
                    },
                    disabled: false,
                  }),
                ]
              : undefined
          }
          actionsPosition={'end'}
        />
      </>
    );
  },
);
