import NumberFormat from 'react-number-format';

export interface TelefoneDuploProps {
  telefone: string;
}

export default function MascaraTelefoneDuplo(props: TelefoneDuploProps) {
  return (
    <>
      <NumberFormat
        value={props.telefone}
        format={String(props.telefone).length > 10 ? '(##) # ####-####' : '(##) ####-#####'}
        name="telefone"
        displayType="text"
        placeholder="(00) 0000-0000"
      />
    </>
  );
}
