import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';
import googlePlay from '../../shared/Imagens/Google_Play.svg';
import * as S from './Button.styles';

interface Props {
  onClick: () => void;
  color: string;
  icon?: string;
  p?: string;
  size?: number;
  titulo: string;
  loja: string;
}
export const IconButtonApp = (props: Props) => {
  return (
    <S.StyledAppButton onClick={props.onClick} color={props.color}>
      {!props.icon ? (
        <Box minWidth={40}>
          <img src={googlePlay} alt={'iconGoogle'} />
        </Box>
      ) : (
        <Box>
          <Icon path={props.icon ? props.icon : mdiPlusCircle} size={props.size ? props.size : 1} color={props.color} />
        </Box>
      )}
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography textAlign={'left'} fontSize={14} color={props.color} display="flex" lineHeight={0}>
          {props.titulo}
        </Typography>
        <Typography variant="h5" textAlign={'right'} color={props.color}>
          {props.loja}
        </Typography>
      </Box>
    </S.StyledAppButton>
  );
};
