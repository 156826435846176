import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { useSnackbar } from 'notistack';
import React, { createContext, useCallback, useReducer } from 'react';
import { useAuthenticationService } from '../../services/auth/authentication.service';
import { useMsalService } from '../../services/auth/msal.service';
import { MsalConfig, pca } from '../../services/auth/msalConfig';
import { AuthenticationActions } from './authenticationActions';
import {
  AuthenticationDispatch,
  AuthenticationReducer,
  AUTHENTICATION_INITIAL_DISPATCH,
} from './authenticationReducer';
import { AuthenticationState, AUTHENTICATION_INITIAL_STATE } from './authenticationState';

const AuthenticationStateContext = createContext<AuthenticationState>(AUTHENTICATION_INITIAL_STATE);
const AuthenticationDispatchContext = createContext<AuthenticationDispatch>(AUTHENTICATION_INITIAL_DISPATCH);

type AuthenticationProps = { children: React.ReactNode };

function AuthenticationProvider({ children }: AuthenticationProps) {
  const [state, dispatch] = useReducer(AuthenticationReducer, AUTHENTICATION_INITIAL_STATE);

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{ scopes: MsalConfig.scopes }}>
        <AuthenticationStateContext.Provider value={state}>
          <AuthenticationDispatchContext.Provider value={dispatch}>{children}</AuthenticationDispatchContext.Provider>
        </AuthenticationStateContext.Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

function useAuthenticationContext() {
  const state = React.useContext(AuthenticationStateContext);
  const authenticationService = useAuthenticationService();
  const { getAccessToken } = useMsalService();
  // const { enqueueSnackbar } = useSnackbar();

  if (state === undefined) {
    throw new Error('useAuthenticationState deve ser utilizando dentro de um AuthenticationProvider');
  }

  const dispatch = React.useContext(AuthenticationDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useAuthenticationDispatch deve ser utilizando dentro de um AuthenticationProvider');
  }

  const actions = AuthenticationActions;

  const hasAccess = useCallback(
    (access: string) => {
      if (!state.userInfo.data) return false;

      // return state.userInfo.data.accesses.some((x: any) => x === access);
      return state.userInfo.data.grupoUsuario.nome === access;
    },
    [state.userInfo.data],
  );

  async function inicializar() {
    dispatch({ type: actions.LOADING_USER_INFO, payload: true });

    try {
      const token = await getAccessToken();
      if (token) {
        definirToken(token);
        const notificationToken = await getAccessToken([MsalConfig.notificationScope]);
        definirNotificationToken(notificationToken);
        await carregarUserInfo(token);
      } else {
        dispatch({ type: actions.LOADING_USER_INFO, payload: false });
        // enqueueSnackbar('Falha na obtenção de token de autenticação', {
        // variant: 'error',
        // });
      }
    } catch (err) {
      dispatch({ type: actions.LOADING_USER_INFO, payload: false });
      throw err;
    }
  }

  async function carregarUserInfo(token?: string) {
    const result = await authenticationService.getUserInfo(token, state.tenantId);

    dispatch({ type: actions.CARREGAR_USER_INFO, payload: result });
  }

  function atualizarUserInfo(fotoPerfil: string, name: string) {
    const userInfo = { ...state.userInfo.data! };
    // userInfo.fotoPerfil = fotoPerfil;
    // userInfo.userName = name;
    userInfo.nome = name;

    dispatch({ type: actions.CARREGAR_USER_INFO, payload: userInfo });
  }

  function definirTenant(tenantId?: string) {
    dispatch({ type: actions.DEFINIR_TENANT, payload: tenantId });
  }

  function definirToken(token?: string) {
    if (token !== state.token) dispatch({ type: actions.DEFINIR_TOKEN, payload: token });
  }

  function definirNotificationToken(notificationToken?: string) {
    if (notificationToken !== state.notificationToken)
      dispatch({ type: actions.DEFINIR_NOTIFICATION_TOKEN, payload: notificationToken });
  }
  return {
    state,
    carregarUserInfo,
    hasAccess,
    definirTenant,
    definirToken,
    inicializar,
    atualizarUserInfo,
  };
}

export { AuthenticationProvider, useAuthenticationContext };
