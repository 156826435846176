import { Box, Typography } from '@mui/material';

interface NoDataResponseProps {
  mensagem: string;
}

export function NoDataResponse({ mensagem }: NoDataResponseProps) {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography sx={{ fontSize: '1.8em' }}>{mensagem}</Typography>
    </Box>
  );
}
