import { OptGridRef, OptLayoutProvider, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GeralWrapper } from '../../../components/Layout/Layout';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { ProcessoGetResponse } from '../../../models/dtos/ProcessoSearchResponse.model';
import { Routes } from '../../../routes';
import useProcessoService from '../../../services/processo.service';
import Theme from '../../../shared/theme';
import { GridProcessosPastas } from './GridProcessosPastas';
import * as S from './styles/index';

export const ProcessosDocPastas = () => {
  const [processo, setProcesso] = useState<ProcessoGetResponse>();
  const [fileNumber, setFileNumber] = useState({
    peticoes: 0,
    procuracoes: 0,
    provas: 0,
    comprovantes: 0,
  });
  const { processoId, documentoId } = useParams<{ processoId: string , documentoId: string}>();

  const { obterTodosDocumentos, obter } = useProcessoService();

  async function obterProcesso() {
    try {
      const processoData = await obter(processoId);
      setProcesso(processoData.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function obterDocumentos() {
    try {
      const documentosData = await obterTodosDocumentos(processoId);
      const Peticoes = documentosData.data.filter((props) => props.tipo === 1);
      const Procuracoes = documentosData.data.filter((props) => props.tipo === 2);
      const Provas = documentosData.data.filter((props) => props.tipo === 3);
      const Comprovantes = documentosData.data.filter((props) => props.tipo === 4);
      const fileNumberNew = {
        peticoes: Peticoes.length,
        procuracoes: Procuracoes.length,
        provas: Provas.length,
        comprovantes: Comprovantes.length,
      };
      setFileNumber(fileNumberNew);
    } finally {
    }
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [fileNumber]);

  useEffect(() => {
    obterProcesso();
    obterDocumentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Gerenciador eletrônico de documentos" goBackRoute={Routes.Documentos.Detalhes(documentoId)} />
      <GeralWrapper>
        <S.Header>
          <h3>Pastas: Processo {processo?.numero}</h3>
          <S.HeaderFunctions>
            <OptLayoutProvider theme={Theme().insideTheme}></OptLayoutProvider>
          </S.HeaderFunctions>
        </S.Header>
        <GridProcessosPastas ArrayPasta={fileNumber} ref={ref} />
      </GeralWrapper>
    </OptSideLayoutContent>
  );
};
