import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, ButtonBase, Drawer } from '@mui/material';
import { OptLayoutProvider } from '@optsol/react';
import { PropsWithChildren } from 'react';
import Theme from '../../shared/theme';
import * as T from './Drawer.styles';

interface DrawerProps {
  title: string;
  isOpen: boolean;
  toggleDrawer: () => void;
}

const CompromissoDrawer = ({ title, isOpen, toggleDrawer, children }: PropsWithChildren<DrawerProps>) => {
  function handleCloseAll() {
    toggleDrawer();
  }

  return (
    <OptLayoutProvider theme={Theme().insideTheme}>
      <Drawer open={isOpen} onClose={handleCloseAll} anchor="right" sx={{ zIndex: '1250' }}>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '750px',
            width: '35vw',
            flexDirection: 'column',
            gap: '15px',
          }}>
          <T.Header>
            <ButtonBase onClick={handleCloseAll} sx={{ borderRadius: '50%', padding: '2px' }}>
              <Icon path={mdiClose} size={0.8} color="#757985" />
            </ButtonBase>
            <h3 style={{ marginTop: '-4px' }}>{title}</h3>
          </T.Header>
          {children}
        </Box>
      </Drawer>
    </OptLayoutProvider>
  );
};

export default CompromissoDrawer;
