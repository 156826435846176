import { Box } from '@mui/material';
import React from 'react';

interface Props {
  titulo: string;
  height?: string;
}

const HeaderContent = ({ titulo, height, children }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        height: height ? height : '',
        gap: '0.8rem',
      }}>
      <p style={{ fontSize: '1.17em', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{titulo}</p>

      <Box display="flex" width="100%" justifyContent="space-between">
        {children}
      </Box>
    </Box>
  );
};

export default HeaderContent;
