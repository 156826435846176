import { Theme } from '@nivo/core';

export const chartTheme: Theme = {
  labels: {
    text: {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
  legends: {
    text: {
      fontSize: '12.5px',
    },
  },
  tooltip: {
    container: {
      marginRight: '130px',
    },
  },
};
