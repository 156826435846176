import { mdiCashCheck, mdiDelete } from '@mdi/js';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { StatusTextPagamentos } from '../../components/StatusText/StatusTextFinanceiro';
import { FinanceiroSearchResponse } from '../../models/dtos/FinanceiroSearchResponse.model';
import { Routes } from '../../routes';
import toBRCurrency from '../../shared/functions';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    ClientSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<FinanceiroSearchResponse>> | any;
  removerPagamento: (clientId: string) => void;
  confirmarPagamento: (id: string, valor: string) => void;

  //   editarCliente(clientId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridFinanceiro = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, removerPagamento, confirmarPagamento }, ref) => {
    const columns: OptGridColumn<FinanceiroSearchResponse>[] = [
      {
        title: 'id',
        field: 'id',
        hidden: true,
      },

      {
        title: 'Nome',
        field: 'nomeCliente',
        align: 'center',
      },
      {
        title: 'Status',
        field: 'status',
        align: 'center',
        render: (data) => {
          return (
            <>
              <StatusTextPagamentos statusDoPagamento={data.status} />
            </>
          );
        },
      },
      {
        title: 'Vencimento',
        field: 'dataVencimento',
        align: 'center',
        render: (data) => {
          const vencimento = new Date(data.dataVencimento);

          return (
            <>
              {vencimento.toLocaleDateString('pt-Br', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </>
          );
        },
      },
      {
        title: 'Quitação',
        field: 'dataQuitacao',
        align: 'center',

        render: (data) => {
          const quitacao = new Date(data.dataQuitacao);

          return (
            <>
              {quitacao.toLocaleString('pt-Br', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }) === '01/01/1'
                ? '-'
                : quitacao.toLocaleString('pt-Br', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
            </>
          );
        },
      },
      {
        title: 'Valor original',
        field: 'valorOriginal',
        align: 'center',

        render: (data) => {
          return <>{toBRCurrency(Number(data.valorOriginal))}</>;
        },
      },
      {
        title: 'Valor pago',
        field: 'valorPago',
        align: 'center',

        render: (data) => {
          return <>{toBRCurrency(Number(data.valorPago))}</>;
        },
      },
    ];
    const history = useHistory();
    return (
      <OptLayoutProvider theme={Theme().insideTheme}>
        <OptGrid
          title=""
          ref={ref as any}
          data={carregar}
          options={options}
          headerTitlePosition={'center'}
          columns={columns}
          actionsPosition={'end'}
          actions={[
            (rowData) => ({
              icon: { path: mdiCashCheck },
              tooltip: 'Pagamento',
              onClick: () => confirmarPagamento(rowData.id, rowData.valorOriginal),
              disabled: false,
            }),
            (rowData) => ({
              icon: { path: mdiDelete },
              tooltip: 'Deletar',
              onClick: () => removerPagamento(rowData.id),
              disabled: false,
            }),
          ]}
          onRowClick={(rowData) => {
            history.push(Routes.Financeiro.FinanceiroDetalhes(rowData.id));
          }}
        />
      </OptLayoutProvider>
    );
  },
);
