import { Box, Tab, Tabs } from '@mui/material';
import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { ButtonWrapper } from '../../components/Button/Button.styles';
import { IconButton } from '../../components/Button/IconButton';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { PerguntaFrequenteForm } from '../../features/PerguntaFrequenteForm/PerguntaFrequenteForm';
import { AndamentoForm } from '../../features/ProcessoForm/AndamentoForm';
import { PerguntasFrequentesFormModel } from '../../models/PerguntasFrequentes/PerguntasFrequentes.model';
import { AndamentosProcessoModel } from '../../models/Processo/Processo.model';
import usePerguntasFrequentesService from '../../services/perguntasFrequentes.service';
import useTraducaoService from '../../services/traducao.service';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridPerguntasFrequentes } from './PerguntasFrequentesGrid';
import { GridAndamentosTraduções } from './TraducoesAndamentosGrid';

export default function PerguntasFrequentes() {
  const {
    state: { userInfo },
  } = useAuthenticationContext();

  const [perguntaSearch, setPerguntaSearch] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [tabDisabled, setTabDisabled] = useState({
    tab1: false,
    tab2: false,
  });
  const [toggleAndamentoDrawer, setToggleAndamentoDrawer] = useState(false);
  const [defaultPergunta, setDefaultPergunta] = useState<PerguntasFrequentesFormModel>({
    id: '',
    pergunta: '',
    resposta: '',
    escritorioId: userInfo.data?.escritorio.id,
  });
  const [defaultTraducao, setDefaultTraducao] = useState<AndamentosProcessoModel>({
    traducao: '',
    id: '',
    data: new Date(),
    escritorioId: userInfo.data?.escritorio.id,
    textoOriginal: '',
    codigo: 351,
    visivel: true,
  });

  function ProcurarPergunta(data?: string) {
    setPerguntaSearch(data ?? '');
  }

  const { obter, obterGridPerguntasFrequentes, remover } = usePerguntasFrequentesService();
  const { obterTraducao, obterGridTraducao, removerTraducao } = useTraducaoService();

  const carregar = (
    query: OptGridRequest,
    PerguntaSearchRequest: PaginatedSearchRequest = { termoBuscado: perguntaSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: PerguntaSearchRequest,
    };
    return obterGridPerguntasFrequentes(request);
  };
  const carregarTraducao = (
    query: OptGridRequest,
    TraducaoSearchRequest: PaginatedSearchRequest = { termoBuscado: perguntaSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: TraducaoSearchRequest,
    };
    return obterGridTraducao(request);
  };

  function toggleAndamento() {
    setToggleAndamentoDrawer(!toggleAndamentoDrawer);
  }

  function criarPergunta() {
    setDefaultPergunta({
      id: '',
      pergunta: '',
      resposta: '',
      escritorioId: userInfo.data?.escritorio.id,
    });
    toggleAndamento();
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  function refreshGrid() {
    if (tabValue === 0) {
      if (refTraducao.current) {
        refTraducao.current.refresh();
      }
    } else {
      if (ref.current) {
        ref.current.refresh();
      }
    }
  }

  async function removerPergunta(perguntaId: string) {
    try {
      await remover(perguntaId);
      ref.current?.refresh();
    } catch {
      console.log('error');
    }
  }
  async function editarPergunta(perguntaId: string) {
    try {
      const perguntaData = await obter(perguntaId);
      setDefaultPergunta(perguntaData.data);
      toggleAndamento();
    } catch {}
  }
  async function traducaoRemover(traducaoId: string) {
    try {
      await removerTraducao(traducaoId);
      refTraducao.current?.refresh();
    } catch {
      console.log('error');
    }
  }
  async function editarTraducao(traducaoId: string) {
    try {
      const traducaoData = await obterTraducao(traducaoId);
      setDefaultTraducao(traducaoData.data);
      toggleAndamento();
    } catch {}
  }

  const ref = useRef<OptGridRef>();
  const refTraducao = useRef<OptGridRef>();

  useEffect(() => {
    if (tabValue === 0) {
      if (refTraducao.current) {
        refTraducao.current.refresh();
      }
    } else {
      if (ref.current) {
        ref.current.refresh();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perguntaSearch]);
  return (
    <OptSideLayoutContent>
      <Toolbar title="FAQ/Tradução" />
      <OptLayoutProvider theme={Theme().insideTheme}>
        <GeralWrapper>
          <HeaderContent height="100%" titulo={tabValue === 0 ? 'Andamentos' : 'Perguntas Frequentes'}>
            {tabValue === 1 ? (
              <ButtonWrapper pb="1.4rem">
                <IconButton color onClick={criarPergunta} />
              </ButtonWrapper>
            ) : (
              <Box></Box>
            )}
            <FormDrawer
              title={tabValue === 0 ? 'Cadastrar andamento' : 'Cadastrar pergunta'}
              toggleDrawer={toggleAndamento}
              isOpen={toggleAndamentoDrawer}>
              {tabValue === 0 ? (
                <AndamentoForm
                  defaultAndamento={defaultTraducao}
                  toggleDrawer={toggleAndamento}
                  refreshGrid={refreshGrid}
                />
              ) : (
                <PerguntaFrequenteForm
                  toggleDrawer={toggleAndamento}
                  defaultPerguntaFrequente={defaultPergunta}
                  refreshGrid={refreshGrid}
                />
              )}
            </FormDrawer>
            <OptSearchField
              placeholder={tabValue === 0 ? 'Buscar andamento' : 'Buscar pergunta'}
              onSearch={ProcurarPergunta}
            />
          </HeaderContent>

          <Tabs
            sx={{ width: '100%', minWidth: '100%' }}
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab disabled={tabDisabled.tab1} sx={{ width: '50%', minWidth: '50%' }} label="Andamentos" />
            <Tab disabled={tabDisabled.tab2} sx={{ width: '50%', minWidth: '50%' }} label="Perguntas frequentes" />
          </Tabs>
          {tabValue === 0 ? (
            <GridAndamentosTraduções
              removerAndamento={traducaoRemover}
              editarAndamento={editarTraducao}
              carregar={carregarTraducao}
              ref={refTraducao}
            />
          ) : (
            <GridPerguntasFrequentes
              editarPergunta={editarPergunta}
              ref={ref}
              removerPergunta={removerPergunta}
              carregar={carregar}
            />
          )}
        </GeralWrapper>
      </OptLayoutProvider>
    </OptSideLayoutContent>
  );
}
