import { mdiDelete, mdiPencil } from '@mdi/js';
import { OptGrid, OptGridColumn, OptGridOptions, OptGridRef, OptGridRequest, OptGridResponse } from '@optsol/react';
import { forwardRef } from 'react';
import { MascaraTelefone } from '../../components/MascaraTelefone/MascaraTelefone';
import { ClientesSearchResponse } from '../../models/dtos/ClientesSearchResponse.model';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    ClientSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<ClientesSearchResponse>>;
  removerCliente: (clientId: string) => void;
  editarCliente(clientId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridClientes = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, removerCliente, editarCliente }, ref) => {
    const columns: OptGridColumn<ClientesSearchResponse>[] = [
      {
        title: 'id',
        field: 'id',
        hidden: true,
      },

      {
        title: 'nomeEmpresa',
        field: 'nomeEmpresa',
        hidden: true,
      },
      {
        title: 'TelefoneFixo',
        field: 'telefone.fixo',
        hidden: true,
      },
      {
        title: 'Nome',
        field: 'nome',
        render: (data: ClientesSearchResponse) => {
          return <>{data.nome ? data.nome : data.nomeEmpresa}</>;
        },
      },
      {
        title: 'Tipo Pessoa',
        field: 'tipoPessoa',
        render: (data: ClientesSearchResponse) => {
          return <>{data.tipoPessoa === 1 ? 'Pessoa Física' : 'Pessoa Jurídica'}</>;
        },
      },
      {
        title: 'Telefone',
        field: 'telefone',
        render: (data: ClientesSearchResponse) => {
          return <MascaraTelefone telefone={data.telefone} />;
        },
      },
      {
        title: 'E-mail',
        field: 'email',
      },
    ];

    return (
      <>
        <OptGrid
          title=""
          ref={ref as any}
          data={carregar}
          options={options}
          columns={columns}
          headerTitlePosition="start"
          actionsPosition={'end'}
          actions={[
            (rowData) => ({
              icon: { path: mdiPencil },
              tooltip: 'Editar',
              onClick: () => editarCliente(rowData.id),
              disabled: false,
            }),
            (rowData) => ({
              icon: { path: mdiDelete },
              tooltip: 'Deletar',
              onClick: () => removerCliente(rowData.id),
              disabled: false,
            }),
          ]}
        />
      </>
    );
  },
);
