import * as Yup from 'yup';
import { validarCNPJ } from '../../shared/utils/functions';

import { Endereco, EnderecoFormModelSchema } from '../Endereco.model';

export interface Telefone {
  fixo?: string;
  celular: string;
}

export interface FileBlob {
  nome: string;
  nomeArquivoStorage: string;
}

export interface RedesSociais {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
}

export interface InformacoesBancarias {
  nomeBanco?: string;
  tipoConta?: number;
  nomeTitular?: string;
  chavePix?: string;
  agencia?: string;
  numeroConta?: string;
}

export interface Escritorio {
  cnpj: string;
  email: string;
  id: string;
  nome: string;
  whatsapp: string;
  telefone: Telefone;
  endereco: Endereco;
  redesSociais: RedesSociais;
  site: string | null;
  cor: string | null;
  imagem?: File | null;
  dadosFinanceiros: InformacoesBancarias | null;
  aceitaTraducaoAndamentoAutomatica?: boolean;
}
export type EscritorioFormModel = Partial<Escritorio>;

export const ESCRITORIO_DEFAULT: Escritorio = {
  nome: '',
  email: '',
  cnpj: '',
  telefone: {
    celular: '',
    fixo: '',
  },
  id: '',
  whatsapp: '',
  redesSociais: {
    facebook: '',
    instagram: '',
    linkedin: '',
  },
  imagem: null,
  site: '',
  cor: '',
  dadosFinanceiros: {
    chavePix: '',
    tipoConta: 0,
    nomeBanco: '',
    nomeTitular: '',
    agencia: '',
    numeroConta: '',
  },
  endereco: {
    bairro: '',
    cep: '',
    cidade: '',
    estado: '',
    rua: '',
    complemento: '',
  },
};

export const TelefoneSchema: Yup.SchemaOf<Telefone> = Yup.object().shape({
  fixo: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  celular: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
});

export const RedesSociaisSchema: Yup.SchemaOf<RedesSociais> = Yup.object().shape({
  facebook: Yup.string().notRequired().typeError('Campo obrigatório'),
  instagram: Yup.string().notRequired().typeError('Campo obrigatório'),
  linkedin: Yup.string().notRequired().typeError('Campo obrigatório'),
});

export const InformacoesBancariasSchema: Yup.SchemaOf<InformacoesBancarias> = Yup.object().shape({
  nomeBanco: Yup.mixed().notRequired().typeError('Campo obrigatório'),
  nomeTitular: Yup.mixed().notRequired().typeError('Campo obrigatório'),
  tipoConta: Yup.mixed().notRequired().typeError('Campo obrigatório'),
  chavePix: Yup.mixed().notRequired().typeError('Campo obrigatório'),
  agencia: Yup.mixed().notRequired().typeError('Campo obrigatório'),
  numeroConta: Yup.mixed().notRequired().typeError('Campo obrigatório'),
});

export const EscritorioFormModelSchema: Yup.SchemaOf<EscritorioFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  nome: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  telefone: TelefoneSchema.required().typeError('Campo obrigatório'),
  email: Yup.string().email('Email inválido').notRequired().typeError('Campo obrigatório'),
  endereco: EnderecoFormModelSchema.required().typeError('Campo obrigatório'),
  cnpj: Yup.string()
    .typeError('Campo obrigatório')
    .required('Campo obrigatório')
    .typeError('Campo obrigatório')
    .test('CNPJ Test', 'CNPJ Inválido', (value) => !!value && validarCNPJ(value)),
  redesSociais: RedesSociaisSchema.required().typeError('Campo obrigatório'),
  site: Yup.string().notRequired().nullable(),
  whatsapp: Yup.string().notRequired(),
  cor: Yup.string().notRequired(),
  imagem: Yup.mixed().notRequired(),
  dadosFinanceiros: InformacoesBancariasSchema.notRequired(),
  aceitaTraducaoAndamentoAutomatica: Yup.boolean().notRequired(),
});
