import { agendamentoDia, AgendamentoFormModel, dragCompromissoModel } from '../models/Agendamento/Agendamento.model';
import { AgendamentoFormatoApi } from '../models/Agendamento/AgendamentoFormatoApi';
import { AgendamentoGridSearchResponse } from '../models/dtos/AgendamentoSearchResponse.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useAgendamentoService() {
  const { get, post, put, remove, gridSearch } = useApi();

  // async function getToken(){
  //   const token = await getAccessToken();
  //   console.log(token)
  //   return token
  // }

  // const token = getToken();

  //   const config: AxiosRequestConfig = {};
  //   config.headers = {};

  //   config.headers.Authorization = `Bearer ${token}`;

  const baseApi = 'api/usuario/compromissos';

  const obterAgenda = async (id: string) => {
    return get<ApiResponse<AgendamentoFormModel>>(`api/usuario/compromisso/${id}`);
  };

  const obterTodos = async (data?: any) => {
    return post<ApiResponse<AgendamentoGridSearchResponse[]>>(`api/usuario/compromissos`, data);
  };

  const obterGridAgendamentos = async (data: SearchRequest<{}>) => {
    return gridSearch<AgendamentoFormatoApi>(`${baseApi}/paginated`, data);
  };

  const obterCompromissosMes = async (data: { inicio: Date; termino: Date }) => {
    return post<ApiResponse<AgendamentoGridSearchResponse[]>>('api/usuario/compromissos-mes', data);
  };

  const enviar = async (data: AgendamentoFormModel) => {
    return post('api/usuario/compromisso', data);
  };

  const alterar = async (id: string, data: AgendamentoFormModel) => {
    return put<AgendamentoFormModel | dragCompromissoModel>(`api/usuario/compromisso/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<AgendamentoFormModel>(`api/usuario/compromisso/${id}`);
  };

  const obterDia = async () => {
    return get<ApiResponse<agendamentoDia>>(`${baseApi}/qtdDia`);
  };

  return { obterAgenda, enviar, alterar, remover, obterGridAgendamentos, obterTodos, obterDia, obterCompromissosMes };
}
