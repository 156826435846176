import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { ButtonWrapper } from '../../components/Button/Button.styles';
import { IconButton } from '../../components/Button/IconButton';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { NpsForm } from '../../features/NpsForm/NpsForm';
import { NPS_DEFAULT } from '../../models/Nps/Nps.model';
import useNpsService from '../../services/nps.service';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridNps } from './GridNps';

export const Nps = () => {
  const [clientSearch, setClientSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [defaultNps, setDefaultNps] = useState(NPS_DEFAULT);

  const { obterGridNps, remover, obter } = useNpsService();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const carregar = (
    query: OptGridRequest,
    ClientSearchRequest: PaginatedSearchRequest = { termoBuscado: clientSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: ClientSearchRequest,
    };
    return obterGridNps(request);
  };

  async function removerCliente(clientId: string) {
    try {
      await remover(clientId);
      ref.current?.refresh();
    } catch {
      console.log('error');
    }
  }
  function criarCliente() {
    setDefaultNps(NPS_DEFAULT);
    toggleDrawer();
  }

  async function editarCliente(id: string) {
    try {
      const npsData = await obter(id);
      setDefaultNps({
        id: npsData.data.id,
        pergunta: npsData.data.pergunta,
      });
      toggleDrawer();
    } catch {}
  }

  function ProcurarCliente(data?: string) {
    setClientSearch(data ?? '');
  }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [clientSearch]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="NPS" />
      <GeralWrapper>
        <OptLayoutProvider theme={Theme().insideTheme}>
          <HeaderContent titulo="Perguntas" height="100%">
            <ButtonWrapper pb="1.4rem">
              <IconButton onClick={toggleDrawer} color />
            </ButtonWrapper>
            <FormDrawer title={'Cadastro de nova pergunta:'} isOpen={isOpen} toggleDrawer={criarCliente}>
              <NpsForm toggleDrawer={toggleDrawer} refreshGrid={refreshGrid} defaultNpsForm={defaultNps} />
            </FormDrawer>
            <OptSearchField placeholder="Buscar pergunta" onSearch={ProcurarCliente} />
          </HeaderContent>
        </OptLayoutProvider>
        <GridNps carregar={carregar} ref={ref} removerNps={removerCliente} editarNps={editarCliente} />
      </GeralWrapper>
    </OptSideLayoutContent>
  );
};
