import styled from 'styled-components';

export const styledBadge = styled.span`
  background-color: ${(p) => p.color};
  color: #fff;
  position: absolute;
  margin-left: 0.7rem;
  top: 1.6em;
  display: inline-block;
  font-size: 0.5em;
  height: 1.1rem;
  width: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  text-align: center;
  border-radius: 50%;
`;
