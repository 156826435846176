import * as Yup from 'yup';
import { validarCNPJ, validarCPF } from '../../shared/utils/functions';
import { Endereco, EnderecoFormModelSchema } from '../Endereco.model';

export interface Telefone {
  fixo?: string;
  celular?: string | null;
}

export interface Cliente {
  cnpj: string | null;
  cpf: string | null;
  dataNascimento: Date | null;
  email: string;
  estadoCivil?: number | null;
  id: string;
  nome: string | null;
  nomeEmpresa: string | null;
  nomeFantasia: string | null;
  telefone: Telefone;
  tipoPessoa: number;
  endereco: Endereco;
}
export type ClienteFormModel = Partial<Cliente>;

export const CLIENTE_DEFAULT: ClienteFormModel = {
  nome: '',
  cpf: '',
  cnpj: '',
  nomeEmpresa: '',
  nomeFantasia: '',
  email: '',
  estadoCivil: null,
  telefone: {
    celular: '',
    fixo: '',
  },
  dataNascimento: null,
  id: '',
  tipoPessoa: 1,
};

export const TelefoneSchema: Yup.SchemaOf<Telefone> = Yup.object().shape({
  fixo: Yup.string()
    .notRequired()
    .when('cnpj', {
      is: (value: string) => {
        return !!value;
      },
      then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
      otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
    }),
  celular: Yup.string().when('cpf', {
    is: (value: string) => {
      return !!value;
    },
    then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
    otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
  }),
});

export const ClienteFormModelSchema: Yup.SchemaOf<ClienteFormModel> = Yup.object().shape({
  nome: Yup.string().when('cpf', {
    is: (value: string) => {
      return !!value;
    },
    then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
    otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
  }),
  nomeEmpresa: Yup.string()
    .nullable()
    .when('cnpj', {
      is: (value: string) => {
        return !!value;
      },
      then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
      otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
    }),
  nomeFantasia: Yup.string().notRequired().typeError('Campo obrigatório').nullable(),
  cnpj: Yup.string()
    .typeError('Campo obrigatório')
    .nullable()
    .when('cpf', {
      is: (value: string) => !!value, // Verifica se o valor do CPF é verdadeiro
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema
          .required('Campo obrigatório')
          .test('CNPJ Test', 'CNPJ Inválido', (value) => !!value && validarCNPJ(value)),
    }),

  // .test('required', 'Campo obrigatório', (value, ctx) => !ctx.parent.cpf),
  cpf: Yup.string()
    .typeError('Campo obrigatório')
    .test('required', 'Campo obrigatório', (value, ctx) => ctx.parent.cnpj || value)
    .test('CPF Test', 'CPF Inválido', (value) => !value || (!!value && validarCPF(value))),

  email: Yup.string().email('Email inválido').required('Campo obrigatório').typeError('Campo obrigatório'),
  estadoCivil: Yup.mixed().when('cpf', {
    is: (value: string) => {
      return !!value;
    },
    then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
    otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
  }),
  dataNascimento: Yup.mixed().when('cpf', {
    is: (value: string) => {
      return !!value;
    },
    then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
    otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
  }),
  telefone: TelefoneSchema.required().typeError('Campo obrigatório'),
  id: Yup.string().notRequired(),
  endereco: EnderecoFormModelSchema.required().typeError('Campo obrigatório'),
  tipoPessoa: Yup.number().notRequired(),
});
