import { TraducaoSearchResponse } from '../models/dtos/TraducaoSearchResponse.model';
import { AndamentosProcessoModel } from '../models/Processo/Processo.model';
import { objectToFormData } from '../shared/functions';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useTraducaoService() {
  const { get, post, put, remove, gridSearch } = useApi();

  const baseApi = 'api/traducao-andamento';

  const obterTraducao = async (id: string) => {
    return get<ApiResponse<AndamentosProcessoModel>>(`${baseApi}/${id}`);
  };

  const obterGridTraducao = async (data: SearchRequest<{}>) => {
    return gridSearch<TraducaoSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterTodosTraducao = async () => {
    return get<AndamentosProcessoModel>(`${baseApi}`);
  };

  const enviar = async (data: AndamentosProcessoModel) => {
    const formData = objectToFormData(data);
    return post(`${baseApi}/salvar`, formData);
  };

  const alterar = async (id: string, data: AndamentosProcessoModel) => {
    const formData = objectToFormData(data);
    return put<AndamentosProcessoModel>(`${baseApi}/${id}/alterar`, formData);
  };

  const removerTraducao = async (id: string) => {
    return remove<AndamentosProcessoModel>(`${baseApi}/${id}`);
  };

  return { obterTraducao, enviar, alterar, removerTraducao, obterTodosTraducao, obterGridTraducao };
}
