import * as Yup from 'yup';
import { DocumentoProcesso } from '../Documento/DocumentoProcesso..model';

export interface ProcessoFormModel {
  id?: string;
  clienteId: string | null;
  responsavelId: string | null;
  numero: string;
  documentosProcesso?: DocumentoProcesso[];
}

export interface ProcessoMultiFormModel {
  processo: ProcessoFormModel[];
}

export interface LembretesModel {
  lembretes: number[];
  processos: string[];
}

export interface InformacoesProcessoModel {
  processo: string;
  vara: string;
  acao: string;
  valor: number;
  peticaoInicial: string;
  dataDistribuicao: Date;
  natureza: string;
  motivoDistribuicao: string;
  dataAjuizamento: Date;
  assuntoPrincipal: string;
}

export interface InformacoesPartesProcessoModel {
  requerente: string[];
  requerido: string[];
}

export interface AndamentosProcessoModel {
  id?: string;
  data?: Date;
  traducao: string | null;
  escritorioId?: string;
  codigo?: number;
  textoOriginal?: string;
  visivel: boolean;
  traducaoInteligenciaArtificial?: string;
  prioridadeProcesso?: number;
  eAgora?: string | null;
  saibaMais?: string | null;
  iconeAndamento?: File | null;
  nomeImagem?: string | null;
}

export interface AdicionarAdvogadoProcessoModel {
  advogadoId: string;
}

export const TRADUCAO_DEFAULT: AndamentosProcessoModel = {
  data: new Date(),
  traducao: '',
  id: '',
  textoOriginal: '',
  codigo: 0,
  visivel: true,
  prioridadeProcesso: 0,
  eAgora: '',
  saibaMais: '',
  iconeAndamento: null,
};

export const PROCESSO_DEFAULT: ProcessoMultiFormModel = {
  processo: [{ clienteId: null, responsavelId: null, numero: '', documentosProcesso: [] }],
};

export const LISTA_PROCESSO_DEFAULT: ProcessoFormModel[] = [
  { clienteId: null, responsavelId: null, numero: '', documentosProcesso: [] },
];

export const DocumentoProcessoSchema: Yup.SchemaOf<DocumentoProcesso> = Yup.object()
  .shape({
    id: Yup.string().notRequired(),
    tipo: Yup.number().required('Campo obrigatório').typeError('Campo obrigatório'),
    arquivo: Yup.mixed().required('Campo obrigatório'),
  })
  .required();

export const ProcessoFormSchema: Yup.SchemaOf<ProcessoFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  clienteId: Yup.mixed().required('Campo obrigatório').typeError('Campo obrigatório'),
  responsavelId: Yup.mixed().required('Campo obrigatório').typeError('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  documentosProcesso: Yup.array().of(DocumentoProcessoSchema).notRequired(),
});

export const ProcessoMultiFormModelSchema: Yup.SchemaOf<ProcessoMultiFormModel> = Yup.object().shape({
  processo: Yup.array().of(ProcessoFormSchema).required('Campo obrigatório'),
});
