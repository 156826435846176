 export interface AssinaturaModel {
    tipo: string,
    nome:string,
    referencia: string,
    id: string,
    data:string,
    status: string
  }

  export interface CadastroAssinaturaModel {
    signatariosId: string[];
    usuarioLogadoSignatario: boolean;
    documento: FormData | null | File;
  }
  
  export type AssinaturaFormModel = Partial<CadastroAssinaturaModel>;

  
export const ASSINATURA_DEFAULT: CadastroAssinaturaModel = {
  signatariosId: [],
  documento: null,
  usuarioLogadoSignatario: false,

};
