import { mdiDelete, mdiPencil } from '@mdi/js';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { NpsSearchResponse } from '../../models/dtos/NpsSearchResponse.model';
import { Routes } from '../../routes';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    NpsSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<NpsSearchResponse>>;
  removerNps: (clientId: string) => void;
  editarNps(clientId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridNps = forwardRef<OptGridRef | undefined, GridProps>(({ carregar, removerNps, editarNps }, ref) => {
  const columns: OptGridColumn<NpsSearchResponse>[] = [
    {
      title: 'id',
      field: 'id',
      hidden: true,
    },

    {
      title: 'Perguntas',
      width: 850,
      field: 'pergunta',
    },
    {
      title: 'Data de criação',
      width: 150,

      field: 'dataCriacao',
      render: (data) => {
        const termino = new Date(data.dataCriacao);

        return (
          <>
            {termino.toLocaleTimeString('pt-Br', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        );
      },
    },
    {
      title: 'Data do último envio',
      field: 'dataUltimoEnvio',
      render: (data) => {
        const termino = new Date(data.dataUltimoEnvio);

        return (
          <>
            {termino.toLocaleTimeString('pt-Br', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        );
      },
    },
  ];
  let history = useHistory();
  return (
    <OptLayoutProvider theme={Theme().insideTheme}>
      <OptGrid
        title=""
        ref={ref as any}
        data={carregar}
        options={options}
        columns={columns}
        onRowClick={(rowData) => {
          history.push(Routes.Nps.NpsDetalhes(rowData.id));
        }}
        headerTitlePosition="start"
        actionsPosition={'end'}
        actions={[
          (rowData) => ({
            icon: { path: mdiPencil },
            tooltip: 'Editar',
            onClick: () => editarNps(rowData.id),
            disabled: false,
          }),
          (rowData) => ({
            icon: { path: mdiDelete },
            tooltip: 'Deletar',
            onClick: () => removerNps(rowData.id),
            disabled: false,
          }),
        ]}
      />
    </OptLayoutProvider>
  );
});
