import { transparentize } from "polished"
import styled from "styled-components"
export const StyledProgress = styled.progress`
width: 100%;
height: 3px;
border-radius: 10px;
::-webkit-progress-bar {
    background-color:${(props)=>{ return transparentize(0.75,props.color!)}};

}
::-webkit-progress-value {
    
    background-color: ${(props)=>{  return props.color}}

}
`