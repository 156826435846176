import * as B from './Button.styles';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import Theme from '../../shared/theme';

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant: 'danger' | 'text' | 'primary';
  label?: string;
  span?: boolean;
}

export default function Button({ label, variant, span, ref, ...props }: ButtonProps) {
  return (
    <>
      {!span ? (
        <B.Wrapper variant={variant} {...props}>
          {label}
        </B.Wrapper>
      ) : (
        <B.SpanWrapper variant={variant} color={Theme().insideTheme.light?.primary} {...props}>
          {label}
        </B.SpanWrapper>
      )}
    </>
  );
}
