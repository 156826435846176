import { Box, FormControlLabel, Switch } from '@mui/material';
import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { ButtonWrapper } from '../../components/Button/Button.styles';
import { IconButton } from '../../components/Button/IconButton';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { SectionsClaims } from '../../contexts/claims/sectionsClaims';
import { UsuarioForm } from '../../features/UsuarioForm/UsuarioForm';
import { USER_ADVOGADO_DEFAULT } from '../../models/Advogado/UserAdvogado.model';
import { USUARIO_DEFAULT, UsuarioFormModel } from '../../models/Usuario/usuario.model';
import useUsuarioService from '../../services/usuario.service';
import Theme from '../../shared/theme';
import { PaginatedUserSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridUsuarios } from './GridUsuario';

export const Usuario = () => {
  const [usuarioSearch, setUsuarioSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [defaultUsuario, setDefaultUsuario] = useState<UsuarioFormModel>(USUARIO_DEFAULT);
  const [userId, setUserId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [mostrarTodosUsuarios, setMostrarTodosUsuarios] = useState(false);

  const { obterGridUsuarios, remover, obter } = useUsuarioService();
  const {
    state: { userInfo },
  } = useAuthenticationContext();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const carregar = (
    query: OptGridRequest,
    UsuarioSearchRequest: PaginatedUserSearchRequest = {
      termoBuscado: usuarioSearch,
      todosUsuarios: mostrarTodosUsuarios,
    },
  ) => {
    const request: SearchRequest<PaginatedUserSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: UsuarioSearchRequest,
    };
    return obterGridUsuarios(request);
  };

  async function removerUsuario() {
    try {
      await remover(userId);
      ref.current?.refresh();
      setOpenModal(false);
    } catch {
      console.log('error');
    }
  }

  function OpenModalRemover(usuarioId: string) {
    setOpenModal(true);
    setUserId(usuarioId);
  }

  function criarUsuario() {
    if (userInfo.data) {
      if (userInfo.data.grupoUsuario.nome !== SectionsClaims.ADMIN_MASTER) {
        setDefaultUsuario({ ...USUARIO_DEFAULT, escritorioId: userInfo.data.escritorio.id });
      } else {
        setDefaultUsuario(USUARIO_DEFAULT);
      }
      toggleDrawer();
    }
  }

  async function editarUsuario(usuarioId: string) {
    try {
      const usuarioData = await obter(usuarioId);
      if (usuarioData.data.dadosCliente && usuarioData.data.dadosCliente.dataNascimento) {
        usuarioData.data.dadosCliente.dataNascimento =
          usuarioData.data.dadosCliente.dataNascimento &&
          new Date(usuarioData.data.dadosCliente.dataNascimento.toString());
      }
      setDefaultUsuario({
        nome: usuarioData.data.nome,
        email: usuarioData.data.email,
        id: usuarioData.data.id,
        grupoUsuarioId: usuarioData.data.grupoUsuario.id,
        escritorioId: usuarioData.data.escritorio,
        cpf: usuarioData.data.cpf,
        dadosAdvogado: usuarioData.data.dadosAdvogado ? usuarioData.data.dadosAdvogado : USER_ADVOGADO_DEFAULT,
        dadosCliente: usuarioData.data.dadosCliente,
      });

      toggleDrawer();
    } catch (err) {
      console.log(err);
    }
  }

  function ProcurarUsuario(data?: string) {
    setUsuarioSearch(data ?? '');
  }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [usuarioSearch, mostrarTodosUsuarios]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Gestão de Usuários" />
      <OptLayoutProvider theme={Theme().insideTheme}>
        <GeralWrapper>
          <HeaderContent titulo="Usuários" height={'100%'}>
            <ButtonWrapper pb="1.4rem">
              <IconButton onClick={criarUsuario} color />
            </ButtonWrapper>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
              <FormControlLabel
                value={mostrarTodosUsuarios}
                control={<Switch color="primary" />}
                onChange={() => {
                  setMostrarTodosUsuarios(!mostrarTodosUsuarios);
                }}
                sx={{ paddingBottom: 1 }}
                label="Mostrar inativos"
                labelPlacement="start"
              />
              <OptSearchField placeholder="Buscar por usuários" onSearch={ProcurarUsuario} />
            </Box>
          </HeaderContent>
          <GridUsuarios
            carregar={carregar}
            ref={ref}
            removerUsuarios={OpenModalRemover}
            editarUsuarios={editarUsuario}
          />
        </GeralWrapper>
        <ConfirmModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onConfirm={removerUsuario}
        />
        <FormDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} title={'Cadastro de novo usuário:'}>
          <UsuarioForm toggleDrawer={toggleDrawer} refreshGrid={refreshGrid} defaultUsuario={defaultUsuario} />
        </FormDrawer>
      </OptLayoutProvider>
    </OptSideLayoutContent>
  );
};
