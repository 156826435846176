import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, MenuItem, TextField, debounce } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldErrors, get, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import { ErrorMessage, FormSelect, FormTextField } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { FormAutoComplete } from '../../components/Form/FormAutocomplete';
import { AgendamentoFormModel, AgendamentoFormModelSchema } from '../../models/Agendamento/Agendamento.model';
import { ClientesSearchResponse } from '../../models/dtos/ClientesSearchResponse.model';
import { ProcessoSearchResponse } from '../../models/dtos/ProcessoSearchResponse.model';
import useAgendamentoService from '../../services/agendamento.service';
import useClienteService from '../../services/cliente.service';
import useProcessoService from '../../services/processo.service';
import { tolocalISOString } from '../../shared/functions';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
interface Props {
  defaultAgendamento: AgendamentoFormModel;
  toggleDrawer: () => void;
  refreshGrid?(): void;
}

export function AgendamentoForm({ defaultAgendamento, toggleDrawer, refreshGrid }: Props) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const { enviar, alterar, remover } = useAgendamentoService();
  const [responseOptions, setResponseOptions] = useState<readonly ClientesSearchResponse[]>([]);
  const [processoLista, setProcessoLista] = useState<ProcessoSearchResponse[]>([]);
  const [day, setDay] = useState<string | Date>(new Date());
  const [searchValue, setSearchValue] = useState<string>();

  const { handleSubmit, control, getValues, formState } = useForm<AgendamentoFormModel>({
    defaultValues: defaultAgendamento,
    resolver: yupResolver(AgendamentoFormModelSchema),
  });

  const idCompromisso = getValues('id');

  const clienteId = getValues('clienteId');
  const { obterGridClientes } = useClienteService();
  const { obterGridProcessosCliente, obterGridProcessos } = useProcessoService();
  const changeHandler = useCallback((value: any) => {
    setSearchValue(value);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const buttonBgColor = Theme().insideTheme.light?.primary;

  const buscarProcessoLista = async (id: string) => {
    console.log(id);
    const data = await obterGridProcessosCliente(id);
    setProcessoLista(data.data);
  };

  const onSubmit = async (data: AgendamentoFormModel) => {
    let submitData = data;
    if (data.clienteId === null) {
      const dataCopy: any = data;
      const inicioFormatado = tolocalISOString(dataCopy.inicio);
      const terminoFormatado = tolocalISOString(dataCopy.termino);
      submitData.inicio = new Date(inicioFormatado);
      submitData.termino = new Date(terminoFormatado);
    } else if (typeof data.clienteId === 'object') {
      const dataCopy: any = data;
      submitData.clienteId = dataCopy.clienteId.id;
      const inicioFormatado = tolocalISOString(dataCopy.inicio);
      const terminoFormatado = tolocalISOString(dataCopy.termino);
      submitData.inicio = new Date(inicioFormatado);
      submitData.termino = new Date(terminoFormatado);
    }

    try {
      setBotaoDesabilitado(true);
      await enviar(submitData);
      toggleDrawer();
      refreshGrid && refreshGrid();
    } catch {
      console.log('error');
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const onEdit = async (data: AgendamentoFormModel) => {
    let submitData = data;
    if (data.clienteId === null) {
      const dataCopy: any = data;
      const inicioFormatado = tolocalISOString(dataCopy.inicio);
      const terminoFormatado = tolocalISOString(dataCopy.termino);
      submitData.inicio = new Date(inicioFormatado);
      submitData.termino = new Date(terminoFormatado);
    } else if (typeof data.clienteId === 'object') {
      const dataCopy: any = data;
      submitData.clienteId = dataCopy.clienteId.id;
      const inicioFormatado = tolocalISOString(dataCopy.inicio);
      const terminoFormatado = tolocalISOString(dataCopy.termino);
      submitData.inicio = new Date(inicioFormatado);
      submitData.termino = new Date(terminoFormatado);
    }

    if (idCompromisso) {
      try {
        setBotaoDesabilitado(true);
        await alterar(idCompromisso, submitData);
        toggleDrawer();
        refreshGrid && refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };

  const onErrors = (data: FieldErrors<AgendamentoFormModel>) => {
    console.log('onErros', data);
  };

  async function removerAgendamento(id: string) {
    try {
      await remover(id);
      toggleDrawer();
    } catch {
      console.log('error');
    }
  }
  const carregar = async (ProcessoSearchRequest: PaginatedSearchRequest = { termoBuscado: '' }) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: 0,
      pageSize: 1000,
      search: ProcessoSearchRequest,
    };
    const data = await obterGridProcessos(request);
    setProcessoLista(data.data);
  };

  useEffect(() => {
    if (clienteId) {
      buscarProcessoLista(defaultAgendamento.clienteId.id);
    } else {
      carregar();
    }
  }, []);

  useEffect(() => {
    obterGridClientes({
      page: 0,
      pageSize: 50,
      search: { termoBuscado: searchValue ?? '' },
    })
      .then((res) => {
        setResponseOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setResponseOptions([]);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
        <Grid item xs={6}>
          <Controller
            name={'clienteId'}
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              // eslint-disable-next-line react/jsx-no-undef
              <FormAutoComplete
                noOptionsText="Nenhum resuldado encontrado"
                options={responseOptions}
                value={value || null}
                onBlur={onBlur}
                onChange={(event, value: any) => {
                  console.log(value);
                  if (value === null) {
                    return onChange(value);
                  } else {
                    buscarProcessoLista(value.id);
                    return onChange(value);
                  }
                }}
                onInputChange={(event, value) => {
                  debounceChangeHandler(value);
                }}
                getOptionLabel={(option: any) =>
                  option.nome ? option.nome : option.nomeEmpresa ? option.nomeEmpresa : ''
                }
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder="Buscar por cliente"
                    label="Buscar por cliente"
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <FormSelect
            control={control}
            name="processoId"
            label="Processo"
            placeholder="Processo"
            onOpen={() => {
              if (clienteId) {
                buscarProcessoLista(clienteId.id);
              } else {
                carregar();
              }
            }}>
            {processoLista!.length
              ? processoLista.map((props, i) => {
                  return (
                    <MenuItem value={props.id} key={i}>
                      {props.numero}
                    </MenuItem>
                  );
                })
              : null}
          </FormSelect>
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            control={control}
            name="tipo"
            label="Tipo de agenda"
            placeholder="Tipo de agenda"
            inputProps={{ maxLength: 35 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="inicio"
            render={({ field: { onChange, value } }) => (
              <DateTimePicker
                label="Início"
                disablePast
                slotProps={{
                  textField: {
                    sx: {
                      width: '100%',
                    },
                  },
                }}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <ErrorMessage error={get(formState.errors, 'inicio')} />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="termino"
            render={({ field: { onChange, value } }) => (
              <DateTimePicker
                label="Término"
                disablePast
                value={value}
                slotProps={{
                  textField: {
                    sx: {
                      width: '100%',
                    },
                  },
                }}
                onChange={onChange}
                onAccept={() => {
                  setDay(value!);
                }}
              />
            )}
          />
          <ErrorMessage error={get(formState.errors, 'termino')} />
        </Grid>
        {/* <Grid item xs={6}>
          <FormSelect control={control} name="horaTermino" label="Hora de término" placeholder="Hora de término">
            {horas.map((props, i) => {
              return (
                <MenuItem value={props} key={i}>
                  {props}
                </MenuItem>
              );
            })}
          </FormSelect>
        </Grid> */}
      </Grid>
      <SubmitWrapper>
        {idCompromisso && (
          <ButtonMui
            $backgroundColor={buttonBgColor}
            onClick={() => removerAgendamento(idCompromisso)}
            loadingIndicator={<CircularProgress color={'warning'} size={16} />}
            loading={botaoDesabilitado}>
            Remover compromisso
          </ButtonMui>
        )}
        <ButtonMui
          $backgroundColor={buttonBgColor}
          onClick={handleSubmit(idCompromisso ? onEdit : onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}
