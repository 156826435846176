import NumberFormat from 'react-number-format';

interface MascaraCnpj {
  cpf: string;
}

export function MascaraCPF(props: MascaraCnpj) {
  const stringSemCaracteres = props.cpf.replace(/[\s-.]/g, '');

  return (
    <>
      <NumberFormat
        value={stringSemCaracteres}
        displayType="text"
        format={stringSemCaracteres.length > 11 ? '##.###.###/####-##' : '###.###.###-##'}
        name="cnpj"
      />
    </>
  );
}
