import { Advogado, AdvogadoFormModel } from '../models/Advogado/Advogado.model';
import { AdvogadoSearchResponse } from '../models/dtos/AdvogadoSearchResponse.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useAdvogadoService() {
  const { get, post, put, remove, gridSearch } = useApi();
  const baseApi = 'api/advogado';

  const obter = async (id: string) => {

    return get<ApiResponse<Advogado>>(`${baseApi}/${id}`);
  };

  const obterGridAdvogados = async (data: SearchRequest<{}>) => {

    return gridSearch<AdvogadoSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterTodosAdvogados = async () => {

    return get<Advogado>(`${baseApi}`);
  };

  const enviar = async (data: AdvogadoFormModel) => {

    return post(`${baseApi}`, data);
  };

  const alterar = async (id: string, data: AdvogadoFormModel) => {

    return put<Advogado>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {

    return remove<Advogado>(`${baseApi}/${id}`);
  };

  return { obter, enviar, alterar, remover, obterTodosAdvogados, obterGridAdvogados };
}
