import { Box, Grid } from '@mui/material';
import { OptGridRef, OptLayoutProvider, OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import { GeralWrapper } from '../../../components/Layout/Layout';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { Documento } from '../../../models/Documento/Documento.model';
import { ProcessoSearchResponse } from '../../../models/dtos/ProcessoSearchResponse.model';
import { Routes } from '../../../routes';
import useDocumentoService from '../../../services/documento.service';
import useProcessoService from '../../../services/processo.service';
import { objectToFormData } from '../../../shared/functions';
import Theme from '../../../shared/theme';

import { GridDocumentosDetalhes } from './GridDocumentosDetalhes';
import { GridProcessosConsulta } from './GridProcessos';
import * as S from './styles';

export const DocumentosDetalhes = () => {
  // const [processoSearch, setProcessoSearch] = useState('');
  const [arquivosGrid, setArquivosGrid] = useState<Documento[]>();
  const [processosCliente, setProcessosCliente] = useState<ProcessoSearchResponse[]>();

  const { obterGridProcessosCliente } = useProcessoService();
  const { obterTodosDocumentos, remover, enviar, obter } = useDocumentoService();

  const { documentoId } = useParams<{ documentoId: string }>();

  async function obterDocumentos() {
    try {
      const documentosData = await obterTodosDocumentos(documentoId);
      setArquivosGrid(documentosData.data);
      refreshGrid();
    } catch (error) {
      console.log(error);
    }
  }

  async function obterProcessos() {
    try {
      const processos = await obterGridProcessosCliente(documentoId);
      setProcessosCliente(processos.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function baixarDocumento(idDocumento: string, documentoNome: string) {
    try {
      obter(documentoId, idDocumento, documentoNome);
    } catch (error) {
      console.log(error);
    }
  }

  async function removerDocumento(idDocumento: string) {
    try {
      await remover(documentoId, idDocumento);
      obterDocumentos();
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (files: File[]) => {
    const newFiles = files.map(async (data: File) => {
      const formData = objectToFormData({
        file: data,
      });
      return enviar(documentoId, formData);
    });
    Promise.all(newFiles).then(() => obterDocumentos());
  };

  const ref = useRef<OptGridRef>();

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }
  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [arquivosGrid]);

  useEffect(() => {
    obterDocumentos();
    obterProcessos();
  }, []);
  return (
    <OptSideLayoutContent>
      <Toolbar title="Gerenciador eletrônico de documentos" goBackRoute={Routes.Documentos.Principal} />
      <GeralWrapper>
        <Grid container item rowSpacing={3} columnSpacing={5} pt={2.5}>
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <OptLayoutProvider theme={Theme().insideTheme}>
                {/* <OptSearchField placeholder="Buscar por ..." onSearch={ProcurarCliente} /> */}
              </OptLayoutProvider>
            </Box>
            {processosCliente ? (
              <GridProcessosConsulta carregar={processosCliente} ref={ref} />
            ) : (
              <OptLoading size={50} color={'primary'} />
            )}
          </Grid>
          <Grid item xs={8}>
            <OptLayoutProvider theme={Theme().insideTheme}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  justifyContent: 'flex-end',
                  flexDirection: 'column',
                  width: '100%',
                }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <S.InputDiv>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      multiple={true}
                      onChange={(e) => {
                        if (e.target.files) {
                          console.log(e.target.files);
                          const fileArray = Array.from(e.target.files);
                          handleChange(fileArray);
                        }
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button span label="Adicionar documentos" variant="primary" />
                    </label>
                  </S.InputDiv>
                </Box>
                {arquivosGrid ? (
                  <GridDocumentosDetalhes
                    data={arquivosGrid}
                    removerDocumento={removerDocumento}
                    baixarDocumento={baixarDocumento}
                    ref={ref}
                  />
                ) : (
                  <OptLoading size={50} color={'primary'} />
                )}
              </Box>
            </OptLayoutProvider>
          </Grid>
        </Grid>
      </GeralWrapper>
    </OptSideLayoutContent>
  );
};
