import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  max-width: 750px;
  width: 48vw;
  flex-direction: column;
  gap: 15px;
`;

export const WrapperNotification = styled.div`
  padding-top: 10px;
  display: flex;
  max-width: 750px;
  width: 35vw;
  flex-direction: column;
  gap: 15px;
`;

export const Header = styled.div`
  display: flex;
  height: 15%;
  width: 100%;
  padding: 20px 20px 10px 20px;
  gap: 10px;
  align-items: center;
`;
export const HeaderNotificacao = styled.div`
  display: flex;
  height: 10%;
  width: 36%;
  background-color: #fff;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  gap: 10px;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9999;
`;

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px 15px 15px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
`;
