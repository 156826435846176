import { mdiApple } from '@mdi/js';
import { Box, Grid, Typography } from '@mui/material';
import { OptSideLayoutContent } from '@optsol/react';
import { IconButtonApp } from '../../components/Button/IconButtonApp';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useMsalService } from '../../services/auth/msal.service';
import mobileImage from '../../shared/Imagens/mobile_app.svg';

export default function MobileRedirect() {
  const msal = useMsalService();

  function redirectToAppShop() {
    window.location.href = 'https://play.google.com/store/apps/details?id=br.com.acessojuris';
  }

  function redirectToAppleStore() {
    window.location.href = 'https://apps.apple.com/br/app/acesso-juris/id6446224690';
  }

  return (
    <OptSideLayoutContent>
      <Toolbar title="" />
      <Grid container>
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={5}>
          <img src={mobileImage} alt={'pagina não encontrada'} height={400} width={700} />
          <Typography variant="h4">Para acessar esse conteúdo baixe nosso app!</Typography>
          <Box display="flex" gap={2}>
            <IconButtonApp
              titulo=" Já disponível"
              loja="Google Play"
              onClick={() => redirectToAppShop()}
              color="black"
              size={2}
            />
            <IconButtonApp
              loja="App Store"
              titulo="Já disponível"
              onClick={() => redirectToAppleStore()}
              icon={mdiApple}
              color="black"
              size={2}
            />
          </Box>
        </Grid>
      </Grid>
    </OptSideLayoutContent>
  );
}
