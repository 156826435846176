import { mdiDelete, mdiPencil } from '@mdi/js';
import { OptGrid, OptGridColumn, OptGridOptions, OptGridRef, OptGridRequest, OptGridResponse } from '@optsol/react';
import { forwardRef } from 'react';
import { MascaraCNPJ } from '../../components/MascaraCnpj/MascaraCnpj';
import MascaraTelefoneDuplo from '../../components/MascaraTelefone/MascaraTelefoneDuplo';
import { EscritorioSearchResponse } from '../../models/dtos/EscritorioSearchResponse.model';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    EscritorioSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<EscritorioSearchResponse>>;
  removerEscritorios(escritorioId: string): Promise<void>;
  editarEscritorios(escritorioId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridEscritorios = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, removerEscritorios, editarEscritorios }, ref) => {
    const columns: OptGridColumn<EscritorioSearchResponse>[] = [
      {
        title: 'id',
        field: 'id',
        hidden: true,
      },
      {
        title: 'Nome do Escritório',
        field: 'nome',
      },
      {
        title: 'CNPJ',
        field: 'cnpj',
        render: (data: EscritorioSearchResponse) => {
          return <MascaraCNPJ cnpj={data.cnpj} />;
        },
      },
      {
        title: 'Telefone',
        field: 'telefone',
        render: (data: EscritorioSearchResponse) => {
          return <MascaraTelefoneDuplo telefone={data.telefone.celular ? data.telefone.celular : data.telefone.fixo} />;
        },
      },
    ];

    return (
      <>
        <OptGrid
          title=""
          ref={ref as any}
          data={carregar}
          options={options}
          columns={columns}
          headerTitlePosition="start"
          actionsPosition={'end'}
          actions={[
            (rowData) => ({
              icon: { path: mdiPencil },
              tooltip: 'Editar',
              onClick: () => editarEscritorios(rowData.id),
              disabled: false,
            }),
            (rowData) => ({
              icon: { path: mdiDelete },
              tooltip: 'Deletar',
              onClick: () => removerEscritorios(rowData.id),
              disabled: false,
            }),
          ]}
        />
      </>
    );
  },
);
