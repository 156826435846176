import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import {
  OptGridRef,
  OptGridRequest,
  OptLayoutProvider,
  OptLoading,
  OptSearchField,
  OptSideLayoutContent,
} from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonWrapper } from '../../components/Button/Button.styles';
import { IconButton } from '../../components/Button/IconButton';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { FinanceiroForm } from '../../features/FinanceiroForm/FinanceiroForm';
import { FinanceiroPagamentoForm } from '../../features/FinanceiroForm/FinanceiroPagamentoForm';
import {
  FinanceiroResumoSearchResponse,
  FinanceiroSearchResponse,
} from '../../models/dtos/FinanceiroSearchResponse.model';
import { FINANCEIRO_DEFAULT } from '../../models/Financeiro/Financeiro.model';
import {
  FinanceiroPagamentoFormModel,
  FinanceiroPagamentoFormModelSchema,
} from '../../models/Financeiro/FinanceiroPagamento.model';
import useFinanceiroService from '../../services/financeiro.service';
import { TransformarModeloFinanceiro } from '../../shared/functions';
import Theme from '../../shared/theme';
import { ApiResponse } from '../../shared/types/ApiResponse';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridFinanceiro } from './GridFinanceiro';
import { GridResumoFinanceiro } from './GridResumoFinanceiro';

export const Financeiro = () => {
  const [clientSearch, setClientSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPagamento, setIsOpenPagamento] = useState(false);
  const [resumo, setResumo] = useState<FinanceiroResumoSearchResponse[]>();

  const [defaultFinanceiro, setDefaultFinanceiro] = useState(FINANCEIRO_DEFAULT);
  const [defaultFinanceiroPagamento, setDefautFinanceiroPagamento] = useState<FinanceiroPagamentoFormModel>();

  const {} = useForm<FinanceiroPagamentoFormModel>({
    defaultValues: {
      id: defaultFinanceiroPagamento?.id!,
      valorOriginal: defaultFinanceiroPagamento?.valorOriginal!,
      valorPago: defaultFinanceiroPagamento?.valorPago,
    },
    resolver: yupResolver(FinanceiroPagamentoFormModelSchema),
  });

  const { obterGridFinanceiros, remover, obterGridResumo } = useFinanceiroService();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const toggleDrawerPagamento = () => {
    setIsOpenPagamento((prevState) => !prevState);
  };
  const closeDrawerPagamento = () => {
    obterResumoFinanceiro();

    setIsOpenPagamento((prevState) => !prevState);
  };

  const carregar = async (
    query: OptGridRequest,
    ClientSearchRequest: PaginatedSearchRequest = { termoBuscado: clientSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: ClientSearchRequest,
    };
    const data = await obterGridFinanceiros(request);

    return TransformarModeloFinanceiro(data as unknown as ApiResponse<FinanceiroSearchResponse>);
  };

  async function obterResumoFinanceiro() {
    try {
      const data = await obterGridResumo();
      if (data) setResumo([data.data]);
    } finally {
      refreshGridResumo();
    }
  }
  const closeDrawer = () => {
    obterResumoFinanceiro();
    setIsOpen((prevState) => !prevState);
  };

  async function removerPagamento(clientId: string) {
    try {
      await remover(clientId);
      ref.current?.refresh();
    } finally {
      obterResumoFinanceiro();
    }
  }
  function criarFinanceiro() {
    setDefaultFinanceiro(FINANCEIRO_DEFAULT);
    toggleDrawer();
  }

  function confirmarPagamento(id: string, valor: string) {
    setDefautFinanceiroPagamento({
      id: id,
      valorOriginal: valor,
      valorPago: '',
    });
  }

  function ProcurarCliente(data?: string) {
    setClientSearch(data ?? '');
  }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }
  function refreshGridResumo() {
    if (refResumo.current) {
      refResumo.current.refresh();
    }
  }

  const ref = useRef<OptGridRef>();
  const refResumo = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [clientSearch]);

  useEffect(() => {
    if (defaultFinanceiroPagamento) toggleDrawerPagamento();
  }, [defaultFinanceiroPagamento]);

  useEffect(() => {
    obterResumoFinanceiro();
  }, []);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Financeiro" />
      <OptSideLayoutContent>
        <GeralWrapper>
          <HeaderContent titulo="Financeiro" height="100%">
            <ButtonWrapper pb="1.4rem">
              <IconButton color onClick={toggleDrawer} />
            </ButtonWrapper>
            <FormDrawer title={'Criar novo lançamento:'} isOpen={isOpen} toggleDrawer={criarFinanceiro}>
              <FinanceiroForm
                toggleDrawer={closeDrawer}
                refreshGrid={refreshGrid}
                defaultFinanceiro={defaultFinanceiro}
              />
            </FormDrawer>
            <OptLayoutProvider theme={Theme().insideTheme}>
              <FinanceiroPagamentoForm
                defaultFinanceiro={defaultFinanceiroPagamento}
                isOpen={isOpenPagamento}
                toggleDrawer={closeDrawerPagamento}
                refreshGrid={refreshGrid}
              />
              <OptSearchField placeholder="Buscar" onSearch={ProcurarCliente} />
            </OptLayoutProvider>
          </HeaderContent>
          <GridFinanceiro
            carregar={carregar}
            ref={ref}
            removerPagamento={removerPagamento}
            confirmarPagamento={confirmarPagamento}
          />
          <Box sx={{ margin: '20px 0' }}>
            <h3>Resumo</h3>
          </Box>
          {resumo ? (
            <GridResumoFinanceiro ref={refResumo} carregar={resumo} removerCliente={removerPagamento} />
          ) : (
            <OptLoading />
          )}
        </GeralWrapper>
      </OptSideLayoutContent>
    </OptSideLayoutContent>
  );
};

