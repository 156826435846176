import { Box } from '@mui/material';
import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { AdvogadoForm } from '../../features/AdvogadoForm/AdvogadoForm';
import { Advogado } from '../../models/Advogado/Advogado.model';
import useAdvogadoService from '../../services/advogado.service';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridAdvogados } from './GridAdvogado';

export const Advogados = () => {
  const [advogadoSearch, setAdvogadoSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [defaultAdvogado, setDefaultAdvogado] = useState<Advogado>({
    cpf: '',
    email: '',
    id: '',
    nome: '',
    oab: '',
    telefone: '',
    ufOab: '',
  });

  const { obterGridAdvogados, remover, obter } = useAdvogadoService();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const carregar = (
    query: OptGridRequest,
    AdvogadoSearchRequest: PaginatedSearchRequest = { termoBuscado: advogadoSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: AdvogadoSearchRequest,
    };
    return obterGridAdvogados(request);
  };

  async function removerAdvogado(advogadoId: string) {
    try {
      await remover(advogadoId);
      ref.current?.refresh();
    } catch {
      console.log('error');
    }
  }
  function criarAdvogado() {
    setDefaultAdvogado({
      cpf: '',
      email: '',
      id: '',
      nome: '',
      oab: '',
      telefone: '',
      ufOab: '',
    });
    toggleDrawer();
  }

  async function editarAdvogado(advogadoId: string) {
    try {
      const advogadoData = await obter(advogadoId);
      setDefaultAdvogado(advogadoData.data);
      toggleDrawer();
    } catch {}
  }

  function ProcurarAdvogado(data?: string) {
    setAdvogadoSearch(data ?? '');
  }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [advogadoSearch]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Gestão de advogados" />
      <OptLayoutProvider theme={Theme().insideTheme}>
        <GeralWrapper>
          <HeaderContent titulo="Advogados" height="100%">
            <Box></Box>
            <FormDrawer title={'Cadastro de novo advogado:'} isOpen={isOpen} toggleDrawer={criarAdvogado}>
              <AdvogadoForm toggleDrawer={toggleDrawer} refreshGrid={refreshGrid} defaultAdvogado={defaultAdvogado} />
            </FormDrawer>
            <OptSearchField placeholder="Buscar por advogados" onSearch={ProcurarAdvogado} />
          </HeaderContent>
          <GridAdvogados
            carregar={carregar}
            ref={ref}
            removerAdvogado={removerAdvogado}
            editarAdvogado={editarAdvogado}
          />
        </GeralWrapper>
      </OptLayoutProvider>
    </OptSideLayoutContent>
  );
};
