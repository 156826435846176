import { OptGrid, OptGridColumn, OptGridOptions, OptGridRef, OptLayoutProvider } from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FinanceiroResumoSearchResponse } from '../../models/dtos/FinanceiroSearchResponse.model';
import toBRCurrency, { insertDecimal } from '../../shared/functions';
import Theme from '../../shared/theme';

interface GridProps {
  carregar: FinanceiroResumoSearchResponse[];
  removerCliente: (clientId: string) => void;
  //   editarCliente(clientId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridResumoFinanceiro = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, removerCliente }, ref) => {
    const columns: OptGridColumn<FinanceiroResumoSearchResponse>[] = [
      {
        title: 'Previsto  ',
        field: 'previsto',
        align: 'center',
        render: (data) => {
          return <>{toBRCurrency(Number(data.previsto))}</>;
        },
      },
      {
        title: 'Efetivo',
        field: 'efetivo',
        align: 'center',
        render: (data) => {
          return <>{toBRCurrency(Number(data.efetivo))}</>;
        },
      },
      {
        title: ' Diferença',
        field: 'diferenca',
        align: 'center',
        render: (data) => {
          return <>{toBRCurrency(Number(data.diferenca))}</>;
        },
      },
    ];

    return (
      <OptLayoutProvider theme={Theme().insideTheme}>
        <OptGrid
          title=""
          ref={ref as any}
          data={carregar}
          options={options}
          headerTitlePosition={'center'}
          columns={columns}
          actionsPosition={'end'}
        />
      </OptLayoutProvider>
    );
  },
);
