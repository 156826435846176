import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldErrors, get, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import { ErrorMessage, FormNumberFormat, FormSelect, FormTextField } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { Cliente, ClienteFormModel, ClienteFormModelSchema } from '../../models/Cliente/Cliente.model';
import { ENDERECO_DEFAULT } from '../../models/Endereco.model';
import useClienteService from '../../services/cliente.service';
import Theme from '../../shared/theme';
import { useCPNJ } from '../../shared/utils/functions';

interface ClienteFormProps {
  toggleDrawer: () => void;
  defaultClient: Partial<Cliente>;
  refreshGrid(): void;
}

export function ClientForm({ defaultClient, toggleDrawer, refreshGrid }: ClienteFormProps) {
  const [tabValue, setTabValue] = useState(0);
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const [tabDisabled, setTabDisabled] = useState({
    tab1: false,
    tab2: false,
  });

  const { enviar, alterar } = useClienteService();
  const { setValue, handleSubmit, control, getValues, formState, watch, reset } = useForm<ClienteFormModel>({
    defaultValues: defaultClient,
    resolver: yupResolver(ClienteFormModelSchema),
  });
  const { buscarCNPJ } = useCPNJ();

  const idClient = getValues('id');
  const watchTipoPessoa = watch('tipoPessoa');

  const clienteCNPJ = getValues('cnpj');
  const clienteCPF = getValues('cpf');

  const onSubmit = async (data: ClienteFormModel) => {
    try {
      setBotaoDesabilitado(true);
      await enviar(data);
      toggleDrawer();
      refreshGrid();
    } catch (error) {
      console.log(error);
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const onEdit = async (data: ClienteFormModel) => {
    if (idClient) {
      try {
        setBotaoDesabilitado(true);
        await alterar(idClient, data);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };

  useEffect(() => {
    if (watchTipoPessoa === 2) {
      setTabValue(1);
    }
  }, [toggleDrawer, watchTipoPessoa]);

  useEffect(() => {
    if (clienteCNPJ) {
      setTabDisabled({ ...tabDisabled, tab1: true });
    } else if (clienteCPF) {
      setTabDisabled({ ...tabDisabled, tab2: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleDrawer]);

  useEffect(() => {
    if (tabValue === 0) {
      setValue('tipoPessoa', 1);
    } else {
      setValue('tipoPessoa', 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  const onErrors = (data: FieldErrors<ClienteFormModel>) => {
    console.log('onErros', data);
  };

  const checkCEP = (e: any) => {
    const cep = e.target.value.replace(/\D/g, '');
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue('endereco', {
          cep: data.cep,
          bairro: data.bairro,
          rua: data.logradouro,
          cidade: data.localidade,
          estado: data.uf,
          complemento: data.complemento,
        });
      });
  };
  const checkCNPJ = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const cnpj = e.target.value;
      buscarCNPJ(cnpj)
        .then((res) => {
          if (res) {
            setValue('endereco', res.endereco);
            setValue('nomeFantasia', res.nomeEmpresa);
            setValue('nomeEmpresa', res.razaoSocial);
            setValue('email', res.email);
            if (res.telefone.length === 10) setValue('telefone.fixo', res.telefone);
            if (res.telefone.length > 10) setValue('telefone.celular', res.telefone);
          }
        })
        .catch(() => {
          setValue('endereco', { ...ENDERECO_DEFAULT });
        });
    },
    [buscarCNPJ, setValue],
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    reset();
  };
  return (
    <>
      <Tabs
        sx={{ width: '100%', padding: '0 20px' }}
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example">
        <Tab disabled={tabDisabled.tab1} sx={{ width: '50%' }} label="Pessoa Física (CPF)" />
        <Tab disabled={tabDisabled.tab2} sx={{ width: '50%' }} label="Pessoa Jurídica (CNPJ)" />
      </Tabs>
      {tabValue === 0 ? (
        <>
          <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
            <Grid item xs={6}>
              <FormTextField control={control} name="nome" label="Nome" placeholder="Nome" />
            </Grid>
            <Grid item xs={6}>
              <FormNumberFormat
                control={control}
                type="text"
                format="###.###.###-##"
                disabled={idClient ? true : false}
                name="cpf"
                label="CPF"
                placeholder="xxx.xxx.xxx-xx"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="dataNascimento"
                render={({ field: { onChange, value } }) => (
                  <>
                    <DatePicker
                      label="Data de Nascimento"
                      value={value}
                      onChange={onChange}
                      slotProps={{
                        textField: {
                          sx: {
                            width: '100%',
                          },
                        },
                      }}
                    />
                  </>
                )}
              />
              <ErrorMessage error={get(formState.errors, 'dataNascimento')} />
            </Grid>
            <Grid item xs={6}>
              <FormSelect control={control} name="estadoCivil" label="Estado civil" placeholder="Estado civil">
                <MenuItem value={1}>Solteiro</MenuItem>
                <MenuItem value={2}>Casado</MenuItem>
                <MenuItem value={3}>Separado</MenuItem>
                <MenuItem value={4}>Viúvo</MenuItem>
                <MenuItem value={5}>União estável</MenuItem>
              </FormSelect>
            </Grid>
            <Grid item xs={6}>
              <FormNumberFormat
                control={control}
                format="(##) #####-####"
                name="telefone.celular"
                label="Celular"
                placeholder="(00) 0 0000-0000"
              />
            </Grid>
            <Grid item xs={6}>
              <FormNumberFormat
                control={control}
                format="(##) ####-####"
                name="telefone.fixo"
                label="Telefone Fixo"
                placeholder="(00) 0000-0000"
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                disabled={idClient ? true : false}
                name="email"
                label="E-mail"
                placeholder="email@mail.com"
              />
            </Grid>
            <Grid item xs={6}>
              <FormNumberFormat
                control={control}
                format="#####-###"
                name="endereco.cep"
                label="CEP"
                placeholder="CEP"
                onBlur={checkCEP}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField control={control} name="endereco.estado" label="Estado" placeholder="Estado" />
            </Grid>
            <Grid item xs={6}>
              <FormTextField control={control} name="endereco.cidade" label="Cidade" placeholder="Cidade" />
            </Grid>
            <Grid item xs={6}>
              <FormTextField control={control} name="endereco.bairro" label="Bairro" placeholder="Bairro" />
            </Grid>
            <Grid item xs={6}>
              <FormTextField control={control} name="endereco.rua" label="Rua" />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                control={control}
                name="endereco.complemento"
                label="Complemento"
                placeholder="Complemento"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
          <Grid item xs={6}>
            <FormNumberFormat
              name="cnpj"
              control={control}
              type="text"
              disabled={idClient ? true : false}
              format="##.###.###/####-##"
              fullWidth
              customInput={TextField}
              label="CNPJ"
              onBlur={checkCNPJ}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="nomeEmpresa"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    onChange={onChange}
                    value={value ?? ''}
                    label="Nome da empresa"
                    variant="outlined"
                    fullWidth
                  />
                </>
              )}
            />
            <ErrorMessage error={get(formState.errors, 'nomeEmpresa')} />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="nomeFantasia" label="Nome fantasia" placeholder="Nome fantasia" />
          </Grid>

          <Grid item xs={6}>
            <FormNumberFormat
              control={control}
              format="(##) # ####-####"
              name="telefone.celular"
              label="Celular"
              placeholder="(00) 0 0000-0000"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="email" label="E-mail" placeholder="email@mail.com" />
          </Grid>
          <Grid item xs={6}>
            <FormNumberFormat
              control={control}
              format="(##) ####-####"
              name="telefone.fixo"
              label="Telefone fixo"
              placeholder="(00)  0000-0000"
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumberFormat
              control={control}
              format="#####-###"
              name="endereco.cep"
              label="CEP"
              placeholder="CEP"
              onBlur={checkCEP}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="endereco.estado" label="Estado" placeholder="Estado" />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="endereco.cidade" label="Cidade" placeholder="Cidade" />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="endereco.bairro" label="Bairro" placeholder="Bairro" />
          </Grid>
          <Grid item xs={6}>
            <FormTextField control={control} name="endereco.rua" label="Rua" />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="endereco.complemento"
              label="Complemento"
              placeholder="Complemento"
            />
          </Grid>
        </Grid>
      )}
      <SubmitWrapper>
        <ButtonMui
          $backgroundColor={Theme().insideTheme.light?.primary}
          onClick={handleSubmit(idClient ? onEdit : onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}
