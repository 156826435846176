import { Advogado, AdvogadoFormModel } from '../models/Advogado/Advogado.model';
import { AssinaturaFormModel } from '../models/Assinatura/Assinatura.model';
import { documentoId } from '../models/dtos/AndamentoSearchResponse';
import { AssinaturaSearchResponse } from '../models/dtos/AssinaturaSearchResponse';
import { objectToFormData } from '../shared/functions';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useAssinaturaSerice() {
  const { get, post, put, remove, gridSearch } = useApi();
  const baseApi = 'api/assinatura';

  const obter = async (id: string) => {
    return get<ApiResponse<AssinaturaSearchResponse>>(`${baseApi}/${id}`);
  };

  const obterGridAssinaturas = async (data: SearchRequest<{}>) => {
    return gridSearch<AssinaturaSearchResponse>(`${baseApi}/buscar-documentos`, data);
  };

  const enviar = async (data: AssinaturaFormModel) => {
    const formData = objectToFormData(data);
    return post(`${baseApi}/salvar`, formData);
  };

  const enviarAssinado = async (data: AssinaturaFormModel) => {
    const formData = objectToFormData(data);
    return post(`${baseApi}/salvar-assinar`, formData);
  };

  const buscarDocumento = async (id: documentoId) => {
    const formData = objectToFormData(id);
    return post<Blob>(`${baseApi}/buscar-documento`, formData, {
      responseType: 'blob',
    });
  };

  const assinarEnviar = async (documentoId: any) => {
    return put(`${baseApi}/assinar-signatario`, documentoId);
  };

  const alterar = async (id: string, data: AdvogadoFormModel) => {
    return put<Advogado>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<Advogado>(`${baseApi}/${id}`);
  };

  return { obter, enviar, alterar, remover, obterGridAssinaturas, enviarAssinado, buscarDocumento, assinarEnviar };
}
