import axios from 'axios';
import { BaseConfig } from './baseConfig';

const baseUrl = BaseConfig.baseApiUrl;

export default function useAxios(url = baseUrl) {
  const Http = axios.create({
    baseURL: url,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });
  return Http;
}
