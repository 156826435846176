import { useEffect, useState } from 'react';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import useEscritorioService from '../../services/escritorio.service';
import logoPadrao from '../../shared/Imagens/PngLogo.png';

export default function LogoEscritorio() {
  const [logo, setLogo] = useState<string>();

  const { obterArquivo } = useEscritorioService();

  const {
    state: { userInfo },
  } = useAuthenticationContext();

  async function HandleLogo() {
    if (userInfo.data)
      if (userInfo.data.escritorio && userInfo.data.escritorio.imagem) {
        try {
          const logoAPI = await obterArquivo(userInfo.data.escritorio.id);
          const myImage = URL.createObjectURL(logoAPI);
          setLogo(myImage);
        } catch {
          setLogo(logoPadrao);
        }
      } else setLogo(logoPadrao);
  }

  useEffect(() => {
    HandleLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.data]);

  return (
    <>
      {logo && (
        <div style={{ width: 'auto', position: 'fixed', top: 15 }}>
          <img style={{ borderRadius: '15%' }} src={logo} alt="logo" width="40" height="40" />
        </div>
      )}
    </>
  );
}
