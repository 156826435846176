import * as Yup from 'yup';

export interface PerguntasFrequentes {
  id: string;
  pergunta: string | null;
  resposta?: string;
  escritorioId?: string;
}

export type PerguntasFrequentesFormModel = Partial<PerguntasFrequentes>;

export const PERGUNTA_FREQUENTE_DEFAULT: PerguntasFrequentesFormModel = {
  id: '',
  pergunta: '',
  resposta: '',
  escritorioId: '',
};

export const PerguntasFrequentesFormModelSchema: Yup.SchemaOf<PerguntasFrequentesFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  escritorioId: Yup.string().notRequired(),
  pergunta: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  resposta: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
});
