import * as Yup from 'yup';
import { validarCNPJ, validarCPF } from '../../shared/utils/functions';
import { Endereco, EnderecoFormModelSchema } from '../Endereco.model';

export interface Telefone {
  fixo?: string | null;
  celular?: string | null;
}

export interface UserCliente {
  cnpj: string | null;
  cpf: string | null;
  dataNascimento: Date | null;
  estadoCivil?: number | null;
  id: string;
  nomeEmpresa: string | null;
  nomeFantasia: string | null;
  telefone: Telefone;
  tipoPessoa: number;
  endereco: Endereco;
}
export type UserClienteFormModel = Partial<UserCliente>;

export const USER_CLIENTE_DEFAULT: UserClienteFormModel = {
  cpf: '',
  cnpj: '',
  nomeEmpresa: '',
  nomeFantasia: '',
  estadoCivil: null,
  telefone: {
    celular: '',
    fixo: '',
  },
  dataNascimento: null,
  id: '',
  tipoPessoa: 1,
};

export const TelefoneSchema: Yup.SchemaOf<Telefone> = Yup.object().shape({
  fixo: Yup.string()
    .notRequired()
    .when('cnpj', {
      is: (value: string) => {
        return !!value;
      },
      then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
      otherwise: (schema) => schema.notRequired().nullable().typeError('Campo obrigatório'),
    }),
  celular: Yup.string().when('cpf', {
    is: (value: string) => {
      return !!value;
    },
    then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
    otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
  }),
});

export const UserClienteFormModelSchema: Yup.SchemaOf<UserClienteFormModel> = Yup.object().shape({
  nomeEmpresa: Yup.string().when('cnpj', {
    is: (value: string) => {
      return !!value;
    },
    then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),

  nomeFantasia: Yup.string().notRequired().nullable(),
  cnpj: Yup.string()
    .typeError('Campo obrigatório')
    .when('cpf', {
      is: (value: string) => {
        return value;
      },
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) =>
        schema
          .required('Campo obrigatório')
          .typeError('Campo obrigatório')
          .test('CNPJ Test', 'CNPJ Inválido', (value) => !!value && validarCNPJ(value)),
    }),

  // .test('required', 'Campo obrigatório', (value, ctx) => !ctx.parent.cpf),
  cpf: Yup.string()
    .typeError('Campo obrigatório')
    .test('required', 'Campo obrigatório', (value, ctx) => ctx.parent.cnpj || value)
    .test('CPF Test', 'CPF Inválido', (value) => !value || (!!value && validarCPF(value))),

  estadoCivil: Yup.mixed().when('cpf', {
    is: (value: string) => {
      return !!value;
    },
    then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
    otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
  }),
  dataNascimento: Yup.mixed().when('cpf', {
    is: (value: string) => {
      return !!value;
    },
    then: (schema) => schema.required('Campo obrigatório').typeError('Campo obrigatório'),
    otherwise: (schema) => schema.notRequired().typeError('Campo obrigatório'),
  }),
  telefone: TelefoneSchema.required().typeError('Campo obrigatório'),
  id: Yup.string().notRequired(),
  endereco: EnderecoFormModelSchema.required().typeError('Campo obrigatório'),
  tipoPessoa: Yup.number().notRequired(),
});
