import { Box, Checkbox, CircularProgress, debounce, FormControlLabel, TextField } from '@mui/material';
import { OptGridRef, OptUpload } from '@optsol/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import { FormAutoComplete } from '../../components/Form/FormAutocomplete';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { ASSINATURA_DEFAULT, CadastroAssinaturaModel } from '../../models/Assinatura/Assinatura.model';
import { UsuarioSearchResponse } from '../../models/dtos/UsuarioSearchResponse.model';
import useAssinaturaSerice from '../../services/assinatura.service';
import useUsuarioService from '../../services/usuario.service';
import Theme from '../../shared/theme';
import { AssinaturaFormGrid } from './AssinaturaFormGrid';

export interface AssinaturaFormDrawer {
  toggleDrawer: () => void;
  refreshGrid(): void;
}

export default function AssinaturaForm({ toggleDrawer, refreshGrid }: AssinaturaFormDrawer) {
  const [disableButtom, setDisableButtom] = useState(true);
  const [loadingButtom, setLoadingButtom] = useState(false);
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [responseOptions, setResponseOptions] = useState<readonly UsuarioSearchResponse[]>([]);
  const [clientes, setClientes] = useState<UsuarioSearchResponse[]>([]);
  const [userIdArray, setUserIdArray] = useState<string[]>([]);
  const [file, setFile] = useState<File>();
  const {
    state: { userInfo },
  } = useAuthenticationContext();

  const changeHandler = useCallback((value: any) => {
    setSearchValue(value);
  }, []);
  const themeBgButtom = Theme().insideTheme.light?.primary;

  const debounceChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const { obterTodosUsuarios } = useUsuarioService();
  const { enviar, enviarAssinado } = useAssinaturaSerice();

  const { control, watch } = useForm<CadastroAssinaturaModel>({
    defaultValues: ASSINATURA_DEFAULT,
  });
  const userAssina = watch('usuarioLogadoSignatario');

  const enviarAssinatura = async () => {
    try {
      if (userInfo.data && userInfo.data.id && userAssina === true) {
        const assinar: any = await enviarAssinado({
          signatariosId: userIdArray,
          usuarioLogadoSignatario: userAssina,
          documento: file,
        });
        window.open(assinar.data.urlAssinatura);
      } else {
        await enviar({
          signatariosId: userIdArray,
          usuarioLogadoSignatario: userAssina,
          documento: file,
        });
      }
    } finally {
      toggleDrawer();
      refreshGrid();
    }
  };
  useEffect(() => {
    // obterGridUsuarios({
    //   page: 0,
    //   pageSize: 50,
    //   search: { termoBuscado: searchValue ?? '' },
    // })
    obterTodosUsuarios()
      .then((res) => {
        const newGrid = res.data.filter((props) => props.id !== userInfo.data?.id);
        setResponseOptions(newGrid);
      })
      .catch((err) => {
        console.log(err);
        setResponseOptions([]);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  function removerClienteGrid(id: string) {
    const newGrid = clientes.filter((props) => props.id !== id);
    setClientes(newGrid);
  }

  function handleChange(files: File[]) {
    const [file] = files;
    setFile(file);
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [clientes]);

  return (
    <Box sx={{ width: '100%', heigth: '100%', padding: '30px' }}>
      <Box sx={{ display: 'flex', gap: '15px', flexDirection: 'Column' }}>
        <Controller
          name={'signatariosId'}
          control={control}
          render={({ field: { onChange, value, onBlur } }) => (
            <FormAutoComplete
              options={responseOptions}
              value={value || null}
              noOptionsText="Nenhum resuldado encontrado"
              onBlur={onBlur}
              onChange={(event, value: any) => {
                onChange((prev: any) => [...prev, value]);
                if (value) {
                  const isFound = clientes.some((element) => {
                    if (element.id === value.id) {
                      return true;
                    }
                    return false;
                  });
                  if (!isFound) {
                    setClientes([...clientes, value]);
                    setUserIdArray([...userIdArray, value.id]);
                  }
                }
              }}
              onInputChange={(event, value) => {
                debounceChangeHandler(value);
              }}
              getOptionLabel={(option: any) => (option.nome ? option.nome : '')}
              isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  placeholder="Adicionar signatários"
                  label="Adicionar signatários"
                />
              )}
            />
          )}
        />
        {clientes.length > 0 ? (
          <>
            <AssinaturaFormGrid removerCliente={removerClienteGrid} carregar={clientes} ref={ref} />
            <Controller
              name="usuarioLogadoSignatario"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    value={true}
                    control={<Checkbox value={value} onChange={onChange} />}
                    label="Devo assinar também?"
                    labelPlacement="end"
                  />
                </>
              )}
            />
          </>
        ) : null}
        {clientes.length > 0 ? (
          <OptUpload
            style={{ height: '300px' }}
            description={'Arraste o arquivo ou clique para seleciona-lo'}
            options={{
              maxFiles: 1,
              accept: '.pdf',
              multiple: false,
            }}
            onChange={(e) => {
              handleChange(e);
              setDisableButtom(false);
            }}
          />
        ) : null}
      </Box>

      <Box sx={{ paddingBottom: ' 10px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        {clientes.length > 0 ? (
          <>
            <ButtonMui
              $backgroundColor={themeBgButtom}
              onClick={() => {
                setLoadingButtom(true);
                setDisableButtom(true);
                enviarAssinatura();
              }}
              loadingIndicator={<CircularProgress color={'primary'} size={16} />}
              loading={loadingButtom}
              disabled={disableButtom || !userAssina}
              style={{ textTransform: 'none' }}>
              Salvar e Assinar
            </ButtonMui>

            <ButtonMui
              disabled={disableButtom}
              $backgroundColor={themeBgButtom}
              onClick={() => {
                setLoadingButtom(true);
                setDisableButtom(true);
                enviarAssinatura();
              }}
              loadingIndicator={<CircularProgress color={'primary'} size={16} />}
              loading={loadingButtomSave}>
              Salvar
            </ButtonMui>
          </>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
