import { Financeiro, FinanceiroFormModel } from '../models/Financeiro/Financeiro.model';
import { FinanceiroResumoSearchResponse, FinanceiroSearchResponse } from '../models/dtos/FinanceiroSearchResponse.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';
import { Documento } from '../models/Documento/Documento.model';
import { downloadBlob } from '../shared/utils/functions';

export default function useFinanceiroService() {
  const { get, post, put, remove, gridSearch, getFile } = useApi();

  const baseApi = 'api/escritorio/financeiro';

  const obter = async (id: string) => {
    return get<ApiResponse<FinanceiroSearchResponse>>(`${baseApi}/${id}`);
  };

  const obterGridFinanceiros = async (data: SearchRequest<{}>) => {
    return gridSearch<FinanceiroSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterGridResumo = async () => {
    return get<ApiResponse<FinanceiroResumoSearchResponse>>(`${baseApi}/resumo`);
  };

  const obterTodosFinanceiros = async () => {
    return get<Financeiro>(`${baseApi}`);
  };
  
  const removerDocumento = async (id: string, documentoId: string) => {
    return remove<Documento>(`${baseApi}/${id}/documento/${documentoId}`);
  };

  const enviar = async (data: FinanceiroFormModel) => {
    return post(`${baseApi}`, data);
  };
  const enviarDocumento = async (id: string, data: FormData) => {
    return post(`${baseApi}/${id}/documento`, data);
  };
  const obterTodosDocumentos = async (id: string) => {
    return get<ApiResponse<Documento[]>>(`${baseApi}/${id}/documentos`);
  };

  const alterarPagamento = async (id: string, data: {
    valorPago: Number
  }) => {
    return put(`${baseApi}/${id}/baixa`, data);
  };

  const baixarDocumento = async (id: string, documentoId: string, nomeArquivo: string) => {
    return getFile<Blob>(`${baseApi}/${id}/documento/${documentoId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    }).then((blob) => {
      downloadBlob(blob, nomeArquivo);
    });
  };


  const remover = async (id: string) => {
    return remove<Financeiro>(`${baseApi}/${id}`);
  };

  return { obter, enviar, alterarPagamento, remover, obterTodosFinanceiros,removerDocumento, obterGridFinanceiros,baixarDocumento, obterGridResumo,obterTodosDocumentos, enviarDocumento };
}
