import { mdiDelete, mdiPencil } from '@mdi/js';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { StatusAndamento } from '../../components/StatusText/StatusAndamento';
import { StatusTextTraducao } from '../../components/StatusText/StatusTextTraducao';
import { StatusTraducaoIa } from '../../components/StatusText/StatusTraducaoIa';
import { TraducaoSearchResponse } from '../../models/dtos/TraducaoSearchResponse.model';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    TraducaoSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<TraducaoSearchResponse>>;

  removerAndamento: (perguntaId: string) => void;
  editarAndamento(perguntaId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridAndamentosTraduções = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, removerAndamento, editarAndamento }, ref) => {
    const columns: OptGridColumn<TraducaoSearchResponse>[] = [
      {
        title: 'id',
        field: 'id',
        hidden: true,
      },

      {
        title: 'Andamento',
        field: 'textoOriginal',
      },
      {
        title: 'Tradução',
        field: 'traducao',
        render: (data) => {
          return <StatusAndamento status={data.traducao ? true : false} />;
        },
      },
      {
        title: 'Tradução por IA',
        field: 'traducaoInteligenciaArtificial',
        render: (data) => {
          return (
            <StatusTraducaoIa
              status={data.traducao && data.traducao === data.traducaoInteligenciaArtificial ? true : false}
            />
          );
        },
      },
      {
        title: 'Vísivel para o cliente',
        field: 'visivel',
        render: (data) => {
          return <StatusTextTraducao status={data.visivel} />;
        },
      },
    ];

    return (
      <>
        <OptLayoutProvider theme={Theme().insideTheme}>
          <OptGrid
            title=""
            ref={ref as any}
            data={carregar}
            options={options}
            columns={columns}
            headerTitlePosition="start"
            actionsPosition={'end'}
            actions={[
              (rowData) => ({
                icon: { path: mdiPencil },
                tooltip: 'Editar',
                onClick: () => editarAndamento(rowData.id),
                disabled: false,
              }),
              (rowData) => ({
                icon: { path: mdiDelete },
                tooltip: 'Deletar',
                onClick: () => removerAndamento(rowData.id),
                disabled: false,
              }),
            ]}
          />
        </OptLayoutProvider>
      </>
    );
  },
);
