import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { MascaraCNPJ } from '../../components/MascaraCnpj/MascaraCnpj';
import { MascaraCPF } from '../../components/MascaraCPF/MascaraCpf';
import { ClientesSearchResponse } from '../../models/dtos/ClientesSearchResponse.model';
import { Routes } from '../../routes';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    ClientSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<ClientesSearchResponse>>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridClientesDocumentos = forwardRef<OptGridRef | undefined, GridProps>(({ carregar }, ref) => {
  const columns: OptGridColumn<ClientesSearchResponse>[] = [
    {
      title: 'id',
      field: 'id',
      hidden: true,
    },

    {
      title: 'nomeEmpresa',
      field: 'nomeEmpresa',
      hidden: true,
    },
    {
      title: 'cnpj',
      field: 'cnpj',
      hidden: true,
    },
    {
      title: 'TelefoneFixo',
      field: 'telefone.fixo',
      hidden: true,
    },
    {
      title: 'Clientes',
      field: 'nome',
      render: (data: ClientesSearchResponse) => {
        return <>{data.nome ? data.nome : data.nomeEmpresa}</>;
      },
    },
    {
      title: 'CPF/CNPJ',
      field: 'cpf',
      render: (data: ClientesSearchResponse) => {
        return <>{data.cpf ? <MascaraCPF cpf={data.cpf} /> : <MascaraCNPJ cnpj={data.cnpj} />}</>;
      },
    },
  ];

  let history = useHistory();

  return (
    <>
      <OptLayoutProvider theme={Theme().insideTheme}>
        <OptGrid
          title=""
          ref={ref as any}
          onRowClick={(rowData) => {
            history.push(Routes.Documentos.Detalhes(rowData.id));
          }}
          data={carregar}
          options={options}
          columns={columns}
          headerTitlePosition="start"
          actionsPosition={'end'}
        />
      </OptLayoutProvider>
    </>
  );
});
