import * as Yup from 'yup';

export interface FinanceiroPagamentoModel {
    id?: string;
  valorOriginal: string;
  valorPago: string;

}
export type FinanceiroPagamentoFormModel = Partial<FinanceiroPagamentoModel>;

export const FINANCEIRO_PAGAMENTO_DEFAULT: FinanceiroPagamentoFormModel = {
  valorOriginal: '',
  valorPago: "",
};


export const FinanceiroPagamentoFormModelSchema: Yup.SchemaOf<FinanceiroPagamentoModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  valorOriginal: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  valorPago: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
});
