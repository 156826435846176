import { mdiPen, mdiTimer } from '@mdi/js';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonWrapper } from '../../components/Button/Button.styles';
import { IconButton } from '../../components/Button/IconButton';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper, PapersWrapper } from '../../components/Layout/Layout';
import { NavPaper } from '../../components/Paper/NavPaper';
import Toolbar from '../../components/Toolbar/Toolbar';
import { ProcessoForm } from '../../features/ProcessoForm/ProcessoForm';

import { agendamentoDia } from '../../models/Agendamento/Agendamento.model';
import { PROCESSO_DEFAULT } from '../../models/Processo/Processo.model';
import { Routes } from '../../routes';
import useAgendamentoService from '../../services/agendamento.service';
import useProcessoService from '../../services/processo.service';
import { transformarSearchResponseEmProcessoModel } from '../../shared/functions';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridProcessos } from './GridProcessos';

export const Processos = () => {
  const [editandoProcesso, setEditandoProcesso] = useState(false);
  const [processoArray, setProcessoArray] = useState<string[]>([]);
  const [value, setValue] = useState<string>('');
  const [processoSearch, setProcessoSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [defaultProcesso, setDefaultProcesso] = useState({
    ...PROCESSO_DEFAULT,
    processo: [{ ...PROCESSO_DEFAULT.processo[0] }],
  });
  const [dia, setDia] = useState<agendamentoDia>({
    quantidadeCompromissosDia: 0,
  });

  const [andamentoDia, setAndamentoDia] = useState(0);
  const { obterDia } = useAgendamentoService();

  const { remover, obter, obterGridProcessos, enviarLembretes, obterAndamentosDia, removerLembretes } =
    useProcessoService();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  async function obterCompromissoDia() {
    const data = await obterDia();
    const andamentos = await obterAndamentosDia();
    setDia(data.data);
    setAndamentoDia(andamentos.data.value);
  }

  useEffect(() => {
    obterCompromissoDia();
  }, []);

  const history = useHistory();

  const carregar = (
    query: OptGridRequest,
    ProcessoSearchRequest: PaginatedSearchRequest = { termoBuscado: processoSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: ProcessoSearchRequest,
    };
    return obterGridProcessos(request);
  };

  async function removerProcesso(processoId: string) {
    try {
      await remover(processoId);
      ref.current?.refresh();
    } catch {
      console.log('error');
    }
  }
  function processoIdArray(processoId: string[]) {
    setProcessoArray(processoId);
  }

  function criarProcesso() {
    setDefaultProcesso(PROCESSO_DEFAULT);
    toggleDrawer();
  }

  async function editarProcesso(processoId: string) {
    setEditandoProcesso(true);
    try {
      const processoData = await obter(processoId);
      const advogadoResponsavel = processoData.data.advogados.filter((data) => data.responsavel === true);
      console.log(advogadoResponsavel);
      const formData = transformarSearchResponseEmProcessoModel(processoData.data, advogadoResponsavel[0]);
      console.log(formData);
      setDefaultProcesso(formData);
      toggleDrawer();
    } catch {}
  }

  function ProcurarProcesso(data?: string) {
    setProcessoSearch(data ?? '');
  }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    refreshGrid();
  }, [processoSearch]);

  useEffect(() => {
    !isOpen && setEditandoProcesso(false);
  }, [isOpen]);

  useEffect(() => {
    async function enviarNovoLembrete() {
      if (value && processoArray.length > 0) {
        try {
          if (value === '0') {
            await removerLembretes({
              lembretes: [Number(value)],
              processos: processoArray,
            });
          } else {
            await enviarLembretes({
              lembretes: [Number(value)],
              processos: processoArray,
            });
          }
        } catch (erro) {
          console.log(erro);
        } finally {
          refreshGrid();
          setValue('');
        }
      }
    }
    enviarNovoLembrete();
  }, [value]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Visão geral dos processos" />
      <GeralWrapper>
        <PapersWrapper>
          <NavPaper
            titulo={'Andamentos'}
            subtitulo={'Novos andamentos'}
            valor={andamentoDia}
            icon={mdiPen}
            onClick={() => history.push(Routes.AndamentosGerais.Andamentos)}
          />
          <NavPaper
            titulo={'Compromissos'}
            subtitulo={'Novo(s) compromisso(s)'}
            valor={dia?.quantidadeCompromissosDia}
            icon={mdiTimer}
            onClick={() => history.push(Routes.Agendamentos)}
          />
        </PapersWrapper>
        <HeaderContent titulo={'Meus Processos'} height={'100%'}>
          <ButtonWrapper pb="1.4rem">
            <IconButton color onClick={toggleDrawer} />
          </ButtonWrapper>
          <FormDrawer
            title={editandoProcesso ? 'Editar processo:' : 'Cadastro de novo processo:'}
            isOpen={isOpen}
            toggleDrawer={criarProcesso}>
            <ProcessoForm
              toggleDrawer={toggleDrawer}
              refreshGrid={refreshGrid}
              defaultProcesso={defaultProcesso}
              editandoProcesso={editandoProcesso}
            />
          </FormDrawer>
          <OptLayoutProvider theme={Theme().insideTheme}>
            <Box display="flex " gap={'30px'}>
              <Box width="265px" paddingBottom="12px">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Lembrete de processo parado</InputLabel>
                  <Select
                    label="Lembrete de processo parado"
                    placeholder="Lembrete de processo parado"
                    value={value}
                    onChange={(props) => {
                      setValue(props.target.value);
                    }}>
                    <MenuItem value={15}>15 Dias</MenuItem>
                    <MenuItem value={30}>30 Dias</MenuItem>
                    <MenuItem value={45}>45 Dias</MenuItem>
                    <MenuItem value={60}>60 Dias</MenuItem>
                    <MenuItem value={'0'}>Remover lembrete</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <OptSearchField placeholder="Buscar por processos" onSearch={ProcurarProcesso} />
            </Box>
          </OptLayoutProvider>
        </HeaderContent>
        <GridProcessos
          carregar={carregar}
          ref={ref}
          setProcessoArray={processoIdArray}
          removerProcesso={removerProcesso}
          editarProcesso={editarProcesso}
        />
      </GeralWrapper>
    </OptSideLayoutContent>
  );
};

