import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderFunctions = styled.div`
  display: flex;
  align-items: flex-start;
  height: 90%; 
  gap: 20px;
`;
