import React from 'react';
import NumberFormat from 'react-number-format';

interface MascaraCnpj {
  cnpj: any;
}

export function MascaraCNPJ(props: MascaraCnpj) {
  return (
    <>
      <NumberFormat value={props.cnpj} displayType="text" format={'##.###.###/####-##'} name="cnpj" />
    </>
  );
}
