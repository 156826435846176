import { yupResolver } from '@hookform/resolvers/yup';
import { mdiFolderPlusOutline, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  ButtonBase,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  debounce,
} from '@mui/material';
import { OptGridRef } from '@optsol/react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import { ErrorMessage, FormNumberFormat } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { FormAutoComplete } from '../../components/Form/FormAutocomplete';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { FileProcessoModel } from '../../models/Processo/FileProcesso.model';
import {
  LISTA_PROCESSO_DEFAULT,
  ProcessoFormModel,
  ProcessoMultiFormModel,
  ProcessoMultiFormModelSchema,
} from '../../models/Processo/Processo.model';
import { AdvogadoSearchResponse } from '../../models/dtos/AdvogadoSearchResponse.model';
import { ClientesSearchResponse } from '../../models/dtos/ClientesSearchResponse.model';
import useAdvogadoService from '../../services/advogado.service';
import useClienteService from '../../services/cliente.service';
import useProcessoService from '../../services/processo.service';
import { Colors } from '../../shared/colors';
import Theme from '../../shared/theme';
import { GridDocumentosProcesso } from './GridDocumentosProcesso';
interface ProcessoFormProps {
  defaultProcesso: ProcessoMultiFormModel;
  toggleDrawer: () => void;
  refreshGrid: () => void;
  editandoProcesso: boolean;
}

export function ProcessoForm({ defaultProcesso, toggleDrawer, refreshGrid, editandoProcesso }: ProcessoFormProps) {
  const [searchValue, setSearchValue] = useState<string>();
  const [searchAdvogadoValue, setSearchAdvogadoValue] = useState<string>();
  const [responseOptions, setResponseOptions] = useState<readonly ClientesSearchResponse[]>([]);
  const [advogadoOptions, setAdvogadoOptions] = useState<readonly AdvogadoSearchResponse[]>([]);
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const [gridFile, setGridFile] = useState<File[]>([]);
  const [gridColumnsData, setGridColumnsData] = useState<FileProcessoModel[]>([]);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [documentosSelect, setDocumentosSelect] = useState<string | number>('');

  const { insideTheme } = Theme();

  const {
    state: { userInfo },
  } = useAuthenticationContext();

  const { obterGridClientes } = useClienteService();
  const { obterGridAdvogados } = useAdvogadoService();

  const { enviar, alterar } = useProcessoService();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm<ProcessoMultiFormModel>({
    defaultValues: defaultProcesso,
    resolver: yupResolver(ProcessoMultiFormModelSchema),
  });

  const idProcesso = getValues('processo')[0].id;
  const documentoWatch = watch('processo.0.documentosProcesso');
  const processoWatch = watch('processo');

  const obterClienteOptions = () => {
    obterGridClientes({
      page: 0,
      pageSize: 50,
      search: { termoBuscado: searchValue ?? '' },
    })
      .then((res) => {
        setResponseOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setResponseOptions([]);
      });
  };

  const obterAdvogadoOptions = () => {
    obterGridAdvogados({
      page: 0,
      pageSize: 50,
      search: { termoBuscado: searchAdvogadoValue ?? '' },
    })
      .then((res) => {
        setAdvogadoOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setAdvogadoOptions([]);
      });
  };

  useEffect(() => {
    obterClienteOptions();
    obterAdvogadoOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (editandoProcesso && defaultProcesso.processo[0].documentosProcesso) {
      setGridColumnsData(defaultProcesso.processo[0].documentosProcesso);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ref = useRef<OptGridRef>();

  function refreshGridDocumentos() {
    if (ref.current) {
      ref.current.refresh();
    }
  }

  const changeHandler = useCallback((value: any) => {
    setSearchValue(value);
  }, []);

  const changeAdvogadoHandler = useCallback((value: any) => {
    setSearchAdvogadoValue(value);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const debounceChangeAdvogadoHandler = useCallback(debounce(changeAdvogadoHandler, 300), []);

  const onSubmit = async (data: ProcessoMultiFormModel) => {
    setBotaoDesabilitado(true);

    let submitData = data;
    const copiaData: any = data;

    submitData.processo.forEach((element, index) => {
      element.responsavelId = copiaData.processo[index].responsavelId.id;
    });
    if (
      typeof data.processo[0].clienteId === 'object' &&
      data.processo[0].clienteId !== null &&
      !Array.isArray(data.processo[0].clienteId)
    ) {
      const dataCopy: any = data;
      submitData.processo.forEach((element, index) => {
        element.clienteId = dataCopy.processo[index].clienteId.id;
      });
    }
    try {
      await enviar(submitData);
      toggleDrawer();
      refreshGrid();
    } catch {
      console.log('error');
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const onEdit = async (data: ProcessoMultiFormModel) => {
    setBotaoDesabilitado(true);
    let submitData = data;
    const copiaData: any = data;

    submitData.processo.forEach((element, index) => {
      element.responsavelId = copiaData.processo[index].responsavelId.id;
    });
    if (
      typeof data.processo[0].clienteId === 'object' &&
      data.processo[0].clienteId !== null &&
      !Array.isArray(data.processo[0].clienteId)
    ) {
      const dataCopy: any = data;
      submitData.processo.forEach((element, index) => {
        element.clienteId = dataCopy.processo[index].clienteId.id;
        submitData.processo[0].clienteId = element.clienteId;
      });
    }

    setValue('processo.0.documentosProcesso', []);
    if (idProcesso) {
      try {
        await alterar(idProcesso, submitData.processo[0]);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    } else setBotaoDesabilitado(false);
  };

  const onErrors = (data: FieldErrors<ProcessoMultiFormModel>) => {
    console.log('onErros', data);
  };

  const adicionarProcesso = () => {
    const processoValue = getValues();
    const novoProcesso: ProcessoFormModel = {
      ...LISTA_PROCESSO_DEFAULT[0],
    };
    processoValue.processo.push(novoProcesso);
    reset(processoValue);
  };

  const removerProcesso = (index: number) => {
    const processoValue = getValues();
    processoValue.processo.splice(index, 1);
    setValue('processo.0.documentosProcesso', []);
    setGridColumnsData([]);
    setDocumentosSelect('');
    reset(processoValue);
  };

  const adicionarArquivos = () => {
    if (documentoWatch && documentosSelect) {
      const element = document.getElementById('documentos-processo');
      element!.click();
    }
  };

  function modelTransform(files: File[], documentosSelect: string | number) {
    const newModel: FileProcessoModel[] = [];
    files.forEach((file, index) => {
      newModel.push({
        id: Math.floor(Math.random() * 9999).toString(),
        tipo: parseInt(documentosSelect.toLocaleString()),
        arquivo: files[index],
      });
    });
    return newModel;
  }
  function removerDocumentoGrid(id: string) {
    const newGrid = gridColumnsData.filter((props) => props.id !== id);
    setGridColumnsData(newGrid);
  }

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    if (documentoWatch && !documentosSelect) setOpenTooltip(true);
  };

  useEffect(() => {
    if (gridFile.length > 0 && documentosSelect) {
      const columnData = modelTransform(gridFile, documentosSelect);
      setGridColumnsData([...gridColumnsData, ...columnData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFile]);

  useEffect(() => {
    refreshGridDocumentos();
    setValue('processo.0.documentosProcesso', gridColumnsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridColumnsData]);

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
        <Controller
          name="processo"
          control={control}
          render={({ field: { value } }) => (
            <>
              {value.map((inputs, index) => {
                return (
                  <Fragment key={index}>
                    <Grid item xs={12} mb={-1} display="flex">
                      {/* <Typography>{`Processo ${(index + 1).toString().padStart(2, '0')}`}</Typography> */}
                      {processoWatch.length > 1 && (
                        <ButtonBase
                          sx={{ borderRadius: '50%', padding: '0 2px', marginTop: '-1px', marginLeft: '20px' }}
                          onClick={() => removerProcesso(index)}>
                          <Icon path={mdiTrashCanOutline} size={0.8} color={Colors.gray3} />
                        </ButtonBase>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`processo.${index}.responsavelId`}
                        control={control}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <FormAutoComplete
                            options={advogadoOptions}
                            value={value ?? null}
                            noOptionsText="Nenhum resuldado encontrado"
                            onBlur={onBlur}
                            onChange={(event, value) => {
                              onChange(value);
                            }}
                            getOptionLabel={(option: any) => option.nome}
                            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                placeholder="Responsável pelo processo"
                                label="Responsável pelo processo"
                              />
                            )}
                          />
                        )}
                      />
                      {errors.processo && <ErrorMessage error={errors.processo[index]?.clienteId} />}
                    </Grid>
                    <Grid item xs={6}>
                      <FormNumberFormat
                        control={control}
                        name={`processo.${index}.numero`}
                        label="Número processo"
                        placeholder="Número processo"
                        format="#######-##.####.#.##.####"
                      />
                    </Grid>
                    {value.length > 1 && (
                      <>
                        <Grid item xs={6}>
                          <Controller
                            name={`processo.${index}.clienteId`}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                              <FormAutoComplete
                                options={responseOptions}
                                value={value ?? null}
                                noOptionsText="Nenhum resuldado encontrado"
                                onBlur={onBlur}
                                onChange={(event, value) => {
                                  onChange(value);
                                }}
                                onInputChange={(event, value) => {
                                  debounceChangeHandler(value);
                                }}
                                getOptionLabel={(option: any) => (option.nome ? option.nome : option.nomeEmpresa)}
                                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Buscar por cliente"
                                    label="Buscar por cliente"
                                  />
                                )}
                              />
                            )}
                          />
                          {errors.processo && <ErrorMessage error={errors.processo[index]?.clienteId} />}
                        </Grid>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </>
          )}
        />
        {/*        {!editandoProcesso && (
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography sx={{ marginRight: 1.5 }}>Cadastrar múltiplos processos?</Typography>
            <IconButton onClick={adicionarProcesso} color />
          </Grid>
        )} */}
        {processoWatch.length === 1 && (
          <>
            <Grid item xs={6} mt={3}>
              <Controller
                name={`processo.0.clienteId`}
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <FormAutoComplete
                    options={responseOptions}
                    disabled={editandoProcesso}
                    value={value ?? null}
                    onBlur={onBlur}
                    onChange={(event, value) => {
                      onChange(value);
                    }}
                    onInputChange={(event, value) => {
                      debounceChangeHandler(value);
                    }}
                    getOptionLabel={(option: any) => (option.nome ? option.nome : option.nomeEmpresa)}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        disabled={editandoProcesso}
                        placeholder="Buscar por cliente"
                        label="Buscar por cliente"
                      />
                    )}
                  />
                )}
              />
              {errors.processo && <ErrorMessage error={errors.processo[0]?.clienteId} />}
            </Grid>
            <Grid item xs={6} mt={!editandoProcesso ? '-10.7px' : ''}>
              {!editandoProcesso && (
                <Box sx={{ display: 'flex', justifyContent: 'right', marginBottom: '7px' }}>
                  <input
                    disabled={editandoProcesso}
                    type="file"
                    style={{ display: 'none' }}
                    id="documentos-processo"
                    multiple={true}
                    onChange={(e) => {
                      if (e.target.files) {
                        const files = Array.from(e.target.files);
                        setGridFile(files);
                      }
                    }}
                  />
                  <Tooltip
                    title="Escolha um tipo de documento"
                    placement="left"
                    open={openTooltip}
                    onOpen={handleOpenTooltip}
                    onClose={handleCloseTooltip}>
                    <ButtonBase sx={{ borderRadius: '50%', padding: 0.5 }} onClick={adicionarArquivos}>
                      <Icon path={mdiFolderPlusOutline} size={0.8} color={insideTheme.light?.primary} />
                    </ButtonBase>
                  </Tooltip>
                </Box>
              )}
              {idProcesso ? null : (
                <FormControl fullWidth>
                  <InputLabel id={`select-helper-label`}>{'Documentos do processo'}</InputLabel>
                  <Select
                    label={'Documentos do processo'}
                    placeholder={'Documentos do processo'}
                    labelId={`select-helper-label`}
                    onChange={(e) => {
                      setDocumentosSelect(e.target.value);
                    }}
                    value={documentosSelect}
                    variant="outlined"
                    disabled={editandoProcesso}
                    fullWidth>
                    <MenuItem value={1}>Petições</MenuItem>
                    <MenuItem value={2}>Procurações</MenuItem>
                    <MenuItem value={3}>Provas</MenuItem>
                    <MenuItem value={4}>Comprovantes</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <SubmitWrapper>
        <ButtonMui
          $backgroundColor={insideTheme.light?.primary}
          onClick={handleSubmit(idProcesso ? onEdit : onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
      {gridColumnsData.length > 0 && processoWatch.length === 1 && (
        <Box mt={0.5} px={2.6}>
          <GridDocumentosProcesso
            data={gridColumnsData}
            removerDocumento={removerDocumentoGrid}
            ref={ref}
            editandoProcesso={editandoProcesso}
          />
        </Box>
      )}
    </>
  );
}
