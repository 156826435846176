import { mdiDelete, mdiPencil } from '@mdi/js';
import { OptGrid, OptGridColumn, OptGridOptions, OptGridRef, OptGridRequest, OptGridResponse } from '@optsol/react';
import { forwardRef } from 'react';
import { MascaraCPF } from '../../components/MascaraCPF/MascaraCpf';
import { StatusUsuario } from '../../components/StatusText/StatusUsuario';
import { UsuarioSearchResponse } from '../../models/dtos/UsuarioSearchResponse.model';
import { PaginatedUserSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    UsuarioSearchRequest?: PaginatedUserSearchRequest,
  ) => Promise<OptGridResponse<UsuarioSearchResponse>>;
  removerUsuarios(usuarioId: string): void;
  editarUsuarios(usuarioId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridUsuarios = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, removerUsuarios, editarUsuarios }, ref) => {
    const columns: OptGridColumn<UsuarioSearchResponse>[] = [
      {
        title: 'id',
        field: 'id',
        hidden: true,
      },
      {
        title: 'Nome',
        field: 'nome',
      },
      {
        title: 'CPF/CNPJ',
        field: 'cpf',
        render: (data) => {
          return <MascaraCPF cpf={data.cpf} />;
        },
      },

      {
        title: 'Email',
        field: 'email',
      },

      {
        title: 'Status',
        field: 'status',
        render: (data) => {
          return <StatusUsuario status={data.status} />;
        },
      },
    ];

    return (
      <>
        <OptGrid
          title=""
          ref={ref as any}
          data={carregar}
          options={options}
          columns={columns}
          headerTitlePosition="start"
          actionsPosition={'end'}
          actions={[
            (rowData) => ({
              icon: { path: mdiPencil },
              tooltip: 'Editar',
              onClick: () => editarUsuarios(rowData.id),
              disabled: false,
            }),
            (rowData) => ({
              icon: { path: mdiDelete },
              tooltip: 'Deletar',
              onClick: () => removerUsuarios(rowData.id),
              disabled: false,
            }),
          ]}
        />
      </>
    );
  },
);
