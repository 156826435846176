import { OptTheme } from '@optsol/react';
import { useEffect, useState } from 'react';
import { useAuthenticationContext } from '../contexts/authentication/authenticationContext';
import { Colors } from './colors';

const Theme = () => {
  const [themeState, setThemeState] = useState<string>(Colors.primaryContrast);

  const {
    state: { userInfo },
  } = useAuthenticationContext();

  useEffect(() => {
    if (userInfo.data) {
      if (userInfo.data.escritorio && userInfo.data.escritorio.cor) {
        if (
          userInfo.data.escritorio.cor !== null &&
          userInfo.data.escritorio.cor !== undefined &&
          userInfo.data.escritorio.cor.charAt(0) === '#'
        )
          setThemeState(userInfo.data.escritorio.cor);
      }
    }
  }, [userInfo]);

  const theme: OptTheme = {
    light: {
      style: 'soft',
      primary: Colors.primary,
      primaryContrast: themeState,
      secondary: Colors.white,
      secondaryContrast: Colors.primaryContrast,
    },
  };

  const insideTheme: OptTheme = {
    light: {
      style: 'soft',
      primary: themeState,
      primaryContrast: Colors.primary,
      secondary: Colors.gray7,
      secondaryContrast: themeState,
    },
  };

  return { theme, insideTheme };
};

export default Theme;
