import { OptGridResponse, OptSearchResponse } from '@optsol/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { useErrorSnackbar } from '../hooks/errorSnackbar';
import { IApiError } from '../types/ApiError';
import useAxios from './Axios';
import { SearchRequest } from './searchRequest';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { useMsalService } from '../../services/auth/msal.service';
import { BaseConfig } from './baseConfig';

export default function useApi(baseUrl?: string) {
  const { tratarErro } = useErrorSnackbar();
  const Http = useAxios(baseUrl);
  Http.interceptors.response.use(
    (axiosResponse) => {
      const responseInvalida = !axiosResponse.data;

      if (responseInvalida) {
        console.info('Controller não retornou IActionResult!');
      }
      return axiosResponse.data;
    },
    (error: AxiosError<IApiError>) => {
      tratarErro(error.response?.data);
      return Promise.reject(error.response?.data);
    },
  );
  const {
    state: { tenantId },
    definirToken,
  } = useAuthenticationContext();

  const { getAccessToken } = useMsalService();

  Http.interceptors.request.use(
    async (config) => {
      const configValida = config !== undefined && config.url !== undefined;
      if (configValida) {
        try {
          let token = await getAccessToken();
          definirToken(token);

          if (token && config.headers !== undefined) {
            config.headers.Authorization = `Bearer ${token}`;

            if (tenantId) {
              config.headers[BaseConfig.TenantHeader] = tenantId;
            }

            // se for necessário verificar expiração do token, considere o fato dele estar em UNIX DATE
            // const tokenValido = token !== undefined && token.accessToken !== undefined;
            // if (tokenValido) {
            //   config.headers['Authorization'] = Bearer ${token.accessToken};
            // }
          }
        } catch (e) {
          console.error(e);
        }
      }

      return config;
    },
    (error) => {
      //TODO: exibir alerta?
      if (error.response.status === 403) {
        enqueueSnackbar(error + ': Forbidden', { variant: 'error' });
      }

      return Promise.reject(error);
    },
  );

  function gridSearch<T extends object>(url: string, data: SearchRequest<any>, config?: AxiosRequestConfig) {
    data.page = data.page + 1;
    return Http.post<T, OptSearchResponse<T>>(url, data, config).then((response) => {
      const r: OptGridResponse<T> = { data: response.data, page: response.page - 1, totalCount: response.total };
      return r;
    });
  }

  const get = <T, R = T>(url: string, config?: AxiosRequestConfig) => {
    return Http.get<T, R>(url, config);
  };

  const post = <T, R = T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    return Http.post<T, R>(url, data, config);
  };

  const put = <T, R = T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    return Http.put<T, R>(url, data, config);
  };

  const remove = <T, R = T>(url: string, config?: AxiosRequestConfig) => {
    return Http.delete<T, R>(url, config);
  };

  function getFile<T, R = T>(url: string, config?: AxiosRequestConfig) {
    return Http.get<T, R>(url, config);
  }

  return { get, post, put, remove, gridSearch, getFile };
}
function enqueueSnackbar(arg0: string, arg1: { variant: string }) {
  throw new Error('Function not implemented.');
}
