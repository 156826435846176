import { Box } from '@mui/material';
import { OptLayoutProvider, OptLoading, OptSideLayout } from '@optsol/react';
import { useEffect } from 'react';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { routes } from '../../routes';
import { useMsalService } from '../../services/auth/msal.service';
import { usuarioInvalido } from '../../shared/mock';
import Theme from '../../shared/theme';
import './App.css';
import { useSections } from './App.sections';

function App() {
  const msal = useMsalService();
  const sections = useSections();

  const {
    state: { userInfo },
    inicializar: inicializarToken,
  } = useAuthenticationContext();

  async function inicializar() {
    await inicializarToken();
  }

  useEffect(() => {
    inicializar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const possuiUserInfo = userInfo && userInfo.data;
  const carregando = userInfo?.loading;

  return (
    <OptLayoutProvider theme={Theme().theme}>
      {carregando && (
        <Box
          sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}>
          {' '}
          <OptLoading size={50} />
        </Box>
      )}
      {!carregando && !possuiUserInfo && (
        <>
          <h3>Falha ao recuperar informações do usuário.</h3>
          <button onClick={msal.logout}>Logout</button>
        </>
      )}
      {!carregando && possuiUserInfo && (
        <OptSideLayout
          onLogout={() => {}}
          onManageProfile={() => {}}
          profile={usuarioInvalido}
          routes={routes}
          sections={sections}
          version="1.0.0"
          appBarConfig={{
            hideLinkDescription: true,
          }}
        />
      )}
    </OptLayoutProvider>
  );
}

export default App;
