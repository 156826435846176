import { DocumentoProcesso } from '../models/Documento/DocumentoProcesso..model';
import { AdvogadoSearchResponse } from '../models/dtos/AdvogadoSearchResponse.model';
import { CompromissoSearchResponse } from '../models/dtos/AgendamentoSearchResponse.model';
import { AndamentoSearchResponse } from '../models/dtos/AndamentoSearchResponse';
import { DocumentoResponse } from '../models/dtos/DocumentoResponse.model';
import { andamentoDia, ProcessoGetResponse, ProcessoSearchResponse } from '../models/dtos/ProcessoSearchResponse.model';
import { LembretesModel, ProcessoFormModel, ProcessoMultiFormModel } from '../models/Processo/Processo.model';
import { objectToFormDataProcesso } from '../shared/functions';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { downloadBlob } from '../shared/utils/functions';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useProcessoService() {
  const { get, post, put, remove, gridSearch, getFile } = useApi();

  const baseApi = 'api/processo';

  const obterDocumento = async (id: string, documentoId: string, nomeArquivo: string) => {
    return getFile<Blob>(`${baseApi}/${id}/documento/${documentoId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    }).then((blob) => {
      downloadBlob(blob, nomeArquivo);
    });
  };

  const obterTodosDocumentos = async (id: string) => {
    return get<ApiResponse<DocumentoResponse[]>>(`${baseApi}/${id}/documentos`);
  };

  const enviarDocumento = async (id: string, data: FormData) => {
    return post(`${baseApi}/${id}/documento`, data);
  };

  const obterGridAndamento = async (data: SearchRequest<{}>) => {
    return gridSearch<AndamentoSearchResponse>(`${baseApi}/andamentos/data`, data);
  };

  const removerDocumento = async (id: string, documentoId: string) => {
    return remove<DocumentoProcesso>(`${baseApi}/${id}/documento/${documentoId}`);
  };

  const obterGridProcessos = async (data: SearchRequest<{}>) => {
    return gridSearch<ProcessoSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterGridProcessosCliente = async (id: string) => {
    return get<ApiResponse<ProcessoSearchResponse[]>>(`${baseApi}/cliente/${id}`);
  };

  const obterTodosProcessos = async () => {
    return get<ProcessoMultiFormModel>(`${baseApi}`);
  };

  const obterCompromissosProcessos = async (processoId: string) => {
    return get<ApiResponse<CompromissoSearchResponse[]>>(`${baseApi}/${processoId}/compromissos`);
  };

  const obter = async (id: string) => {
    return get<ApiResponse<ProcessoGetResponse>>(`${baseApi}/${id}`);
  };

  const enviar = async (data: ProcessoMultiFormModel) => {
    if (data.processo.length > 1) return post(`${baseApi}/multi`, data);
    if (data.processo.length === 1) {
      const processoData: ProcessoFormModel = {
        clienteId: data.processo[0].clienteId,
        responsavelId: data.processo[0].responsavelId,
        numero: data.processo[0].numero,
        documentosProcesso: data.processo[0].documentosProcesso,
      };

      return post(`${baseApi}`, objectToFormDataProcesso({ ...processoData }));
    }
  };

  const alterar = async (id: string, data: ProcessoFormModel) => {
    return put<ProcessoFormModel>(`${baseApi}/${id}`, data);
  };

  const alterarTraducao = async (
    ProcessoId: string,
    andamentoId: string,
    data: {
      traducao: string;
      visivel: boolean;
    },
  ) => {
    return put(`${baseApi}/${ProcessoId}/andamentos/${andamentoId}/traducao`, data);
  };

  const enviarAdvogado = async (ProcessoId: string, advogadoId: string) => {
    return post(`${baseApi}/${ProcessoId}/advogado-adicionar/${advogadoId}`);
  };

  const obterAndamentosDia = async () => {
    return get<ApiResponse<andamentoDia>>(`${baseApi}/andamentos/total-dia`);
  };

  const obterAdvogadosProcesso = async (id: string) => {
    return get<ApiResponse<AdvogadoSearchResponse[]>>(`${baseApi}/${id}/advogados`);
  };

  const removerAdvogadosProcesso = async (processoId: string, advogadoId: string) => {
    return remove(`${baseApi}/${processoId}/advogado-remover/${advogadoId}`);
  };

  const remover = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };
  const enviarLembretes = async (data: LembretesModel) => {
    return put(`${baseApi}/lembretes`, data);
  };
  const removerLembretes = async (data: LembretesModel) => {
    return put(`${baseApi}/lembretes-remover`, data);
  };

  return {
    obter,
    enviar,
    alterar,
    remover,
    removerLembretes,
    obterTodosProcessos,
    obterGridProcessos,
    obterTodosDocumentos,
    obterDocumento,
    enviarDocumento,
    removerDocumento,
    obterGridProcessosCliente,
    enviarLembretes,
    obterGridAndamento,
    alterarTraducao,
    enviarAdvogado,
    obterAndamentosDia,
    obterAdvogadosProcesso,
    removerAdvogadosProcesso,
    obterCompromissosProcessos,
  };
}
