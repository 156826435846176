import { mdiDelete } from '@mdi/js';
import { Box, Tooltip, Typography } from '@mui/material';
import { OptGrid, OptGridColumn, OptGridRef } from '@optsol/react';
import { forwardRef, useState } from 'react';
import { AdvogadoSearchResponse } from '../../models/dtos/AdvogadoSearchResponse.model';
import { Colors } from '../../shared/colors';

interface Props {
  data: AdvogadoSearchResponse[];
  removerAdvogado: (advogadoId: string) => void;
}

export const GridEditarAdvogado = forwardRef<OptGridRef | undefined, Props>(({ data, removerAdvogado }, ref) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const columns: OptGridColumn<AdvogadoSearchResponse>[] = [
    {
      title: 'Id',
      field: 'id',
      hidden: true,
    },
    {
      title: 'Nome',
      field: 'nome',
      render: (data) => {
        if (data.responsavel) {
          return (
            <Tooltip
              title={'Este é o advogado responsável pelo processo. Favor editar o processo para alterá-lo.'}
              placement="bottom-start"
              open={openTooltip}
              onOpen={handleOpenTooltip}
              onClose={handleCloseTooltip}>
              <Box>
                <Typography color={data.responsavel && Colors.gray5}>{data.nome}</Typography>
              </Box>
            </Tooltip>
          );
        } else {
          return <Typography color={data.responsavel && Colors.gray5}>{data.nome}</Typography>;
        }
      },
    },
    {
      title: 'responsavel',
      field: 'responsavel',
      hidden: true,
    },
  ];

  return (
    <>
      <OptGrid
        title=""
        columns={columns}
        data={data}
        ref={ref as any}
        actions={[
          (rowData) => {
            if (rowData.responsavel === false) {
              return {
                icon: { path: mdiDelete },
                tooltip: 'Deletar advogado',
                onClick: () => {
                  if (rowData.id) removerAdvogado(rowData.id);
                },
                disabled: false,
              };
            } else {
              return {
                icon: { path: '' },
                onClick: () => null,
                disabled: false,
              };
            }
          },
        ]}
        actionsPosition={'end'}
      />
    </>
  );
});
