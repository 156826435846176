import NumberFormat from 'react-number-format';
import { Telefone } from '../../models/Cliente/Cliente.model';

interface MascaraTelefoneProps {
  telefone: Telefone;
}

export function MascaraTelefone(props: MascaraTelefoneProps) {
  return (
    <>
      <NumberFormat
        value={props.telefone.celular ? props.telefone.celular : props.telefone.fixo}
        displayType="text"
        format={props.telefone.fixo ? '(##) ####-####' : '(##) # ####-####'}
        name="telefone.fixo"
      />
    </>
  );
}
