import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import { FormTextField } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import InfoCabecalho from '../../components/Layout/InfoCabecalho';
import {
  PerguntasFrequentes,
  PerguntasFrequentesFormModel,
  PerguntasFrequentesFormModelSchema,
} from '../../models/PerguntasFrequentes/PerguntasFrequentes.model';
import usePerguntasFrequentesService from '../../services/perguntasFrequentes.service';
import Theme from '../../shared/theme';

interface PerguntaFrequenteFormProps {
  defaultPerguntaFrequente?: Partial<PerguntasFrequentes>;
  toggleDrawer: () => void;
  refreshGrid: () => void;
}

export function PerguntaFrequenteForm({
  defaultPerguntaFrequente,
  toggleDrawer,
  refreshGrid,
}: PerguntaFrequenteFormProps) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);

  const { handleSubmit, control, getValues } = useForm<PerguntasFrequentes>({
    defaultValues: defaultPerguntaFrequente,
    resolver: yupResolver(PerguntasFrequentesFormModelSchema),
  });
  const { enviar, alterar } = usePerguntasFrequentesService();
  const idPergunta = getValues('id');

  const onSubmit = async (data: PerguntasFrequentesFormModel) => {
    try {
      setBotaoDesabilitado(true);
      await enviar(data);
      toggleDrawer();
      refreshGrid();
    } catch {
      console.log('error');
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const onEdit = async (data: PerguntasFrequentesFormModel) => {
    if (idPergunta) {
      try {
        setBotaoDesabilitado(true);
        await alterar(idPergunta, data);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };

  const onErrors = (data: FieldErrors<PerguntasFrequentes>) => {
    console.log('onErros', data);
  };

  return (
    <>
      <Grid container item columnSpacing={5} px={2.6} display={'flex'} justifyContent={'space-between'}>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}>
          <InfoCabecalho titulo="Cadastrar pergunta:" />
        </Grid>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}></Grid>
        <Grid item xs={12} mt={7}>
          <FormTextField
            control={control}
            name={'pergunta'}
            label="Pergunta"
            placeholder="Pergunta"
            multiline
            minRows={1}
          />
        </Grid>
        <Grid item xs={12} mt={7}>
          <FormTextField
            control={control}
            name={'resposta'}
            label="Resposta"
            placeholder="Resposta"
            multiline
            minRows={3}
          />
        </Grid>
      </Grid>
      <SubmitWrapper>
        <ButtonMui
          $backgroundColor={Theme().insideTheme.light?.primary}
          onClick={handleSubmit(idPergunta ? onEdit : onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}
