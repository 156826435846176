import { Box } from '@mui/system';
import React from 'react';
import { Colors } from '../../shared/colors';

interface StatusTextProps {
  status: string;
}

export function StatusText(props: StatusTextProps) {
  return (
    <>
      {props.status === 'Assinado' ? (
        <Box sx={{ color: Colors.gray6, fontWeight: '600' }}>• {props.status}</Box>
      ) : props.status === 'Novo' ? (
        <Box sx={{ color: 'lightgreen', fontWeight: '600' }}>• {props.status}</Box>
      ) : (
        <Box sx={{ color: '#FFA500', fontWeight: '600' }}>• {props.status}</Box>
      )}
    </>
  );
}
