import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, debounce, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldErrors, get, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import ButtonMui from '../../components/Button/ButtonMui';
import { ErrorMessage, FormNumberFormat, FormSelect, FormTextField } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { FormAutoComplete } from '../../components/Form/FormAutocomplete';
import { ClientesSearchResponse } from '../../models/dtos/ClientesSearchResponse.model';
import { ProcessoSearchResponse } from '../../models/dtos/ProcessoSearchResponse.model';
import { Financeiro, FinanceiroFormModel, FinanceiroFormModelSchema } from '../../models/Financeiro/Financeiro.model';
import useClienteService from '../../services/cliente.service';
import useFinanceiroService from '../../services/financeiro.service';
import useProcessoService from '../../services/processo.service';
import Theme from '../../shared/theme';

interface FinanceiroFormProps {
  defaultFinanceiro: Partial<Financeiro>;
  toggleDrawer: () => void;
  refreshGrid(): void;
}

export function FinanceiroForm({ defaultFinanceiro, toggleDrawer, refreshGrid }: FinanceiroFormProps) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const [responseOptions, setResponseOptions] = useState<readonly ClientesSearchResponse[]>([]);
  const [processoLista, setProcessoLista] = useState<ProcessoSearchResponse[]>([]);
  const [searchValue, setSearchValue] = useState<string>();

  const { handleSubmit, control, getValues, watch, setValue, formState } = useForm<FinanceiroFormModel>({
    defaultValues: defaultFinanceiro,
    resolver: yupResolver(FinanceiroFormModelSchema),
  });
  const clienteId = getValues('clienteId');
  const parcelasWatch = watch('parcelas');

  const { enviar } = useFinanceiroService();
  const { obterGridProcessosCliente } = useProcessoService();
  const { obterGridClientes } = useClienteService();

  const changeHandler = useCallback((value: any) => {
    setSearchValue(value);
  }, []);

  const debounceChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const onSubmit = async (data: FinanceiroFormModel) => {
    let submitData = data;

    if (typeof data.clienteId === 'object' && data.clienteId !== null && !Array.isArray(data.clienteId)) {
      const dataCopy: any = data;
      submitData.clienteId = dataCopy.clienteId.id;
      submitData.parcelas = dataCopy.parcelas.replace('x', '');
    }
    try {
      setBotaoDesabilitado(true);
      await enviar(submitData);
      toggleDrawer();
      refreshGrid();
    } catch {
      console.log('error');
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const buscarProcessoLista = async (id: any) => {
    const data = await obterGridProcessosCliente(id);
    setProcessoLista(data.data);
  };

  const onErrors = (data: FieldErrors<FinanceiroFormModel>) => {
    console.log('onErros', data);
  };

  useEffect(() => {
    if (clienteId) buscarProcessoLista(defaultFinanceiro.clienteId);
  }, []);

  useEffect(() => {
    obterGridClientes({
      page: 0,
      pageSize: 50,
      search: { termoBuscado: searchValue ?? '' },
    })
      .then((res) => {
        setResponseOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setResponseOptions([]);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
        <Grid item xs={6}>
          <Controller
            name={'clienteId'}
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              // eslint-disable-next-line react/jsx-no-undef
              <FormAutoComplete
                noOptionsText="Nenhum resuldado encontrado"
                options={responseOptions}
                value={value || null}
                onBlur={onBlur}
                onChange={(event, value: any) => {
                  buscarProcessoLista(value.id);
                  return onChange(value);
                }}
                onInputChange={(event, value) => {
                  debounceChangeHandler(value);
                }}
                getOptionLabel={(option: any) => (option.nome ? option.nome : option.nomeEmpresa)}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder="Buscar por cliente"
                    label="Buscar por cliente"
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name={'processoId'}
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <FormSelect control={control} name="processoId" label="Processo" placeholder="Processo">
                {processoLista!.length
                  ? processoLista.map((props, i) => {
                      return (
                        <MenuItem value={props.id} key={i}>
                          {props.numero}
                        </MenuItem>
                      );
                    })
                  : null}
              </FormSelect>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            control={control}
            name="formaDePagamento"
            label="Formas de pagamento"
            placeholder="Formas de pagamento">
            <MenuItem value={1}>Boleto</MenuItem>
            <MenuItem value={2}>Pix</MenuItem>
            <MenuItem value={3}>Crédito</MenuItem>
            <MenuItem value={4}>Débito</MenuItem>
          </FormSelect>
        </Grid>
        <Grid item xs={6}>
          <FormNumberFormat
            control={control}
            name="parcelas"
            label="Parcelas"
            placeholder="1x"
            suffix={'x'}
            format={String(parcelasWatch).length === 1 ? '#x' : '##x'}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="valorOriginal"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <NumberFormat
                  fullWidth
                  decimalScale={2}
                  fixedDecimalScale
                  decimalSeparator=","
                  thousandSeparator="."
                  label="Valor Original"
                  placeholder="R$16.000,00"
                  customInput={TextField}
                  value={value ?? ''}
                  onValueChange={(values, source) => {
                    setValue('valorOriginal', values.floatValue);
                    if (source.event) {
                      const { event } = source;
                      const { floatValue: value } = values;
                      onChange({ ...event, target: { ...event.target, value } });
                    }
                  }}
                />
              </>
            )}
          />
          <ErrorMessage error={get(formState.errors, 'valorOriginal')} />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="dataVencimento"
            render={({ field: { onChange, value } }) => (
              <DatePicker
                disablePast
                label="Data de vencimento"
                value={value}
                onChange={onChange}
                slotProps={{
                  textField: {
                    sx: {
                      width: '100%',
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField control={control} name="descricao" label="Descrição" placeholder="Digite o texto...." />
        </Grid>
      </Grid>
      <SubmitWrapper>
        <ButtonMui
          $backgroundColor={Theme().insideTheme.light?.primary}
          onClick={handleSubmit(onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}
