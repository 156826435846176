import { mdiPen } from '@mdi/js';
import { Box, Grid, Typography } from '@mui/material';
import { OptDivider } from '@optsol/react';
import { andamentosProcesso } from '../../models/dtos/ProcessoSearchResponse.model';
import { Colors } from '../../shared/colors';
import { ButtonWrapper } from '../Button/Button.styles';
import { IconButton } from '../Button/IconButton';

interface Props {
  andamentos: andamentosProcesso[] | undefined;
  atualizarAndamentoAtual: (andamento: andamentosProcesso, index: number) => void;
  toggleDrawer: () => void;
  overflow: string;
}

const InfoAndamentosProcesso = ({ andamentos, atualizarAndamentoAtual, toggleDrawer, overflow }: Props) => {
  const onEdit = async (item: andamentosProcesso, index: number) => {
    try {
      atualizarAndamentoAtual(item, index);
      console.log(item);
    } finally {
      toggleDrawer();
    }
  };

  return (
    <>
      <Box sx={{ maxHeight: '50vh', overflowY: overflow }}>
        <Grid container item columnSpacing={5} display={'flex'} justifyContent={'space-between'}>
          <Grid item xs={12} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={'column'}>
              {andamentos
                ? andamentos.map((item, index) => {
                    const novaData = new Date(item.data);
                    const horaData = novaData.toLocaleTimeString('pt-Br', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    });

                    return (
                      <Box bgcolor={index % 2 === 0 ? Colors.white : Colors.gray10} key={index} px={2.5} pt={1} pb={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <p style={{ fontWeight: 500 }}>{horaData}</p>
                          <ButtonWrapper>
                            <IconButton icon={mdiPen} p={'5px'} onClick={() => onEdit(item, index)} />
                          </ButtonWrapper>
                        </Box>
                        <Typography
                          fontWeight={800}
                          color={item.andamento.traducao && item.andamento.traducao.traducao.length ? '' : 'red'}>
                          {item.andamento.descricao}
                        </Typography>
                        {item.andamento.traducao && item.andamento.traducao.traducao.length ? (
                          <>
                            <OptDivider />
                            <Typography>{item.andamento.traducao.traducao}</Typography>
                          </>
                        ) : (
                          ''
                        )}
                      </Box>
                    );
                  })
                : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InfoAndamentosProcesso;
