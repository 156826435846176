import * as Yup from 'yup';

export interface UserAdvogado {
  id?: string;

  oab: string;
  ufOab: string;
  telefone: string;
}
export type UserAdvogadoFormModel = Partial<UserAdvogado>;

export const USER_ADVOGADO_DEFAULT: UserAdvogadoFormModel = {
  telefone: '',
  id: '',
  oab: '',
  ufOab: '',
};

// export const TelefoneSchema: Yup.SchemaOf<Telefone> = Yup.object().shape({
//   fixo: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),

//   celular: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
// });

export const UserAdvogadoFormModelSchema: Yup.SchemaOf<UserAdvogadoFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  telefone: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  // endereco: EnderecoFormModelSchema.required().typeError('Campo obrigatório'),
  oab: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  ufOab: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
});

