import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import NotificacaoField from '../../components/NotificacaoField/NotificacaoField';
import { NotificacaoSearchResponse } from '../../models/dtos/NotificacaoSearchResponse.model';
import useNotificacaoService from '../../services/notificacao.service';
import cocktail from '../../shared/Imagens/Cocktail _Outline.svg';

export default function InfoNotification() {
  const { obterTodasNotificacoes, removerNotificacao, marcarLida } = useNotificacaoService();
  const [notificacao, setNotificacao] = useState<NotificacaoSearchResponse[]>();
  const [totalNotificacoes, setTotalNotificacoes] = useState(15);
  const [loading, setLoading] = useState(false);

  async function obterNotificacoes() {
    setLoading(true);
    try {
      const data = await obterTodasNotificacoes({
        page: 0,
        pageSize: totalNotificacoes,
        search: {},
      });
      setNotificacao(data.data);
    } finally {
      setLoading(false);
    }
  }

  async function removerNotificacaoLista(id: string) {
    await removerNotificacao(id);
    obterNotificacoes();
  }
  async function marcarNotificacaoLista(id: string) {
    await marcarLida({
      notificacoes: [id],
    });
    obterNotificacoes();
  }

  useEffect(() => {
    obterNotificacoes();
  }, [totalNotificacoes]);

  return (
    <Box>
      {notificacao ? (
        <NotificacaoField
          loading={loading}
          totalNotificacoes={setTotalNotificacoes}
          notificacao={notificacao}
          removerNotificacao={removerNotificacaoLista}
          marcarNotificacao={marcarNotificacaoLista}
        />
      ) : (
        <Box
          sx={{
            height: '80vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          flexDirection="column">
          <img src={cocktail} alt={'pagina não encontrada'} />
          <Typography variant="h6">Não há nenhuma notificação</Typography>
          <Typography>Volte a verificar mais tarde</Typography>
        </Box>
      )}
    </Box>
  );
}

