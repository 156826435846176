import { format, parse } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { DocumentoProcesso } from '../models/Documento/DocumentoProcesso..model';
import { ProcessoFormModel, ProcessoMultiFormModel } from '../models/Processo/Processo.model';
import { AdvogadoSearchResponse } from '../models/dtos/AdvogadoSearchResponse.model';
import { ProcessoGetResponse } from '../models/dtos/ProcessoSearchResponse.model';
import useGrupoUsuarioService from '../services/grupoUsuario.service';
import { ApiResponse } from './types/ApiResponse';

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';

  const i = +Math.floor(Math.log(bytes) / Math.log(1024));

  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

export function deepCopy<T>(data: T): T {
  return JSON.parse(JSON.stringify(data)) as T;
}

export function compararArrays<T>(array1: T[], array2: T[]) {
  const array2Sorted = array2.slice().sort();

  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every((value, index) => {
        return value === array2Sorted[index];
      })
  );
}

export const tolocalISOString = (date: Date) => {
  const tzOffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzOffset).toISOString();
};

function buildFormData(formData: FormData, data: any, parentKey?: any) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    if (value instanceof Date) {
      formData.append(parentKey, tolocalISOString(value));
    } else {
      formData.append(parentKey, value);
    }
  }
}

export function objectToFormData<T>(data: T) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export function objectToFormDataProcesso(data: ProcessoFormModel) {
  const formData = new FormData();
  formData.append('clienteId', data.clienteId!);
  formData.append('responsavelId', data.responsavelId!);
  formData.append('numero', data.numero);

  let index = 0;

  data.documentosProcesso &&
    data.documentosProcesso.forEach((element) => {
      formData.append(`documentosProcesso[${index}].tipo`, element.tipo.toString());
      formData.append(`documentosProcesso[${index++}].arquivo`, element.arquivo);
    });

  return formData;
}

export function clone(input: any) {
  if (input == null || typeof input != 'object') return input;

  const output: any = Array.isArray(input) ? [] : {};

  for (const key of Object.keys(input)) {
    output[key] = clone(input[key]);
  }

  return output;
}

export function repetirJsx(n: number, callbackfn: (index: number) => JSX.Element) {
  return [...Array(n)].map((a, index) => callbackfn(index));
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any);
}

interface DateStringOptions {
  hasTime?: boolean;
  hourMinuteOnly?: boolean;
}

export function toBRDateString(date: Date, options?: DateStringOptions) {
  let dateFormat = buildDateFormat(options);

  if (isValidDate(date)) {
    return format(date, dateFormat);
  } else {
    return date;
  }
}

export default function toBRCurrency(value: number) {
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

  return `${amount}`;
}

export function fromBRDateString(date: string, options?: DateStringOptions) {
  let dateFormat = buildDateFormat(options);

  try {
    if (date) {
      const result = parse(date, dateFormat, new Date());
      return result;
    } else {
      return date;
    }
  } catch (err) {
    return date;
  }
}

function buildDateFormat(options: DateStringOptions | undefined) {
  let dateFormat = 'dd/MM/yyyy';

  if (options?.hasTime) {
    if (options.hourMinuteOnly) {
      dateFormat = 'dd/MM/yyyy HH:mm';
    } else {
      dateFormat = 'dd/MM/yyyy HH:mm:ss';
    }
  }
  return dateFormat;
}

export function blobAsURL(blob?: File) {
  let url = '';

  if (blob) {
    url = window.URL.createObjectURL(blob);
  }

  return url;
}

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function transformarSearchResponseEmProcessoModel(
  data: ProcessoGetResponse,
  advogadoResponsavel: AdvogadoSearchResponse,
) {
  const documentosData: DocumentoProcesso[] = [];
  data.documentosProcesso.forEach((doc) => {
    documentosData.push({ id: doc.id, tipo: doc.tipo, arquivo: doc.nomeArquivoOriginal });
  });

  const formDataModel: ProcessoMultiFormModel = {
    processo: [
      {
        id: data.id,
        clienteId: data.clientes[0] as unknown as string,
        numero: data.numero,
        responsavelId: advogadoResponsavel as unknown as string,
        documentosProcesso: documentosData,
      },
    ],
  };
  return formDataModel;
}

export function TransformarModeloAgenda(props: ApiResponse<any>) {
  const newModel = {
    data: props.data.compromissos,
    page: props.data.page,
    totalCount: props.data.total,
  };
  return newModel;
}

export function TransformarModeloNps(props: ApiResponse<any>) {
  const newModel = {
    data: props.data.respostas,
    page: props.data.page,
    totalCount: props.data.total,
  };
  return newModel;
}
export function TransformarModeloFinanceiro(props: ApiResponse<any>) {
  const newModel = {
    data: props.data.lancamentos,
    page: props.data.page,
    totalCount: props.data.total,
  };
  return newModel;
}
//TODO: Unificar essas functions ^
export function insertDecimal(num: number) {
  return (num / 10).toFixed(2);
}

interface useOnScreenProps {
  current: any;
}

export function useOnScreen(ref: useOnScreenProps) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

export async function IsAdvogado() {
  const { obterTodosGrupoUsuarios } = useGrupoUsuarioService();
  try {
    const userGroups = await obterTodosGrupoUsuarios();
    const gestorData = userGroups.data.find((data) => data.nome === 'Gestor');
    if (gestorData) {
      return gestorData.id;
    } else {
      throw new Error('Advogado não encontrado');
    }
  } catch (error) {
    console.log(error);
    throw new Error('Erro ao obter os grupos de usuário');
  }
}
