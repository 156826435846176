import { OptGrid, OptGridColumn, OptGridRef, OptLayoutProvider } from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ProcessoSearchResponse } from '../../../models/dtos/ProcessoSearchResponse.model';
import { Routes } from '../../../routes';
import Theme from '../../../shared/theme';

interface DocumentosGridProps {
  carregar: ProcessoSearchResponse[];
}
export const GridProcessosConsulta = forwardRef<OptGridRef | undefined, DocumentosGridProps>(({ carregar }, ref) => {
  const { documentoId } = useParams<{ documentoId: string }>();

  const columns: OptGridColumn<ProcessoSearchResponse>[] = [
    {
      title: 'id',
      field: 'id',
      hidden: true,
    },
    {
      title: 'Processos do Cliente',
      field: 'numero',
      align: 'center',
    },
  ];
  const history = useHistory();
  return (
    <>
      <OptLayoutProvider theme={Theme().insideTheme}>
        <OptGrid
          title=""
          columns={columns}
          data={carregar}
          onRowClick={(rowData) => {
            history.push(Routes.Documentos.Processos(documentoId, rowData.id));
          }}
          headerTitlePosition={'center'}
          ref={ref as any}
        />
      </OptLayoutProvider>
    </>
  );
});
