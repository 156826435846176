import * as Yup from 'yup';

export interface Financeiro {
  id: string;
  clienteId: string | null;
  valorOriginal: string | number;
  descricao?: string;
  processoId?: string;
  formaDePagamento?: string;
  parcelas: string;
  dataVencimento: Date;
}
export type FinanceiroFormModel = Partial<Financeiro>;

export const FINANCEIRO_DEFAULT: FinanceiroFormModel = {
  clienteId: '',
  valorOriginal: '',
  id: '',
  descricao: '',
  processoId: '',
  dataVencimento: new Date(),
};

export const FinanceiroFormModelSchema: Yup.SchemaOf<FinanceiroFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  clienteId: Yup.mixed().required().typeError('Campo obrigatório'),
  valorOriginal: Yup.mixed().required('Campo obrigatório').typeError('Campo obrigatório'),
  descricao: Yup.string().notRequired(),
  processoId: Yup.string().notRequired(),
  dataVencimento: Yup.date().required('Campo obrigatório').typeError('Data inválida'),
  formaDePagamento: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  parcelas: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
});

