import { Box, Grid, Typography } from '@mui/material';
import { ProcessoGetResponse } from '../../models/dtos/ProcessoSearchResponse.model';
import { Colors } from '../../shared/colors';

interface Props {
  data: ProcessoGetResponse | null;
}

const InfoProcesso = ({ data }: Props) => {
  const inicio = new Date(data?.dataAjuizamento ? data.dataAjuizamento : '');

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid
        container
        item
        columnSpacing={'10vw'}
        px={2.5}
        py={1}
        bgcolor={Colors.gray10}
        display={'flex'}
        justifyContent={'flex-start'}>
        <Grid item xs={5}>
          <Box display={'flex'} gap={'10px'} justifyContent="flex-start">
            <Typography fontWeight={500}>Processo:</Typography>
            <Typography>{data?.numero ? data.numero : ''}</Typography>
          </Box>

          <Box display={'flex'} gap={'10px'} justifyContent="flex-start">
            <Typography fontWeight={500}>Ação:</Typography>
            <Typography>{data?.tipoAcao ? data.tipoAcao : ''}</Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="flex-start">
            <Typography fontWeight={500}>Valor da causa:</Typography>
            <Typography>
              {data?.valorCausa ? data.valorCausa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display={'flex'} gap={'10px'} justifyContent="flex-start">
            <Typography sx={{ whiteSpace: 'nowrap' }} fontWeight={500}>
              Órgão julgador:
            </Typography>
            <Typography>{data && data.orgaoJulgador ? data.orgaoJulgador.nome : ''}</Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="flex-start">
            <Typography fontWeight={500}>Data de distribuição:</Typography>
            <Typography>
              {inicio.toLocaleTimeString('pt-Br', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Typography>
          </Box>
          {/* <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Natureza:</Typography>
            <Typography>{data?.natureza}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3.8}>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Motivo da distribuição:</Typography>
            <Typography>{data?.motivoDistribuicao}</Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Data de ajuizamento:</Typography>
            <Typography>{data?.data?Ajuizamento.toLocaleDateString()}</Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Assunto principal:</Typography>
            <Typography>{data?.assuntoPrincipal}</Typography>
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoProcesso;
