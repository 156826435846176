import { Cliente, ClienteFormModel } from '../models/Cliente/Cliente.model';
import { ClientesSearchResponse } from '../models/dtos/ClientesSearchResponse.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useClienteService() {
  const { get, post, put, remove, gridSearch } = useApi();
  const baseApi = 'api/cliente';

  const obter = async (id: string) => {
    return get<ApiResponse<Cliente>>(`${baseApi}/${id}`);
  };

  const obterGridClientes = async (data: SearchRequest<{}>) => {
    return gridSearch<ClientesSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterTodosClientes = async () => {
    return get<Cliente>(`${baseApi}`);
  };

  const enviar = async (data: ClienteFormModel) => {
    return post(`${baseApi}`, data);
  };

  const alterar = async (id: string, data: ClienteFormModel) => {
    return put<Cliente>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<Cliente>(`${baseApi}/${id}`);
  };

  return { obter, enviar, alterar, remover, obterTodosClientes, obterGridClientes };
}
