import Theme from '../../shared/theme';
import { InfoCabecalhoStyled, StyledTypography } from './styles';

interface Props {
  titulo: string;
}

const InfoCabecalho = ({ titulo }: Props) => {
  const bgColor = Theme().insideTheme.light?.primary;
  return (
    <InfoCabecalhoStyled color={bgColor}>
      <StyledTypography>{titulo}</StyledTypography>
    </InfoCabecalhoStyled>
  );
};

export default InfoCabecalho;
