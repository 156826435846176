import useApi from '../shared/utils/api';
import { ContatoFormModel } from './../models/Contato/Contato.model';

export default function useContatoService() {
  const { post } = useApi();
  const baseApi = 'api/contato';

  const enviar = async (data: ContatoFormModel) => {
    return post(`${baseApi}`, data);
  };

  return { enviar };
}
