import { Box } from '@mui/system';
import { Colors } from '../../shared/colors';

interface StatusAndamentoProps {
  status: boolean;
}

export function StatusAndamento(props: StatusAndamentoProps) {
  return (
    <>
      {!props.status ? (
        <Box sx={{ color: Colors.gray6, fontWeight: '600' }}>• Não traduzido</Box>
      ) : (
        <Box sx={{ color: 'lightgreen', fontWeight: '600' }}>• Traduzido</Box>
      )}
    </>
  );
}
