import { PerguntasFrequentesSearchResponse } from '../models/dtos/PerguntasFrequentesSearchResponse.model';
import {
  PerguntasFrequentes,
  PerguntasFrequentesFormModel,
} from '../models/PerguntasFrequentes/PerguntasFrequentes.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function usePerguntasFrequentesService() {
  const { get, post, put, remove, gridSearch } = useApi();

  const baseApi = 'api/pergunta-frequente';

  const obter = async (id: string) => {
    return get<ApiResponse<PerguntasFrequentes>>(`${baseApi}/${id}`);
  };

  const obterGridPerguntasFrequentes = async (data: SearchRequest<{}>) => {
    return gridSearch<PerguntasFrequentesSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterTodosPerguntasFrequentes = async () => {
    return get<PerguntasFrequentes>(`${baseApi}`);
  };

  const enviar = async (data: PerguntasFrequentesFormModel) => {
    return post(`${baseApi}`, data);
  };

  const alterar = async (id: string, data: PerguntasFrequentesFormModel) => {
    return put<PerguntasFrequentes>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<PerguntasFrequentes>(`${baseApi}/${id}`);
  };

  return { obter, enviar, alterar, remover, obterTodosPerguntasFrequentes, obterGridPerguntasFrequentes };
}
