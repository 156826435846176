import { mdiDelete } from '@mdi/js';
import { OptGrid, OptGridColumn, OptGridOptions, OptGridRef } from '@optsol/react';
import { forwardRef } from 'react';
import { UsuarioSearchResponse } from '../../models/dtos/UsuarioSearchResponse.model';

interface GridProps {
  carregar: UsuarioSearchResponse[];
  removerCliente: (id: string) => void;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const AssinaturaFormGrid = forwardRef<OptGridRef | undefined, GridProps>(({ carregar, removerCliente }, ref) => {
  const columns: OptGridColumn<UsuarioSearchResponse>[] = [
    {
      title: 'id',
      field: 'id',
      hidden: true,
    },
    {
      title: 'Nome',
      field: 'nome',
      render: (data: UsuarioSearchResponse) => {
        return <>{data.nome}</>;
      },
    },
  ];

  return (
    <>
      <OptGrid
        title=""
        ref={ref as any}
        data={carregar}
        options={options}
        columns={columns}
        headerTitlePosition="start"
        actionsPosition={'end'}
        actions={[
          (rowData) => ({
            icon: { path: mdiDelete },
            tooltip: 'Deletar',
            onClick: () => removerCliente(rowData.id),
            disabled: false,
          }),
        ]}
      />
    </>
  );
});
