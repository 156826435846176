import { Grid } from '@mui/material';
import styled from 'styled-components';
export const styledBox = styled(Grid)``;

export const styledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  opacity: 0;
  align-items: center;
  justify-content: center;

  background-color: ${(p) => p.color};
  &:hover {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease-in-out 0s;
    transition: opacity 0.25s ease-in-out 0s;
    fill: rebeccapurple;
  }
  ${styledBox}:hover & {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease-in-out 0s;
    transition: opacity 0.25s ease-in-out 0s;
    fill: rebeccapurple;
  }
`;
