import * as Yup from 'yup';
import { validarCPF } from '../../shared/utils/functions';

export interface Advogado {
  cpf: string;
  email: string;
  id?: string;
  nome: string;
  oab: string;
  ufOab: string;
  telefone: string;
  escritorioId?: string;
}
export type AdvogadoFormModel = Partial<Advogado>;

export const ADVOGADO_DEFAULT: AdvogadoFormModel = {
  nome: '',
  email: '',
  cpf: '',
  telefone: '',
  id: '',
  oab: '',
  escritorioId: '',
  ufOab: '',
};

// export const TelefoneSchema: Yup.SchemaOf<Telefone> = Yup.object().shape({
//   fixo: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),

//   celular: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
// });

export const AdvogadoFormModelSchema: Yup.SchemaOf<AdvogadoFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  nome: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório').typeError('Campo obrigatório'),
  // endereco: EnderecoFormModelSchema.required().typeError('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório')
    .test('CPF Test', 'CPF Inválido', (value) => !value || (!!value && validarCPF(value))),
  oab: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  ufOab: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  escritorioId: Yup.string().notRequired(),
});
