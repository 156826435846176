import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, FieldErrors, get, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import { ErrorMessage, FormNumberFormat, FormSelect, FormTextField } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { Advogado, AdvogadoFormModel, AdvogadoFormModelSchema } from '../../models/Advogado/Advogado.model';
import useAdvogadoService from '../../services/advogado.service';
import { ufArray } from '../../shared/mock';
import Theme from '../../shared/theme';

interface AdvogadoFormProps {
  defaultAdvogado: Partial<Advogado>;
  toggleDrawer: () => void;
  refreshGrid(): void;
}

export function AdvogadoForm({ defaultAdvogado, toggleDrawer, refreshGrid }: AdvogadoFormProps) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);

  const { handleSubmit, control, getValues, formState, watch } = useForm<AdvogadoFormModel>({
    defaultValues: defaultAdvogado,
    resolver: yupResolver(AdvogadoFormModelSchema),
  });

  const idAdvogado = getValues('id');
  const monitorarTelefone = watch('telefone');

  const { enviar, alterar } = useAdvogadoService();

  const onSubmit = async (data: AdvogadoFormModel) => {
    try {
      setBotaoDesabilitado(true);
      await enviar(data);
      toggleDrawer();
      refreshGrid();
    } catch {
      console.log('error');
    } finally {
      setBotaoDesabilitado(false);
    }
  };

  const onEdit = async (data: AdvogadoFormModel) => {
    if (idAdvogado) {
      try {
        setBotaoDesabilitado(true);
        await alterar(idAdvogado, data);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };

  const onErrors = (data: FieldErrors<AdvogadoFormModel>) => {
    console.log('onErros', data);
  };

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
        <Grid item xs={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField onChange={onChange} value={value ?? ''} label="Nome" variant="outlined" fullWidth />
              </>
            )}
          />
          <ErrorMessage error={get(formState.errors, 'nome')} />
        </Grid>
        <Grid item xs={6}>
          <FormNumberFormat
            control={control}
            type="text"
            format="###.###.###-##"
            disabled={idAdvogado ? true : false}
            name="cpf"
            label="CPF"
            placeholder="xxx.xxx.xxx-xx"
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect control={control} name="ufOab" label="UF OAB" placeholder="UF OAB">
            {ufArray.map((props, i) => {
              return (
                <MenuItem key={i} value={props}>
                  {props}
                </MenuItem>
              );
            })}
          </FormSelect>
        </Grid>
        <Grid item xs={6}>
          <FormNumberFormat control={control} format={'######'} name="oab" label="Número OAB " placeholder="000000" />
        </Grid>
        <Grid item xs={6}>
          <FormNumberFormat
            control={control}
            format={String(monitorarTelefone).length === 11 ? '(##) # ####-####' : '(##) ####-#####'}
            name="telefone"
            label="Telefone "
            placeholder="(00) 0000-0000"
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            control={control}
            disabled={idAdvogado ? true : false}
            name="email"
            label="E-mail"
            placeholder="email@mail.com"
          />
        </Grid>
      </Grid>
      <SubmitWrapper>
        <ButtonMui
          $backgroundColor={Theme().insideTheme.light?.primary}
          onClick={handleSubmit(idAdvogado ? onEdit : onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}

