import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import logo from '../../shared/Imagens/PngLogo.png';

import { Box } from '@mui/system';
import { FormNumberFormat, FormTextField } from '../../components/Form';
import { Contato, ContatoFormModel, ContatoFormModelSchema, CONTATO_DEFAULT } from '../../models/Contato/Contato.model';
import useContatoService from '../../services/contato.service';

export const ContatoForm = () => {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, reset, watch } = useForm<Contato>({
    defaultValues: CONTATO_DEFAULT,
    resolver: yupResolver(ContatoFormModelSchema),
  });
  const { enviar } = useContatoService();

  const telefone = watch('telefone');
  const telefoneLenght = telefone ? telefone.replace(/[^\d]+/g, '').length : 0;

  async function onSubmit(data: ContatoFormModel) {
    setLoading(true);
    try {
      enviar(data);
      reset(CONTATO_DEFAULT);
      enqueueSnackbar('Mensagem enviada com sucesso', { variant: 'success' });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }
  const onErrors = (data: FieldErrors<ContatoFormModel>) => {
    console.log('onErros', data);
  };
  return (
    <Grid container height="100vh" overflow="hidden">
      <Grid
        container
        item
        xs={12}
        sm={6}
        display="flex"
        flexDirection="column"
        gap={3}
        alignItems="center"
        justifyContent="center"
        paddingX={{ xs: 1, sm: 15 }}>
        <Box mb={2} sx={{ display: { md: 'none' } }}>
          <img src={logo} alt="logo" />
        </Box>
        <Grid item width={1}>
          <Typography variant="h5">Formulário de contato</Typography>
        </Grid>
        <Grid item width={1}>
          <FormTextField control={control} name="nomeCompleto" label="Nome" placeholder="Nome" color="warning" />
        </Grid>
        <Grid item width={1}>
          <FormNumberFormat
            format={telefoneLenght < 11 ? '(##) ####-#####' : '(##) # ####-####'}
            control={control}
            name="telefone"
            label="Telefone"
            placeholder="Telefone"
            color="warning"
          />
        </Grid>
        <Grid item width={1}>
          <FormTextField control={control} name="email" label="E-mail" placeholder="E-mail" color="warning" />
        </Grid>
        <Grid item width={1}>
          <FormTextField
            color="warning"
            control={control}
            name="descricaoContato"
            label="Mensagem"
            placeholder="Mensagem"
            multiline
            minRows={3}
          />
        </Grid>
        <ButtonMui fullWidth $backgroundColor="#E57602" onClick={handleSubmit(onSubmit, onErrors)} loading={loading}>
          Enviar mensagem
        </ButtonMui>
      </Grid>
      <Grid item xs={6} md={6} sx={{ display: { xs: 'none', md: 'flex' } }}>
        <img
          className="imgAcesso"
          src="https://devacessojurisstg.blob.core.windows.net/geral/logo.png"
          alt="AcessoJuris"
        />
      </Grid>
    </Grid>
  );
};
