import { Box, Switch, SwitchProps as DefaultSwitchProps, Typography } from '@mui/material';
import { Controller, ControllerProps, FieldError, FieldValues, get, useFormState } from 'react-hook-form';
import Theme from '../../shared/theme';
import { ErrorMessage } from './ErrorMessage';

export interface SwitchProps<T extends DefaultSwitchProps>
  extends Omit<ControllerProps<T>, 'render'>,
    Omit<DefaultSwitchProps, 'defaultValue' | 'name'> {
  errors?: FieldError | string;
  label: string;
}

export function FormSwitch<T extends FieldValues>({ control, errors, name, label, ...switchProps }: SwitchProps<T>) {
  const formState = useFormState<T>({ control });
  const error = get(formState.errors, name);
  const switchColor = Theme().insideTheme.light?.primary;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Box display="flex" alignItems="center">
              <Typography color={switchColor}>{label}</Typography>
              <Switch {...switchProps} value={value} onChange={onChange} checked={value} />
            </Box>
          );
        }}
      />
      <ErrorMessage error={error} />
    </>
  );
}
