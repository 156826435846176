import { mdiDelete, mdiDownload } from '@mdi/js';
import { OptGrid, OptGridColumn, OptGridRef } from '@optsol/react';
import { forwardRef } from 'react';
import { Documento } from '../../../models/Documento/Documento.model';

interface DocumentosGridProps {
  data: Documento[];
  baixarDocumento: (id: string, documentoNome: string) => void;
  removerDocumento: (id: string) => void;
}
export const GridResumo = forwardRef<OptGridRef | undefined, DocumentosGridProps>(
  ({ data, baixarDocumento, removerDocumento }, ref) => {
    const columns: OptGridColumn<Documento>[] = [
      {
        title: 'Id',
        field: 'id',
        hidden: true,
      },
      {
        title: 'Documentos',
        field: 'nomeArquivoOriginal',
      },
    ];

    return (
      <>
        <OptGrid
          title=""
          columns={columns}
          data={data}
          ref={ref as any}
          actions={[
            (rowData) => ({
              icon: { path: mdiDownload },
              tooltip: 'Baixar documento',
              onClick: () => {
                baixarDocumento(rowData.id, rowData.nomeArquivoOriginal);
              },
              disabled: false,
            }),
            (rowData) => ({
              icon: { path: mdiDelete },
              tooltip: 'Deletar documento',
              onClick: () => removerDocumento(rowData.id),
              disabled: false,
            }),
          ]}
          actionsPosition={'end'}
        />
      </>
    );
  },
);
