import { yupResolver } from '@hookform/resolvers/yup';
import { mdiPen } from '@mdi/js';
import Icon from '@mdi/react';
import { CircularProgress, Divider, Grid, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import { OptLayoutProvider } from '@optsol/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import { Controller, FieldErrors, get, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import ButtonMui from '../../components/Button/ButtonMui';
import { ErrorMessage, FormNumberFormat, FormSelect, FormTextField } from '../../components/Form';
import { AutoComplete } from '../../components/Form/AutoComplete';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { FormSwitch } from '../../components/Form/FormSwitch';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { Escritorio, EscritorioFormModel, EscritorioFormModelSchema } from '../../models/Escritorio/Escritorio.model';
import useEscritorioService from '../../services/escritorio.service';
import { Colors } from '../../shared/colors';
import Theme from '../../shared/theme';
import * as S from './styles';

interface EscritorioFormProps {
  defaultEscritorio: Partial<Escritorio>;
  onImageUpload?: (imageUrl: string) => any;
  label?: string;
}

export function ConfiguracoesForm({ defaultEscritorio, onImageUpload, label }: EscritorioFormProps) {
  const {
    state: { userInfo },
  } = useAuthenticationContext();

  const [filePreview, setFilePreview] = useState<string | undefined>(undefined);
  const [tabValue, setTabValue] = useState<Number>(1);
  const [numeroContaFormat, setNumeroContaFormat] = useState('');

  const [color, setColor] = useColor(
    'hex',
    userInfo.data?.escritorio.cor ? userInfo.data?.escritorio.cor : Colors.primary,
  );

  const bancos = [
    { value: 'Banco do Brasil', nome: 'Banco do Brasil' },
    { value: 'Banco Itaú', nome: 'Banco Itaú' },
    { value: 'Bradesco', nome: 'Bradesco' },
    { value: 'Banco Santander', nome: 'Banco Santander' },
    { value: 'C6 Bank', nome: 'C6 Bank' },
    { value: 'Caixa econômica', nome: 'Caixa econômica' },
    { value: 'Inter', nome: 'Inter' },
    { value: 'Nubank', nome: 'Nubank' },
    { value: 'Neon', nome: 'Neon' },
    { value: 'Next', nome: 'Next' },
  ];

  const { alterar, enviarArquivo, alterarArquivo, obterArquivo } = useEscritorioService();

  const { setValue, handleSubmit, control, getValues, formState, watch } = useForm<EscritorioFormModel>({
    defaultValues: defaultEscritorio,
    resolver: yupResolver(EscritorioFormModelSchema),
  });

  const numeroConta = watch(`dadosFinanceiros.numeroConta`);
  const numeroContaLenght = numeroConta ? numeroConta.replace(/[^\d]+/g, '').length : 0;

  const idEscritorio = getValues('id');

  const onEdit = async (data: EscritorioFormModel) => {
    if (idEscritorio) {
      try {
        await alterar(idEscritorio, data);
        window.location.reload();
      } catch {
        console.log('error');
      }
    }
  };

  const onErrors = (data: FieldErrors<EscritorioFormModel>) => {
    console.log('onErros', data);
  };

  const checkCEP = (e: any) => {
    const cep = e.target.value.replace(/\D/g, '');
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue('endereco', {
          bairro: data.bairro,
          rua: data.logradouro,
          cidade: data.localidade,
          cep: data.cep,
          estado: data.uf,
          complemento: data.complemento,
        });
      });
  };

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files![0];

    if (file && userInfo.data) {
      const reader = new FileReader();
      if (userInfo.data.escritorio.imagem) {
        alterarArquivo(userInfo.data.escritorio.id, file);
      } else {
        enviarArquivo(userInfo.data.escritorio.id, file);
      }

      reader.addEventListener('load', async (e) => {
        setFilePreview(String(e.target?.result));
      });

      reader.readAsDataURL(file);
    }
  }

  async function HandleLogo() {
    if (userInfo.data && userInfo.data.escritorio.imagem) {
      try {
        const logoAPI = await obterArquivo(userInfo.data.escritorio.id);
        const myImage = URL.createObjectURL(logoAPI);
        setFilePreview(myImage);
      } catch {}
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const formatNumeroConta = () => {
    if (numeroContaLenght < 6) {
      setNumeroContaFormat('####-##');
    } else if (numeroContaLenght < 7) {
      setNumeroContaFormat('#####-##');
    } else if (numeroContaLenght < 8) {
      setNumeroContaFormat('######-##');
    } else if (numeroContaLenght < 9) {
      setNumeroContaFormat('#######-##');
    } else if (numeroContaLenght < 10) {
      setNumeroContaFormat('########-##');
    } else if (numeroContaLenght < 11) {
      setNumeroContaFormat('#########-##');
    } else {
      setNumeroContaFormat('###########-#');
    }
  };

  useEffect(() => {
    formatNumeroConta();
  }, [numeroContaLenght]);

  useEffect(() => {
    HandleLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.data]);

  return (
    <OptLayoutProvider theme={Theme().insideTheme}>
      <Grid container sx={{ display: 'flex', flexDirection: 'row' }} height={1}>
        <Grid item xs={7} columnSpacing={5} px={2.5} pt={2.5}>
          <Grid item mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              sx={{ width: '100%', padding: '0 20px' }}
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example">
              <Tab sx={{ width: '33%' }} label="Dados da empresa" value={1} />
              <Tab sx={{ width: '33%' }} label="Informações de endereço" value={2} />
              <Tab sx={{ width: '33%' }} label="Dados bancários" value={3} />
            </Tabs>
          </Grid>
          {tabValue === 1 && (
            <Grid container item rowSpacing={3} columnSpacing={5}>
              <Grid item xs={6}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        disabled
                        onChange={onChange}
                        value={value ?? ''}
                        label="Nome do Escritório"
                        variant="outlined"
                        fullWidth
                      />
                    </>
                  )}
                />
                <ErrorMessage error={get(formState.errors, 'nomeEmpresa')} />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="cnpj"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <NumberFormat
                        disabled
                        format="##.###.###/####-##"
                        fullWidth
                        customInput={TextField}
                        value={value ?? ''}
                        label="CNPJ"
                        onValueChange={(values, source) => {
                          if (source.event) {
                            const { event } = source;
                            const { floatValue: value } = values;
                            onChange({ ...event, target: { ...event.target, value } });
                          }
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSwitch
                  label="Utilizar tradução automática de andamentos gerada por IA?"
                  name="aceitaTraducaoAndamentoAutomatica"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="bold"> Informações de contato</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormNumberFormat
                  control={control}
                  format="(##) # ####-####"
                  name="telefone.celular"
                  label="Celular"
                  placeholder="(00) 0 0000-0000"
                />
              </Grid>
              <Grid item xs={6}>
                <FormNumberFormat
                  control={control}
                  format="(##) ####-####"
                  name="telefone.fixo"
                  label="Telefone fixo"
                  placeholder="(00) 0000-0000"
                />
              </Grid>
              <Grid item xs={6}>
                <FormNumberFormat
                  control={control}
                  format="(##) # ####-####"
                  name="whatsapp"
                  label="Whatsapp"
                  placeholder="(00) 0 0000-0000"
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField control={control} name="email" label="E-mail" placeholder="email@mail.com" />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="bold"> Social</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormTextField control={control} name="redesSociais.facebook" label="Facebook" placeholder="Facebook" />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="redesSociais.instagram"
                  label="Instagram"
                  placeholder="Instagram"
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField control={control} name="redesSociais.linkedin" label="Linkedin" placeholder="Linkedin" />
              </Grid>
              <Grid item xs={6}>
                <FormTextField control={control} name="site" label="Site" placeholder="Site" />
              </Grid>
            </Grid>
          )}
          {tabValue === 2 && (
            <Grid container item rowSpacing={3} columnSpacing={5}>
              <Grid item xs={6}>
                <FormNumberFormat
                  control={control}
                  format="#####-###"
                  name="endereco.cep"
                  label="CEP"
                  placeholder="CEP"
                  onBlur={checkCEP}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField control={control} name="endereco.estado" label="Estado" placeholder="Estado" />
              </Grid>
              <Grid item xs={6}>
                <FormTextField control={control} name="endereco.cidade" label="Cidade" placeholder="Cidade" />
              </Grid>
              <Grid item xs={6}>
                <FormTextField control={control} name="endereco.bairro" label="Bairro" placeholder="Bairro" />
              </Grid>
              <Grid item xs={6}>
                <FormTextField control={control} name="endereco.rua" label="Rua" />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="endereco.complemento"
                  label="Complemento"
                  placeholder="Complemento"
                />
              </Grid>
            </Grid>
          )}
          {tabValue === 3 && (
            <Grid container item rowSpacing={3} columnSpacing={5}>
              <Grid item xs={6}>
                <AutoComplete
                  options={bancos}
                  control={control}
                  label="Nome do banco"
                  placeholder="Nome do banco"
                  name={`dadosFinanceiros.nomeBanco`}
                />
                <ErrorMessage error={get(formState.errors, `dadosFinanceiros.nomeBanco`)} />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  control={control}
                  name="dadosFinanceiros.tipoConta"
                  label="Tipo da conta"
                  placeholder="Tipo da conta">
                  <MenuItem value={1}>Conta corrente</MenuItem>
                  <MenuItem value={2}>Conta de poupança</MenuItem>
                </FormSelect>
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="dadosFinanceiros.nomeTitular"
                  label="Nome do titular"
                  placeholder="Nome do titular"
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="dadosFinanceiros.chavePix"
                  label="Chave PIX"
                  placeholder="Chave PIX"
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dadosFinanceiros.agencia"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <NumberFormat
                        format="####"
                        fullWidth
                        customInput={TextField}
                        value={value ?? ''}
                        label="Agência"
                        onValueChange={(values, source) => {
                          if (source.event) {
                            const { event } = source;
                            const { floatValue: value } = values;
                            onChange({ ...event, target: { ...event.target, value } });
                          }
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormNumberFormat
                  control={control}
                  format={numeroContaFormat}
                  name="dadosFinanceiros.numeroConta"
                  label="Número da conta"
                  placeholder="Número da conta"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" variant="fullWidth" />
        </Grid>
        <Grid
          item
          xs={4}
          rowSpacing={3}
          columnSpacing={5}
          px={2.5}
          display="flex"
          flexDirection="column"
          justifyContent="space-between">
          <S.Wrapper>
            <S.titleWrapper> Customização do sistema</S.titleWrapper>
            <Controller
              name="cor"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {/* //TODO: width esta travado na lib */}
                  <ColorPicker
                    width={420}
                    height={180}
                    color={color}
                    onChange={setColor}
                    onChangeComplete={(e) => onChange(e.hex)}
                    hideHSV
                    hideRGB
                  />
                </>
              )}
            />
            {filePreview ? (
              <S.ImageWrapper color={userInfo.data && userInfo.data.escritorio.cor ? 'white' : Colors.primaryContrast}>
                <S.ImagePreviewWrapper>
                  <S.ImagePreview preview={filePreview}></S.ImagePreview>
                </S.ImagePreviewWrapper>
                <S.LabelWrapper
                  color={userInfo.data && userInfo.data.escritorio.cor ? 'white' : Colors.primaryContrast}>
                  <S.Label>
                    {label}
                    <S.Input
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/webp, image/jpg"
                      onChange={handleChange}
                    />
                    <Icon size={'20px'} path={mdiPen} />
                  </S.Label>
                </S.LabelWrapper>
              </S.ImageWrapper>
            ) : (
              <>
                <div>
                  <S.Label color={Colors.white} shadow={'rgba(0, 0, 0, 0.35) 0px 5px 15px'}>
                    <Icon size={'20px'} path={mdiPen} color={Colors.gray} />
                    {label}
                    <S.Input
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/webp, image/jpg"
                      onChange={handleChange}
                    />
                  </S.Label>
                </div>
              </>
            )}
          </S.Wrapper>
          <SubmitWrapper>
            <ButtonMui
              $backgroundColor={Theme().insideTheme.light?.primary}
              onClick={handleSubmit(onEdit, onErrors)}
              loadingIndicator={<CircularProgress color={'warning'} size={16} />}
              loading={false}>
              Salvar
            </ButtonMui>
          </SubmitWrapper>
        </Grid>
      </Grid>
    </OptLayoutProvider>
  );
}
