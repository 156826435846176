import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptLayoutProvider,
} from '@optsol/react';
import { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { AndamentoSearchResponse } from '../../models/dtos/AndamentoSearchResponse';
import { Routes } from '../../routes';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    andamentoSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<AndamentoSearchResponse>>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridAndamentos = forwardRef<OptGridRef | undefined, GridProps>(({ carregar }, ref) => {
  const columns: OptGridColumn<AndamentoSearchResponse>[] = [
    {
      title: 'id',
      field: 'id',
      hidden: true,
    },

    {
      title: 'Nº Processo',
      field: 'numero',
      align: 'center',
    },
    {
      title: 'Tribunal',
      field: 'tribunal',
      align: 'center',
    },
    {
      title: 'Parte(Cliente)',
      field: 'clientes',
      align: 'center',
    },
    {
      title: 'Último andamento',
      field: 'data',
      align: 'center',

      render: (data) => {
        const dataFormat = new Date(data.data);
        return (
          <>
            {dataFormat.toLocaleTimeString('pt-Br', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        );
      },
    },
  ];
  const history = useHistory();

  return (
    <>
      <OptLayoutProvider theme={Theme().insideTheme}>
        <OptGrid
          title=""
          ref={ref as any}
          data={carregar}
          options={options}
          columns={columns}
          headerTitlePosition="center"
          actionsPosition={'end'}
          onRowClick={(rowData) => {
            history.push(Routes.AndamentosGerais.AndamentoDetalhado(rowData.id));
          }}
        />
      </OptLayoutProvider>
    </>
  );
});
