import { Box } from '@mui/material';
import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { ClientForm } from '../../features/ClientForm/ClientForm';
import { CLIENTE_DEFAULT } from '../../models/Cliente/Cliente.model';
import useClienteService from '../../services/cliente.service';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridClientes } from './GridClientes';

export const Clientes = () => {
  const [clientSearch, setClientSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [defaultClient, setDefaultClient] = useState(CLIENTE_DEFAULT);

  const { obterGridClientes, remover, obter } = useClienteService();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const carregar = (
    query: OptGridRequest,
    ClientSearchRequest: PaginatedSearchRequest = { termoBuscado: clientSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: ClientSearchRequest,
    };
    return obterGridClientes(request);
  };

  async function removerCliente(clientId: string) {
    try {
      await remover(clientId);
      ref.current?.refresh();
    } catch {
      console.log('error');
    }
  }
  function criarCliente() {
    setDefaultClient(CLIENTE_DEFAULT);
    toggleDrawer();
  }

  async function editarCliente(clientId: string) {
    try {
      const clientData = await obter(clientId);
      clientData.data.dataNascimento =
        clientData.data.dataNascimento && new Date(clientData.data.dataNascimento.toString());
      setDefaultClient(clientData.data);
      toggleDrawer();
    } catch {}
  }

  function ProcurarCliente(data?: string) {
    setClientSearch(data ?? '');
  }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [clientSearch]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Clientes/Contatos" />
      <OptLayoutProvider theme={Theme().insideTheme}>
        <GeralWrapper>
          <HeaderContent titulo="Contatos" height="100%">
            {/*  <ButtonWrapper pb="1.4rem">
              <IconButton onClick={toggleDrawer} color />
            </ButtonWrapper> */}
            <Box></Box>
            <FormDrawer title={'Cadastro de novo cliente:'} isOpen={isOpen} toggleDrawer={criarCliente}>
              <ClientForm toggleDrawer={toggleDrawer} refreshGrid={refreshGrid} defaultClient={defaultClient} />
            </FormDrawer>
            <OptSearchField placeholder="Buscar por cliente" onSearch={ProcurarCliente} />
          </HeaderContent>
          <GridClientes carregar={carregar} ref={ref} removerCliente={removerCliente} editarCliente={editarCliente} />
        </GeralWrapper>
      </OptLayoutProvider>
    </OptSideLayoutContent>
  );
};
