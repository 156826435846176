import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { OptLayoutProvider } from '@optsol/react';
import { ptBR } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';
import { AuthenticationProvider } from '../../contexts/authentication/authenticationContext';
import { Routes } from '../../routes';
import Theme from '../../shared/theme';
import { ContatoForm } from '../Contato/Contato';

const useStyles = makeStyles(() => ({
  success: { backgroundColor: 'purple' },
  error: { backgroundColor: 'blue' },
  warning: { backgroundColor: 'green' },
  info: { backgroundColor: 'yellow' },
}));

export const AppProviders = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();

  const location = window.location.pathname;

  if (location === Routes.Contato)
    return (
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantInfo: classes.info,
          variantWarning: classes.warning,
        }}>
        <ContatoForm />
      </SnackbarProvider>
    );
  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={4000}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantInfo: classes.info,
        variantWarning: classes.warning,
      }}>
      <AuthenticationProvider>
        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
          <OptLayoutProvider theme={Theme().theme}>{children}</OptLayoutProvider>
        </LocalizationProvider>
      </AuthenticationProvider>
    </SnackbarProvider>
  );
};
