import { Box } from '@mui/system';
import React from 'react';
import { Colors } from '../../shared/colors';

interface StatusTextPagamentosProps {
  statusDoPagamento: Number;
}

export function StatusTextPagamentos(props: StatusTextPagamentosProps) {
  return (
    <>
      {props.statusDoPagamento === 2 ? (
        <Box sx={{ color: Colors.red, fontWeight: '600' }}>• Vencido</Box>
      ) : props.statusDoPagamento === 1 ? (
        <Box sx={{ color: 'lightgreen', fontWeight: '600' }}>• Pago</Box>
      ) : props.statusDoPagamento === 3 ? (
        <Box sx={{ color: Colors.red, fontWeight: '600' }}>• Cancelado</Box>
      ) : (
        <Box sx={{ color: '#FCC413', fontWeight: '600' }}>• Em aberto</Box>
      )}
    </>
  );
}
