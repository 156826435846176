import { Endereco } from '../../models/Endereco.model';

export const validarCPF = (cpf?: string) => {
  if (!cpf) return false;
  // Remove tudo o que nao for numero
  cpf = cpf.replace(/[^\d]+/g, '');
  // Valida tamanho e todos os numeros iguais
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  // Retorna um array com todos os numeros
  const numeros = cpf.split('').map((el) => parseInt(el));
  // Calcular Soma dos digitos e multiplicar por 10
  const soma = (count: number) =>
    numeros.slice(0, count - 12).reduce((soma, el, index) => soma + el * (count - index), 0) * 10;
  // Pegar o resto por 11 e transforma 10 para 0
  const resto = (count: number) => (soma(count) % 11) % 10;
  return resto(10) === numeros[9] && resto(11) === numeros[10];
};

export function validarCNPJ(cnpj?: string): boolean {
  if (!cnpj) return false;

  cnpj = cnpj.replace(/[^\d]+/g, '').padStart(14, '0');

  if (cnpj === '' || cnpj.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false;
  }

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1))) {
    return false;
  }

  return true;
}

export function downloadBlob(blob: Blob, fileName: string) {
  if (!blob) return;

  // Create blob link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';

  link.setAttribute('download', fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode && link.parentNode.removeChild(link);
}

export function blobToFile({ theBlob, fileName }: any) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

type ViaCepEndereco = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
};

type ViaCepError = { erro: boolean };

export const useCEP = () => {
  const parseResponse = (response: ViaCepEndereco | ViaCepError): Endereco => {
    if ('erro' in response) {
      throw new Error('CEP inválido');
    }

    return {
      cep: response.cep,
      rua: response.logradouro,
      bairro: response.bairro,
      cidade: response.localidade,
      estado: response.uf,
    };
  };

  const buscar = async (cep?: string): Promise<Endereco | undefined> => {
    if (!cep || cep.replace(/\D/g, '').length !== 8) return;

    return fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then(parseResponse)
      .catch((error: Error) => {
        throw error;
      });
  };

  return { buscar };
};

type BrasilApiCNPJ = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  municipio: string;
  uf: string;
  nome_fantasia: string;
  ddd_telefone_1: string;
  email: string;
  razao_social: string;
};

export interface ApiCnpj {
  endereco: {
    cep: string;
    rua: string;
    bairro: string;
    cidade: string;
    estado: string;
    complemento: string;
  };
  razaoSocial: string;
  nomeEmpresa: string;
  telefone: string;
  email: string;
}

type BrasilApiCNPJError = { erro: boolean };

export const useCPNJ = () => {
  const parseResponse = (response: BrasilApiCNPJ | BrasilApiCNPJError) => {
    if ('erro' in response) {
      throw new Error('CEP inválido');
    }

    return {
      endereco: {
        cep: response.cep,
        rua: response.logradouro,
        bairro: response.bairro,
        cidade: response.municipio,
        estado: response.uf,
        complemento: response.complemento,
      },
      razaoSocial: response.razao_social,
      nomeEmpresa: response.nome_fantasia,
      telefone: response.ddd_telefone_1,
      email: response.email,
    };
  };

  const buscarCNPJ = async (cnpj?: string): Promise<ApiCnpj | undefined> => {
    const cnpjValido = validarCNPJ(cnpj);
    if (!cnpjValido) return;
    const cnpjNumbers = cnpj?.replace(/\D/g, '');

    return fetch(`https://brasilapi.com.br/api/cnpj/v1/${cnpjNumbers}`)
      .then((res) => res.json())
      .then(parseResponse)
      .catch((error: Error) => {
        throw error;
      });
  };

  return { buscarCNPJ };
};

export function removeDuplicates<T>(arr: T[]): T[] {
  const map = new Map<T, boolean>();
  const result = [];

  for (const item of arr) {
    if (!map.has(item)) {
      map.set(item, true);
      result.push(item);
    }
  }

  return result;
}

export function subtractThreeHoursFromDate(date: Date): Date {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();

  const newDate = new Date(year, month, day, hours - 3);
  return newDate;
}

export function setZeroHour(date: Date) {
  const zeroHour = new Date(date);
  zeroHour.setHours(0);
  zeroHour.setMinutes(0);
  zeroHour.setSeconds(0);
  zeroHour.setMilliseconds(0);
  return zeroHour;
}
