import { OptGridRef, OptGridRequest, OptLayoutProvider, OptSearchField, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { ButtonWrapper } from '../../components/Button/Button.styles';
import { IconButton } from '../../components/Button/IconButton';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import AssinaturaForm from '../../features/AssinaturaForm/AssinaturaForm';
import useAssinaturaSerice from '../../services/assinatura.service';
import Theme from '../../shared/theme';
import { PaginatedSearchRequest, SearchRequest } from '../../shared/utils/searchRequest';
import { GridAssinaturas } from './GridAssinaturas';

export const Assinaturas = () => {
  const [assinaturaSearch, setAssinaturaSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const { obterGridAssinaturas, remover } = useAssinaturaSerice();

  const carregar = (
    query: OptGridRequest,
    ClientSearchRequest: PaginatedSearchRequest = { termoBuscado: assinaturaSearch },
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: ClientSearchRequest,
    };
    return obterGridAssinaturas(request);
  };

  async function removerDocumento(id: string) {
    try {
      await remover(id);
      ref.current?.refresh();
    } catch {
      console.log('error');
    }
  }
  // function criarCliente() {
  //   setDefaultClient(CLIENTE_DEFAULT);
  //   toggleDrawer();
  // }

  // async function editarCliente(clientId: string) {
  //   try {
  //     const clientData = await obter(clientId);
  //     setDefaultClient(clientData.data);
  //     toggleDrawer();
  //   } catch {}
  // }

  // function ProcurarCliente(data?: string) {
  //   setClientSearch(data ?? '');
  // }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
  }
  function ProcurarAssinatura(data?: string) {
    setAssinaturaSearch(data ?? '');
  }
  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [assinaturaSearch]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Assinaturas" />
      <GeralWrapper>
        <OptLayoutProvider theme={Theme().insideTheme}>
          <HeaderContent titulo="Meus documentos" height="100%">
            <ButtonWrapper pb="1.4rem">
              <IconButton onClick={toggleDrawer} color />
            </ButtonWrapper>
            <FormDrawer title={'Cadastro de documento para assinatura:'} isOpen={isOpen} toggleDrawer={toggleDrawer}>
              <AssinaturaForm refreshGrid={refreshGrid} toggleDrawer={toggleDrawer} />
            </FormDrawer>
            <OptSearchField onSearch={ProcurarAssinatura} />
          </HeaderContent>
        </OptLayoutProvider>
        <GridAssinaturas ref={ref} carregar={carregar} removerCliente={removerDocumento} />
      </GeralWrapper>
    </OptSideLayoutContent>
  );
};

