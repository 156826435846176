import {
  mdiAccountCog,
  mdiAccountMultiple,
  mdiAccountTie,
  mdiCheckDecagram,
  mdiCurrencyUsd,
  mdiFolder,
  mdiHelpCircle,
  mdiOfficeBuilding,
  mdiPen,
  mdiViewGrid,
} from '@mdi/js';
import { OptMenuSection } from '@optsol/react';
import LogoEscritorio from '../../components/LogoEscritorio/LogoEscritorio';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { SectionsClaims } from '../../contexts/claims/sectionsClaims';
import { Routes } from '../../routes';

export const useSections = () => {
  const { hasAccess, state } = useAuthenticationContext();

  const sections: OptMenuSection[] = [
    {
      title: 'AcessoJuris',
      items: [{ icon: LogoEscritorio(), path: Routes.Configuracoes, title: '' }],
    },
  ];

  const protectedSections: OptMenuSection = { title: 'AcessoJurisProtected', items: [] };

  if (state.userInfo.data) {
    if (hasAccess(SectionsClaims.CLIENTE)) {
      sections.push(protectedSections);
    }
  }

  if (state.userInfo.data) {
    if (hasAccess(SectionsClaims.ADMIN)) {
      protectedSections.items.push(
        { icon: mdiViewGrid, path: Routes.VisaoGeral.Principal, title: 'Visão Geral' },
        { icon: mdiFolder, path: Routes.Documentos.Principal, title: 'Documentos' },
        { icon: mdiAccountMultiple, path: Routes.Clientes, title: 'Clientes / Contatos' },
        { icon: mdiPen, path: Routes.AssinaturaEletronica.Assinaturas, title: 'Assinaturas' },
        { icon: mdiCurrencyUsd, path: Routes.Financeiro.FinanceiroGrid, title: 'Financeiro' },
        { icon: mdiAccountTie, path: Routes.Advogados, title: 'Gestão de Advogados' },
        { icon: mdiAccountCog, path: Routes.Usuarios, title: 'Usuários' },
        { icon: mdiHelpCircle, path: Routes.Faq, title: 'FAQ / Traduções' },
        { icon: mdiCheckDecagram, path: Routes.Nps.Nps, title: 'NPS' },
      );
      sections.push(protectedSections);
    }
  }

  if (state.userInfo.data) {
    if (hasAccess(SectionsClaims.ADMIN_MASTER)) {
      protectedSections.items.push(
        { icon: mdiOfficeBuilding, path: Routes.Escritorios, title: 'Gestão de Escritórios' },
        { icon: mdiAccountCog, path: Routes.Usuarios, title: 'Usuários' },
      );
      sections.push(protectedSections);
    }
  }

  if (state.userInfo.data) {
    if (hasAccess(SectionsClaims.GESTOR)) {
      protectedSections.items.push(
        { icon: mdiAccountMultiple, path: Routes.Clientes, title: 'Clientes / Contatos' },
        { icon: mdiCurrencyUsd, path: Routes.Financeiro.FinanceiroGrid, title: 'Financeiro' },
        { icon: mdiAccountTie, path: Routes.Advogados, title: 'Gestão de Advogados' },
        { icon: mdiAccountCog, path: Routes.Usuarios, title: 'Usuários' },
      );
      sections.push(protectedSections);
    }
  }
  if (state.userInfo.data) {
    if (hasAccess(SectionsClaims.ADVOGADO_SOCIO)) {
      protectedSections.items.push(
        { icon: mdiViewGrid, path: Routes.VisaoGeral.Principal, title: 'Visão Geral' },
        { icon: mdiFolder, path: Routes.Documentos.Principal, title: 'Documentos' },
        { icon: mdiAccountMultiple, path: Routes.Clientes, title: 'Clientes / Contatos' },
        { icon: mdiPen, path: Routes.AssinaturaEletronica.Assinaturas, title: 'Assinaturas' },
        { icon: mdiCurrencyUsd, path: Routes.Financeiro.FinanceiroGrid, title: 'Financeiro' },
        { icon: mdiAccountTie, path: Routes.Advogados, title: 'Gestão de Advogados' },
        { icon: mdiAccountCog, path: Routes.Usuarios, title: 'Usuários' },
        { icon: mdiHelpCircle, path: Routes.Faq, title: 'FAQ / Traduções' },
        { icon: mdiCheckDecagram, path: Routes.Nps.Nps, title: 'NPS' },
      );
      sections.push(protectedSections);
    }
  }

  if (state.userInfo.data) {
    if (hasAccess(SectionsClaims.ADVOGADO) || hasAccess(SectionsClaims.ESTAGIARIO)) {
      protectedSections.items.push(
        //CHAT
        { icon: mdiViewGrid, path: Routes.VisaoGeral.Principal, title: 'Visão Geral' },
        { icon: mdiFolder, path: Routes.Documentos.Principal, title: 'Documentos' },
        { icon: mdiAccountMultiple, path: Routes.Clientes, title: 'Clientes / Contatos' },
        { icon: mdiPen, path: Routes.AssinaturaEletronica.Assinaturas, title: 'Assinaturas' },
        { icon: mdiAccountCog, path: Routes.Usuarios, title: 'Usuários' },
      );
      sections.push(protectedSections);
    }
  }

  return sections;
};
