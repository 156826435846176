const baseApiUrl = process.env.REACT_APP_ACESSOJURIS_API_URL;
const SSO_URL = process.env.REACT_APP_SSO_URL;
const notificationId = process.env.REACT_APP_ACESSOJURIS_NOTIFICACAO_ID;

export const BaseConfig = {
  baseApiUrl,
  SSO_URL,
  notificationId,
  TenantHeader: 'V1cxU2FXSXlVbXc9',
};
