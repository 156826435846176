import { Box } from '@mui/material';
import { OptGridRef, OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { ButtonWrapper } from '../../components/Button/Button.styles';
import { IconButton } from '../../components/Button/IconButton';
import FormDrawer from '../../components/Drawer/FormDrawer';
import HeaderContent from '../../components/Layout/HeaderContent';
import { GeralWrapper } from '../../components/Layout/Layout';
import Toolbar from '../../components/Toolbar/Toolbar';
import { AgendamentoForm } from '../../features/AgendamentoForm/AgendamentoForm';
import { AGENDAMENTO_DEFAULT, AgendamentoFormModel } from '../../models/Agendamento/Agendamento.model';
import useAgendamentoService from '../../services/agendamento.service';
import useClienteService from '../../services/cliente.service';
import { Calendario } from './Calendario';

export const Agendamentos = () => {
  const [agendamentoSearch, setAgendamentoSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [defaultAgendamento, setDefaultAgendamento] = useState<AgendamentoFormModel>(AGENDAMENTO_DEFAULT);
  const [refresh, setRefresh] = useState(false);

  const { obterAgenda, remover } = useAgendamentoService();
  const { obter } = useClienteService();

  const toggleDrawer = async () => {
    setIsOpen((prevState) => !prevState);
  };

  const closeDrawer = async () => {
    try {
      setIsOpen((prevState) => !prevState);
      setRefresh(true);
    } finally {
      setRefresh(false);
    }
  };

  function criarAgendamento(dataInicio?: Date, dataTermino?: Date) {
    setDefaultAgendamento(AGENDAMENTO_DEFAULT);
    if (dataInicio && !dataTermino) setDefaultAgendamento((prev) => ({ ...prev, inicio: new Date(dataInicio) }));
    if (dataInicio && dataTermino)
      setDefaultAgendamento((prev) => ({ ...prev, inicio: dataInicio, termino: new Date(dataTermino) }));
    toggleDrawer();
  }

  async function editarAgendamento(processoId: string) {
    try {
      const agendamentoData = await obterAgenda(processoId);
      const cliente = await obter(agendamentoData.data.clienteId);
      setDefaultAgendamento({
        clienteId: cliente.data,
        inicio: agendamentoData.data.inicio && new Date(agendamentoData.data.inicio.toString()),
        termino: agendamentoData.data.termino && new Date(agendamentoData.data.termino.toString()),
        processoId: agendamentoData.data.processoId,
        tipo: agendamentoData.data.tipo,
        id: agendamentoData.data.id,
      });
      toggleDrawer();
    } catch {}
  }

  function refreshGrid() {
    if (ref.current) {
      ref.current.refresh();
    }
    if (refCalendario.current) {
      refCalendario.current.refresh();
    }
  }

  const ref = useRef<OptGridRef>();
  const refCalendario = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [agendamentoSearch]);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Compromissos" />
      <Box sx={{ display: 'flex' }}>
        <GeralWrapper style={{ flex: 3 }}>
          <HeaderContent titulo={'Meus compromissos'} height={'fit-content'}>
            <ButtonWrapper>
              <IconButton color onClick={toggleDrawer} />
            </ButtonWrapper>
            <FormDrawer title={'Criar novo compromisso:'} isOpen={isOpen} toggleDrawer={criarAgendamento}>
              <AgendamentoForm
                toggleDrawer={closeDrawer}
                refreshGrid={refreshGrid}
                defaultAgendamento={defaultAgendamento}
              />
            </FormDrawer>
            <ButtonWrapper pb={'9px'}>
              {/* <ButtonMui
                $backgroundColor={Theme().insideTheme.light?.primary}
                onClick={() => {}}
                loadingIndicator={<CircularProgress color={'warning'} size={16} />}
                loading={false}>
                Notificar cliente
              </ButtonMui> */}
            </ButtonWrapper>
          </HeaderContent>
          {refresh ? (
            <OptLoading />
          ) : (
            <Calendario editarEvento={editarAgendamento} criarAgendamento={criarAgendamento} ref={refCalendario} />
          )}
        </GeralWrapper>
      </Box>
    </OptSideLayoutContent>
  );
};
