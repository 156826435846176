import { GrupoUsuarioSearchResponse } from '../models/dtos/GrupoUsuarioSearchResponse';
import { GrupoUsuario, GrupoUsuarioFormModel } from '../models/Usuario/grupoUsuario.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useGrupoUsuarioService() {
  const { get, post, put, remove, gridSearch } = useApi();

  const baseApi = 'api/grupo-usuario';

  const obter = async (id: string) => {
    return get<ApiResponse<GrupoUsuario>>(`${baseApi}/${id}`);
  };

  const obterGridGrupoUsuarios = async (data: SearchRequest<{}>) => {
    return gridSearch<GrupoUsuarioSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterTodosGrupoUsuarios = async () => {
    return get<ApiResponse<GrupoUsuario[]>>(`${baseApi}/obter-grupos`);
  };

  const enviar = async (data: GrupoUsuarioFormModel) => {
    return post(`${baseApi}`, data);
  };

  const alterar = async (id: string, data: GrupoUsuarioFormModel) => {
    return put<GrupoUsuario>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<GrupoUsuario>(`${baseApi}/${id}`);
  };

  return { obter, enviar, alterar, remover, obterTodosGrupoUsuarios, obterGridGrupoUsuarios };
}
