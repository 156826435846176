import { mdiBell } from '@mdi/js';
import { Box, Typography } from '@mui/material';
import { OptActionToolbar, OptAppBarAvatar } from '@optsol/react';
import { useEffect, useState } from 'react';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { Routes } from '../../routes';
import { useMsalService } from '../../services/auth/msal.service';
import { Colors } from '../../shared/colors';
import { usuarioInvalido } from '../../shared/mock';
import { IconButton } from '../Button/IconButton';
import * as S from './style';
import NotificacaoDrawer from '../Drawer/NotificacaoDrawer';
import useNotificacaoService from '../../services/notificacao.service';
import { NotificacaoTotalSearchResponse } from '../../models/dtos/NotificacaoSearchResponse.model';
import Theme from '../../shared/theme';
import InfoNotification from '../../features/InfoNotification/InfoNotification';

export interface ToolbarProps {
  title: string;
  goBackRoute?: string;
}

export default function Toolbar({ title, goBackRoute }: ToolbarProps) {
  const {
    state: { userInfo },
  } = useAuthenticationContext();

  const [numeroNotificacoes, setNumeroNotificacoes] = useState<NotificacaoTotalSearchResponse>();
  const [isOpen, setIsOpen] = useState(false);
  const msal = useMsalService();
  const { obterNumeroNotificacoes, marcarTodasLidas } = useNotificacaoService();
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  async function MarcarTodasLidas() {
    await marcarTodasLidas();
  }
  const closeDrawer = () => {
    obterNotificacoes();
    setIsOpen((prevState) => !prevState);
  };
  async function obterNotificacoes() {
    const data = await obterNumeroNotificacoes();
    setNumeroNotificacoes(data.data);
  }
  useEffect(() => {
    obterNotificacoes();
  }, []);

  const themeColor = Theme().insideTheme.light?.primary;

  return (
    <OptActionToolbar goBackRoute={goBackRoute ? goBackRoute : Routes.Home} title={title}>
      <Box sx={{ display: 'flex' }}>
        <IconButton icon={mdiBell} onClick={toggleDrawer} />
        {Number(numeroNotificacoes?.value) > 0 && Number(numeroNotificacoes?.value) < 100 ? (
          <S.styledBadge color={themeColor}>{numeroNotificacoes?.value}</S.styledBadge>
        ) : Number(numeroNotificacoes?.value) > 100 ? (
          <S.styledBadge color={themeColor}>+99</S.styledBadge>
        ) : null}
      </Box>
      <NotificacaoDrawer
        marcarComoLidas={MarcarTodasLidas}
        title={'Notificações'}
        isOpen={isOpen}
        toggleDrawer={closeDrawer}>
        <InfoNotification />
      </NotificacaoDrawer>
      <OptAppBarAvatar
        profile={
          userInfo.data
            ? {
                name: userInfo.data.nome,
                email: userInfo.data.email,
                avatarSrc: undefined,
                alternativeColor: Colors.gray7,
              }
            : usuarioInvalido
        }
        onManageProfile={() => null}
        onLogout={msal.logout}
      />
      <Typography pr={'25px'}>{userInfo.data ? userInfo.data.nome : usuarioInvalido.name}</Typography>
    </OptActionToolbar>
  );
}
