import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Colors } from '../../../shared/colors';

export const InfoCabecalhoStyled = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  background-color: ${(p) => p.color};
  margin-top: 28px;
  padding-left: 20px;
`;

export const StyledTypography = styled(Typography)`
  color: ${Colors.white};
  font-weight: 500;
`;
