import { UsuarioSearchResponse } from '../models/dtos/UsuarioSearchResponse.model';
import { Usuario, UsuarioFormModel } from '../models/Usuario/usuario.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useUsuarioService() {
  const { get, post, put, remove, gridSearch } = useApi();

  const baseApi = 'api/usuario';

  const obter = async (id: string) => {
    return get<ApiResponse<UsuarioSearchResponse>>(`${baseApi}/${id}`);
  };

  const obterGridUsuarios = async (data: SearchRequest<{}>) => {
    return gridSearch<UsuarioSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterTodosUsuarios = async () => {
    return get<ApiResponse<UsuarioSearchResponse[]>>(`${baseApi}`);
  };

  const enviar = async (data: UsuarioFormModel) => {
    return post(`${baseApi}`, data);
  };

  const alterar = async (id: string, data: UsuarioFormModel) => {
    return put<Usuario>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<Usuario>(`${baseApi}/${id}`);
  };

  return { obter, enviar, alterar, remover, obterTodosUsuarios, obterGridUsuarios };
}
