import { Nps, NpsFormModel } from '../models/Nps/Nps.model';
import { NpsResposta, NpsSearchResponse } from '../models/dtos/NpsSearchResponse.model';
import { ApiResponse } from '../shared/types/ApiResponse';
import useApi from '../shared/utils/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export default function useNpsService() {
  const { get, post, put, remove, gridSearch } = useApi();
  const baseApi = 'api/avaliacao';

  const obter = async (id: string) => {
    return get<ApiResponse<NpsSearchResponse>>(`${baseApi}/${id}`);
  };

  const obterGridNps = async (data: SearchRequest<{}>) => {
    return gridSearch<NpsSearchResponse>(`${baseApi}/paginated`, data);
  };

  const obterGridRespostas= async (data: SearchRequest<{}>, id: string) => {
    return gridSearch<NpsResposta>(`${baseApi}/${id}/respostas/paginated`, data);
  };

  const obterTodosNps = async () => {
    return get<Nps>(`${baseApi}`);
  };

  const enviar = async (data: NpsFormModel) => {
    return post(`${baseApi}`, data);
  };

  const alterar = async (id: string, data: NpsFormModel) => {
    return put<Nps>(`${baseApi}/${id}`, data);
  };

  const remover = async (id: string) => {
    return remove<Nps>(`${baseApi}/${id}`);
  };

  return { obter, enviar, alterar, remover, obterTodosNpss: obterTodosNps, obterGridNps,obterGridRespostas };
}
