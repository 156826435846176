import { Box } from '@mui/material';
import { OptDialog } from '@optsol/react';
import Theme from '../../shared/theme';
import ButtonModal from '../Button/ButtonModal';
import * as S from './styles';

export interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm(): Promise<void>;
}

export function ConfirmModal({ open, onClose, onConfirm }: ConfirmModalProps) {
  return (
    <OptDialog title="Deseja realmente deletar o usuário selecionado?" open={open} onClose={onClose}>
      <S.DialogWrapper>
        <Box display={'flex'} width="100%" gap={'10px'}>
          <ButtonModal $backgroundColor={Theme().insideTheme.light?.primary} tipo="cancel" onClick={onClose}>
            Cancelar
          </ButtonModal>
          <ButtonModal $backgroundColor={Theme().insideTheme.light?.primary} tipo="confirm" onClick={onConfirm}>
            Confirmar
          </ButtonModal>
        </Box>
      </S.DialogWrapper>
    </OptDialog>
  );
}
