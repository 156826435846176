import * as Yup from 'yup';

export interface Contato {
  nomeCompleto?: string;
  telefone?: string;
  descricaoContato?: string;
  email: string;
}

export type ContatoFormModel = Partial<Contato>;

export const CONTATO_DEFAULT: ContatoFormModel = {
  nomeCompleto: '',
  telefone: '',
  descricaoContato: '',
  email: '',
};

export const ContatoFormModelSchema: Yup.SchemaOf<ContatoFormModel> = Yup.object().shape({
  descricaoContato: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  nomeCompleto: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
});
