import { mdiCalendarCursor, mdiPrinter } from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import { OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { ButtonWrapper } from '../../../components/Button/Button.styles';
import ButtonMui from '../../../components/Button/ButtonMui';
import { IconButton } from '../../../components/Button/IconButton';
import CompromissoDrawer from '../../../components/Drawer/CompromissoDrawer';
import FormDrawer from '../../../components/Drawer/FormDrawer';
import HeaderContent from '../../../components/Layout/HeaderContent';
import ColunasAndamentoProcesso from '../../../components/Layout/InfoAndamentosProcesso';
import InfoCabecalho from '../../../components/Layout/InfoCabecalho';
import ColunasPartesProcesso from '../../../components/Layout/InfoPartesProcesso';
import ColunasInfoProcesso from '../../../components/Layout/InfoProcesso';
import { GeralWrapper } from '../../../components/Layout/Layout';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';
import { AgendamentoForm } from '../../../features/AgendamentoForm/AgendamentoForm';
import CompromissosList from '../../../features/AgendamentoForm/CompromissosList';
import { AndamentoForm } from '../../../features/ProcessoForm/AndamentoForm';
import { EditarAdvogadoForm } from '../../../features/ProcessoForm/EditarAdvogadoForm';
import { AgendamentoFormModel } from '../../../models/Agendamento/Agendamento.model';
import { AndamentosProcessoModel } from '../../../models/Processo/Processo.model';
import { CompromissoSearchResponse } from '../../../models/dtos/AgendamentoSearchResponse.model';
import {
  ProcessoGetResponse,
  andamentosProcesso,
  polosProcesso,
} from '../../../models/dtos/ProcessoSearchResponse.model';
import { Routes } from '../../../routes';
import useAgendamentoService from '../../../services/agendamento.service';
import useProcessoService from '../../../services/processo.service';
import useTraducaoService from '../../../services/traducao.service';
import { Colors } from '../../../shared/colors';
import Theme from '../../../shared/theme';

export const ResumoProcesso = () => {
  const { id } = useParams<{ id: string }>();
  const [isOpenTraducao, setIsOpenTraducao] = useState(false);
  const [isOpenAdvogado, setIsOpenAdvogado] = useState(false);
  const [isOpenCompromissos, setIsOpenCompromissos] = useState(false);
  const [isOpenCompromissoForm, setIsOpenCompromissoForm] = useState(false);
  const [andamentoAtual, setAndamentoAtual] = useState<andamentosProcesso[]>();
  const [compromissos, setCompromissos] = useState<CompromissoSearchResponse[]>();

  const [polos, setPolos] = useState<polosProcesso[]>();
  const [andamentoEditar, setAndamentoEditar] = useState<andamentosProcesso>();
  const [andamentoProcesso, setAndamentoProcesso] = useState<AndamentosProcessoModel>();
  const [processo, setProcesso] = useState<ProcessoGetResponse>();
  const bgColor = Theme().insideTheme.light?.primary;
  const [defaultAgendamento, setDefaultAgendamento] = useState<AgendamentoFormModel>({
    clienteId: '',
    processoId: id,
    tipo: '',
    inicio: null,
    termino: null,
  });
  const { obter, obterCompromissosProcessos } = useProcessoService();
  const { remover } = useAgendamentoService();

  const {
    state: { userInfo },
  } = useAuthenticationContext();

  const atualizarAndamentoAtual = (andamento: andamentosProcesso) => {
    if (andamento.andamento.traducao) {
      setAndamentoEditar(andamento);
    } else {
      setAndamentoEditar({
        id: andamento.id,
        identificador: andamento.identificador,
        data: andamento.data,
        andamento: {
          id: andamento.andamento.id,
          codigoNacional: andamento.andamento.codigoNacional,
          descricao: andamento.andamento.descricao,
          traducao: {
            codigo: andamento.andamento.codigoNacional,
            escritorioId: userInfo.data?.escritorio.id ? userInfo.data?.escritorio.id : '',
            textoOriginal: andamento.andamento.descricao,
            traducao: '',
            visivel: false,
          },
        },
      });
    }
    obterTraducaoForm();
  };

  async function removerCompromisso(id: string) {
    try {
      await remover(id);
    } catch (err) {
      console.log(err);
    } finally {
      obterCompromissos();
    }
  }

  const { obterTraducao } = useTraducaoService();
  async function obterTraducaoForm() {
    try {
      if (andamentoEditar) {
        const data = await obterTraducao(andamentoEditar.id);
        setAndamentoProcesso(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function obterCompromissos() {
    try {
      const data = await obterCompromissosProcessos(id);
      setCompromissos(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function obterProcesso(params: string) {
    try {
      const data = await obter(params);
      setProcesso(data.data);
      setAndamentoAtual(data.data.andamentosProcesso);
      setPolos(data.data.polos);
    } catch (err) {
      console.log(err);
    }
  }

  const toggleDrawerTraducao = () => {
    setIsOpenTraducao((prevState) => !prevState);
  };

  const toggleDrawerAdvogado = () => {
    setIsOpenAdvogado((prevState) => !prevState);
  };
  const toggleDrawerCompromissoForm = () => {
    setIsOpenCompromissoForm((prevState) => !prevState);
    obterCompromissos();
  };
  const toggleDrawerCompromisso = () => {
    setIsOpenCompromissos((prevState) => !prevState);
  };

  const componentRef = useRef(null);
  useEffect(() => {
    obterProcesso(id);
    obterCompromissos();
  }, []);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Resumo de processos" goBackRoute={Routes.VisaoGeral.Principal} />
      <GeralWrapper>
        <HeaderContent titulo={'Acompanhamento processual'} height={'40px'}>
          <ButtonWrapper>
            <ReactToPrint
              trigger={() => <IconButton icon={mdiPrinter} onClick={() => {}} />}
              content={() => componentRef.current}
            />
          </ButtonWrapper>
          <Box display="flex" gap={3} alignItems="center">
            <Box
              onClick={toggleDrawerCompromisso}
              width={45}
              height={45}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                border: `1px solid ${Colors.gray8}`,
                borderRadius: '50%',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                cursor: 'pointer',
              }}>
              <Icon path={mdiCalendarCursor} size={1} color={bgColor} />
            </Box>
            <ButtonMui onClick={toggleDrawerCompromissoForm} $backgroundColor={bgColor} style={{ width: '160px' }}>
              Criar compromisso
            </ButtonMui>

            <ButtonMui onClick={toggleDrawerAdvogado} $backgroundColor={bgColor} style={{ width: '160px' }}>
              Editar advogado
            </ButtonMui>
          </Box>
          {/* <ButtonMui onClick={() => {}} $backgroundColor={bgColor} style={{ width: '160px' }}>
            Enviar relatório
          </ButtonMui> */}
        </HeaderContent>
        <div ref={componentRef}>
          <InfoCabecalho titulo={'Informações do processo'} />
          {processo ? <ColunasInfoProcesso data={processo} /> : <OptLoading />}
          <InfoCabecalho titulo={'Partes do processo'} />
          {polos ? <ColunasPartesProcesso polos={polos} /> : <OptLoading />}

          <InfoCabecalho titulo={'Andamento do Processo'} />
          {andamentoAtual ? (
            <ColunasAndamentoProcesso
              overflow="scroll"
              andamentos={andamentoAtual}
              atualizarAndamentoAtual={atualizarAndamentoAtual}
              toggleDrawer={toggleDrawerTraducao}
            />
          ) : (
            <OptLoading />
          )}
        </div>
      </GeralWrapper>
      <FormDrawer
        title={'Criar novo compromisso:'}
        isOpen={isOpenCompromissoForm}
        toggleDrawer={toggleDrawerCompromissoForm}>
        <AgendamentoForm toggleDrawer={toggleDrawerCompromissoForm} defaultAgendamento={defaultAgendamento} />
      </FormDrawer>

      {compromissos && (
        <CompromissoDrawer
          title={'Compromissos do processo:'}
          isOpen={isOpenCompromissos}
          toggleDrawer={toggleDrawerCompromisso}>
          <CompromissosList removerCompromisso={removerCompromisso} compromisso={compromissos} loading={false} />
        </CompromissoDrawer>
      )}
      <FormDrawer
        title={'Traduzir andamento e notificar cliente'}
        isOpen={isOpenTraducao}
        toggleDrawer={toggleDrawerTraducao}>
        {andamentoAtual && andamentoEditar ? (
          <AndamentoForm
            defaultAndamento={andamentoEditar.andamento.traducao}
            toggleDrawer={toggleDrawerTraducao}
            refreshGrid={() => obterProcesso(id)}
            notificacao
          />
        ) : (
          <OptLoading />
        )}
      </FormDrawer>
      <FormDrawer title={'Edição de advogado'} isOpen={isOpenAdvogado} toggleDrawer={toggleDrawerAdvogado}>
        <EditarAdvogadoForm toggleDrawer={toggleDrawerAdvogado} processoId={id} />
      </FormDrawer>
    </OptSideLayoutContent>
  );
};
