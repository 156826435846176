import * as Yup from 'yup';

export interface Endereco {
  cep?: string;
  rua: string;
  bairro: string;
  cidade: string;
  estado: string;
  complemento?: string | null;
}

export type EnderecoFOrmModel = Endereco;

export const ENDERECO_DEFAULT: Endereco = {
  bairro: '',
  cep: '',
  cidade: '',
  estado: '',
  rua: '',
  complemento: '',
};

export const EnderecoFormModelSchema: Yup.SchemaOf<Endereco> = Yup.object().shape({
  cep: Yup.string().notRequired().typeError('Campo obrigatório'),
  rua: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  cidade: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  complemento: Yup.string().nullable().notRequired(),
  estado: Yup.string()
    .required('Campo obrigatório')
    .max(2, 'Excedeu o limite de caracteres')
    .typeError('Campo obrigatório'),
});
