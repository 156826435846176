import { ResponsivePie } from '@nivo/pie';
import React, { useCallback, useEffect, useState } from 'react';
import { chartTheme } from './config';

interface DataType {
  value: number;
  color: string;
  label: string;
  id: string;
}

interface Props<T extends object> {
  data: T[];
}

export function PieChart<T extends DataType>({ data }: Props<T>) {
  const [totalPropostas, setTotalPropostas] = useState(0);

  const somarTotal = useCallback((data: T[]) => {
    return data.reduce((total, valor) => total + valor.value, 0);
  }, []);

  useEffect(() => setTotalPropostas(somarTotal(data)), [somarTotal, data]);

  return (
    <>
      <ResponsivePie
        data={data}
        margin={{
          top: 4,
          right: 0,
          bottom: 4,
          left: 200,
        }}
        innerRadius={0.65}
        activeOuterRadiusOffset={4}
        activeInnerRadiusOffset={0}
        colors={data.map((value) => value.color)}
        arcLabel={(d) => (d.value === 0 ? '' : `${((d.value * 100) / totalPropostas).toFixed(0)}%`)}
        arcLabelsTextColor={'white'}
        theme={chartTheme}
        enableArcLinkLabels={false}
        legends={[
          {
            anchor: 'left',
            direction: 'column',
            itemHeight: 20,
            itemWidth: 100,
            itemsSpacing: 5,
            translateX: -175,
            symbolShape: 'circle',
            symbolSize: 10.5,
            symbolSpacing: 7,
          },
        ]}
      />
    </>
  );
}
