import { Route, Switch } from 'react-router-dom';
import MobileRedirect from '../pages/MobileRedirect/MobileRedirect';
import { GeralRoutes } from './GeralRoutes.routes';

export const Routes = {
  Home: '/',
  Configuracoes: '/configuracoes',
  Documentos: {
    Principal: '/documentos',
    Detalhes: (documentoId = ':documentoId') => `/documentos/${documentoId}`,
    Processos: (documentoId = ':documentoId', processoId = ':processoId') =>
      `/documentos/${documentoId}/processos/${processoId}`,
    ProcessoArquivos: (documentoId = ':documentoId', processoId = ':processoId', id = ':id') =>
      `/documentos/${documentoId}/processos/${processoId}/arquivos/${id}`,
  },

  Escritorios: '/escritorios',
  VisaoGeral: {
    Principal: '/visao-geral',
    ResumoProcesso: (id = ':id') => `/visao-geral/${id}`,
  },
  Agendamentos: '/agendamentos',
  AndamentosGerais: {
    Andamentos: '/andamentos',
    AndamentoDetalhado: (id = ':id') => `/andamentos/${id}`,
  },
  AssinaturaEletronica: {
    Assinaturas: '/assinaturas',
    DocumentoAssinatura: (id = ':id') => `/assinaturas/${id}`,
  },
  Financeiro: {
    FinanceiroGrid: '/financeiro',
    FinanceiroDetalhes: (id = ':id') => `/financeiro/${id}`,
  },
  Advogados: '/advogados',
  Contato: '/contato',
  Usuarios: '/usuarios',
  acessoCliente: '/acesso-cliente',
  Faq: '/perguntas-frequentes',
  Nps: {
    Nps: '/nps',
    NpsDetalhes: (id = ':id') => `/nps/${id}`,
  },
  Clientes: '/clientes',
} as const;

export const routes = (
  <Switch>
    <Route exact path={Routes.Home} component={GeralRoutes} />
    <Route exact path={Routes.acessoCliente} component={MobileRedirect} />
    <Route exact path={Routes.VisaoGeral.Principal} component={GeralRoutes} />
    <Route exact path={Routes.VisaoGeral.ResumoProcesso()} component={GeralRoutes} />
    <Route exact path={Routes.Agendamentos} component={GeralRoutes} />
    <Route exact path={Routes.AndamentosGerais.Andamentos} component={GeralRoutes} />
    <Route exact path={Routes.AndamentosGerais.AndamentoDetalhado()} component={GeralRoutes} />
    <Route exact path={Routes.Documentos.Principal} component={GeralRoutes} />
    <Route exact path={Routes.Documentos.Detalhes()} component={GeralRoutes} />
    <Route exact path={Routes.Documentos.Processos()} component={GeralRoutes} />
    <Route exact path={Routes.Documentos.ProcessoArquivos()} component={GeralRoutes} />
    <Route exact path={Routes.Clientes} component={GeralRoutes} />
    <Route exact path={Routes.AssinaturaEletronica.Assinaturas} component={GeralRoutes} />
    <Route exact path={Routes.AssinaturaEletronica.DocumentoAssinatura()} component={GeralRoutes} />
    <Route exact path={Routes.Financeiro.FinanceiroGrid} component={GeralRoutes} />
    <Route exact path={Routes.Financeiro.FinanceiroDetalhes()} component={GeralRoutes} />
    <Route exact path={Routes.Escritorios} component={GeralRoutes} />
    <Route exact path={Routes.Advogados} component={GeralRoutes} />
    <Route exact path={Routes.Usuarios} component={GeralRoutes} />
    <Route exact path={Routes.Configuracoes} component={GeralRoutes} />
    <Route exact path={Routes.Faq} component={GeralRoutes} />
    <Route exact path={Routes.Nps.Nps} component={GeralRoutes} />
    <Route exact path={Routes.Nps.NpsDetalhes()} component={GeralRoutes} />
  </Switch>
);
