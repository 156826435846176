import styled from 'styled-components';

export interface labelProps {
  shadow?: string;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImagePreview = styled.div<{ preview: string }>`
  height: 100%;
  background-image: url(${(p) => p.preview});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImagePreviewWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

export const ImageWrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  display: flex;
  background-color: ${(props) => props.color};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  overflow: hidden;
  width: 100%;
  max-width: 425px;
`;

export const Input = styled.input`
  display: none;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.color};
`;

export const Label = styled.label<labelProps>`
  background-color: ${(props) => props.color};
  border-radius: 20px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  box-shadow: ${({ shadow }) => shadow};
`;

export const titleWrapper = styled.h4`
  padding-bottom: 30px;
`;
