import styled from 'styled-components';

export const GeralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 25px 20px 25px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderFunctions = styled.div`
  display: flex;
  align-items: flex-start;
  height: 90%;
  gap: 20px;
`;

export const PapersWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;
  padding: 25px 0 20px 0;
`;
