import { OptUserProfile } from '@optsol/react';
import { Advogado } from '../models/Advogado/Advogado.model';
import { AndamentosGeralModel } from '../models/Andamentos/Andamentos.model';
import { AgendamentoSearchResponse } from '../models/dtos/AgendamentoSearchResponse.model';
import { NpsSearchResponse } from '../models/dtos/NpsSearchResponse.model';
import {
  AndamentosProcessoModel,
  InformacoesPartesProcessoModel,
  InformacoesProcessoModel,
} from '../models/Processo/Processo.model';

import { Colors } from './colors';

export const usuarioInvalido: OptUserProfile = {
  name: 'Usuário Inválido',
  email: 'invalido@gmail.com',
  avatarSrc: '',
  alternativeColor: Colors.gray7,
};

export const ProcessosDocumentos = [
  {
    nome: '31213123113214214',
    id: '3124123213123131',
  },
  {
    nome: '45213123113214213',
    id: '3321123213123131',
  },
  {
    nome: '54353212421321421',
    id: '23412321312313',
  },
];

export const DocumentoAssinatura = [
  {
    tipo: 'Classificação da assinatura',
    nome: 'Nome do documento',
    referencia: 'referência',
    id: '3124123213123131',
    data: '02/05/2022',
    status: 'Novo',
  },
  {
    tipo: 'Classificação da assinatura',
    nome: 'Nome do documento',
    referencia: 'referência',
    id: '3124123213123131',
    data: '02/05/2022',
    status: 'Pendente',
  },
  {
    tipo: 'Classificação da assinatura',
    nome: 'Nome do documento',
    referencia: 'referência',
    id: '3321123213123131',
    data: '12/05/2022',
    status: 'Assinado',
  },
  {
    tipo: 'Classificação da assinatura',
    nome: 'Nome do documento',
    referencia: 'referência',
    id: '23412321312313',
    data: '30/08/2022',
    status: 'Assinado',
  },
];

export const resumoMock = [
  {
    id: '2321412',
    tipo: 'Receita',
    previsto: '500.000,00',
    efetivo: '435.000,00',
    diferenca: '65.000,00',
  },
];

export const informacoesProcessoFake: InformacoesProcessoModel = {
  processo: '0005447-40.2015.8.08.0035',
  vara: 'Vila Velha 5ª Vara Civel',
  acao: 'Procedimento sumário',
  valor: 10000,
  peticaoInicial: '20150080777',
  dataDistribuicao: new Date('03/05/2015'),
  natureza: 'Cível',
  motivoDistribuicao: 'Distr. por sorteio',
  dataAjuizamento: new Date('04/05/2015'),
  assuntoPrincipal: 'Direito Civil',
};

export const partesProcessoFake: InformacoesPartesProcessoModel = {
  requerente: [
    'ADRIEL DE OLIVEIRA SILVA',
    'BRENNO ZONTA VILANOVA',
    'CAIO VITOR BROSEGHINI',
    'ELIOMAR PERTEL',
    'MARIA VILMA SILVA DAMASCENO',
    'PEDRO AUGUSTO CARVALHO',
    'PEDRO JEREMIAS VIEIRA',
  ],
  requerido: [
    'SOCIEDADE EDUCACIONAL DO ESPIRITO SANTO UNIVERSIDADE DE V V',
    'VINICIUS BERTOLDO ALVES-18373/ES',
    'GRACIELLE WALKEES SIMON - 16674/ES',
  ],
};

export const andamentosProcessoFake: AndamentosProcessoModel[] = [
  {
    data: new Date('04/19/2022'),
    textoOriginal: 'Remetidos os Autos (em grau de recurso) para TRIBUNAL DE JUSTIÇA DO ESPÍRITO SANTO.',
    traducao: 'O processo foi enviado para instância superior.',
    id: '',
    visivel: false,
    codigo: 0,
  },
  {
    data: new Date('04/19/2022'),
    textoOriginal: 'Remetidos os Autos (em grau de recurso) para TRIBUNAL DE JUSTIÇA DO ESPÍRITO SANTO.',
    traducao: null,
    id: '',
    visivel: false,
    codigo: 0,
  },
  {
    data: new Date('04/05/2022'),
    textoOriginal: 'Remetidos os Autos.',
    traducao: null,
    id: '',
    visivel: false,
    codigo: 0,
  },
  {
    data: new Date('04/01/2022'),
    textoOriginal: 'Petição recebida.',
    traducao: null,
    id: '',
    visivel: false,
    codigo: 0,
  },
];

export const npsDefault: NpsSearchResponse = {
  dataCriacao: new Date(),
  dataUltimoEnvio: new Date(),
  id: '',
  pergunta: '',
  resultado: {
    mediaAvaliacao: 0,
    qtdBom: 0,
    qtdMedio: 0,
    qtdRuim: 0,
    totalRespostas: 0,
  },
};

export const advogadoProcessoFake: Advogado[] = [
  {
    cpf: '',
    email: '',
    id: 'asd5asd84qww4dddff',
    nome: 'Roberto Santos',
    oab: '',
    telefone: '',
    ufOab: '',
  },
  {
    cpf: '',
    email: '',
    id: 'as56d465asd456sda',
    nome: 'Mario Vilella',
    oab: '',
    telefone: '',
    ufOab: '',
  },
  {
    cpf: '',
    email: '',
    id: 'as7d4as8d9sad8wqwq',
    nome: 'Maria Sodré',
    oab: '',
    telefone: '',
    ufOab: '',
  },
];

export const gridAgendamentosFake: AgendamentoSearchResponse[] = [
  {
    id: '1',
    cliente: 'Marcel Andreano',
    numeroProcesso: '00065325698712',
    tipoAgenda: 'Reunião',
    data: new Date(),
    hora: new Date(),
  },
  {
    id: '2',
    cliente: 'Ricardo Risco',
    numeroProcesso: '00065325585472',
    tipoAgenda: 'Reunião',
    data: new Date(),
    hora: new Date(),
  },
  {
    id: '3',
    cliente: 'Rodrigo Marcelo',
    numeroProcesso: '00065329999712',
    tipoAgenda: 'Reunião',
    data: new Date(),
    hora: new Date(),
  },
];

export const AndamentosFake: AndamentosGeralModel[] = [
  {
    id: '42135123412',
    numeroDoProcesso: '4215213423412',
    tribunal: 'TRF',
    cliente: 'Afonso D.',
    ultimoAndamento: new Date().toLocaleDateString(),
    parteContratada: new Date().toLocaleDateString(),
  },
  {
    id: '42135123412',
    numeroDoProcesso: '4215213423412',
    tribunal: 'STF',
    cliente: 'Claudio P.',
    ultimoAndamento: new Date().toLocaleDateString(),
    parteContratada: new Date().toLocaleDateString(),
  },
  {
    id: '42135123412',
    numeroDoProcesso: '4215213423412',
    tribunal: 'TJ',
    cliente: 'Junior S.',
    ultimoAndamento: new Date().toLocaleDateString(),
    parteContratada: new Date().toLocaleDateString(),
  },
];

export const ufArray = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const npsFake = [
  {
    id: '13214123414',
    pergunta: 'Em uma escala de 0 a 10, quanto você recomendaria nosso escritório?',
    dataDeCriacao: '17/05/2022',
    dataDoUltimoEnvio: '17/05/2022',
  },
  {
    id: '13214123414',
    pergunta: 'Em uma escala de 0 a 10, quanto você avalia nosso atendimento?',
    dataDeCriacao: '30/03/2022',
    dataDoUltimoEnvio: '30/03/2022',
  },
];

export const npsDetalhesFake = [
  {
    cliente: 'Vlad',
    nota: '10',
    comentario: 'Nota 10 para o desenvolvedor que fez',
    id: '12',
  },
  {
    cliente: 'Pedras',
    nota: '8.5',
    comentario: 'Se melhorar estraga',
    id: '12',
  },
  {
    cliente: 'Daniel',
    nota: '10',
    comentario: 'Atendeu todas as minhas expectativas',
    id: '12',
  },
];

