import { mdiPrinter } from '@mdi/js';
import { OptLayoutProvider, OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import ButtonMui from '../../../components/Button/ButtonMui';
import { IconButton } from '../../../components/Button/IconButton';
import FormDrawer from '../../../components/Drawer/FormDrawer';
import ColunasAndamentoProcesso from '../../../components/Layout/InfoAndamentosProcesso';
import InfoCabecalho from '../../../components/Layout/InfoCabecalho';
import { GeralWrapper, Header, HeaderFunctions } from '../../../components/Layout/Layout';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { AndamentoProcessoDetalheForm } from '../../../features/ProcessoForm/AndamentoProcessoDetalheForm';
import { andamentosProcesso } from '../../../models/dtos/ProcessoSearchResponse.model';
import useProcessoService from '../../../services/processo.service';

import Theme from '../../../shared/theme';

export const AndamentoDetalhado = () => {
  const { obter } = useProcessoService();
  const { id } = useParams<{ id: string }>();
  const [isOpenTraducao, setIsOpenTraducao] = useState(false);
  const [andamentoEditar, setAndamentoEditar] = useState<andamentosProcesso>();

  const [andamentoAtual, setAndamentoAtual] = useState<andamentosProcesso[]>();

  const toggleDrawerTraducao = () => {
    setIsOpenTraducao((prevState) => !prevState);
  };

  async function obterProcesso(params: string) {
    const data = await obter(params);
    setAndamentoAtual(data.data.andamentosProcesso);
  }
  const atualizarAndamentoAtual = (andamento: andamentosProcesso) => {
    setAndamentoEditar(andamento);
  };

  const componentRef = useRef(null);

  useEffect(() => {
    obterProcesso(id);
  }, []);
  //TODO: adicionar notificação ao enviar tradução
  return (
    <OptSideLayoutContent>
      <OptLayoutProvider theme={Theme().insideTheme}>
        <Toolbar title="Andamentos" />
        <GeralWrapper>
          <Header>
            <h3>Acompanhamento do processual</h3>
            <HeaderFunctions>
              <ButtonMui $backgroundColor="transparent">
                <ReactToPrint
                  trigger={() => <IconButton icon={mdiPrinter} onClick={() => {}} />}
                  content={() => componentRef.current}
                />
              </ButtonMui>
            </HeaderFunctions>
          </Header>
          <div ref={componentRef}>
            <InfoCabecalho titulo={'Andamento do Processo'} />
            {andamentoAtual ? (
              <ColunasAndamentoProcesso
                overflow="none"
                andamentos={andamentoAtual}
                atualizarAndamentoAtual={atualizarAndamentoAtual}
                toggleDrawer={toggleDrawerTraducao}
              />
            ) : (
              <OptLoading />
            )}
            <FormDrawer
              title={'Traduzir andamento e notificar cliente'}
              isOpen={isOpenTraducao}
              toggleDrawer={toggleDrawerTraducao}>
              {andamentoAtual ? (
                <AndamentoProcessoDetalheForm
                  processoId={id}
                  defaultAndamento={andamentoEditar}
                  toggleDrawer={toggleDrawerTraducao}
                  refreshGrid={() => obterProcesso(id)}
                  notificacao
                />
              ) : (
                <OptLoading />
              )}
            </FormDrawer>
          </div>
        </GeralWrapper>
      </OptLayoutProvider>
    </OptSideLayoutContent>
  );
};
