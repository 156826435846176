import * as Yup from 'yup';

export interface agendamentoDia {
  quantidadeCompromissosDia: number;
}

export interface EventoModel {
  title: string;
  start: Date;
  end: Date;
  id?: string;
}

export interface AgendamentoFormModel {
  id?: string;
  clienteId?: string | any;
  processoId?: string | any;
  tipo?: string;
  inicio: Date | null;
  termino: Date | null;
  // horaInicio: Date | string;
  // horaTermino: Date | string;
}

export interface dragCompromissoModel {
  id?: string;
  inicio: Date | null;
  termino: Date | null;
}

export const AGENDAMENTO_DEFAULT: AgendamentoFormModel = {
  id: '',
  clienteId: '',
  processoId: '',
  tipo: '',
  inicio: null,
  termino: null,

  // horaInicio: "08:00",
  // horaTermino: "08:00",
};

export const AgendamentoFormModelSchema: Yup.SchemaOf<AgendamentoFormModel> = Yup.object().shape({
  id: Yup.string().notRequired(),
  clienteId: Yup.mixed().notRequired(),
  processoId: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  tipo: Yup.string().required('Campo obrigatório'),
  inicio: Yup.date().required('Campo obrigatório').typeError('Campo obrigatório'),
  termino: Yup.date().required('Campo obrigatório').typeError('Campo obrigatório'),
  // horaInicio: Yup.string().required('Campo obrigatório'),
  // horaTermino: Yup.string().required('Campo obrigatório'),
});
