import { mdiCheckAll, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, ButtonBase, Drawer, Tooltip } from '@mui/material';
import { OptLayoutProvider } from '@optsol/react';
import { PropsWithChildren } from 'react';
import Theme from '../../shared/theme';
import { IconButton } from '../Button/IconButton';
import * as T from './Drawer.styles';

interface DrawerProps {
  title: string;
  isOpen: boolean;
  toggleDrawer: () => void;
  marcarComoLidas: () => void;
}

const NotificacaoDrawer = ({
  toggleDrawer,
  marcarComoLidas,
  title,
  isOpen,
  children,
}: PropsWithChildren<DrawerProps>) => {
  return (
    <OptLayoutProvider theme={Theme().insideTheme}>
      <Drawer open={isOpen} onClose={toggleDrawer} anchor="right" sx={{ zIndex: '1250' }}>
        <T.WrapperNotification>
          <T.HeaderNotificacao>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ButtonBase onClick={toggleDrawer} sx={{ borderRadius: '50%', padding: '2px' }}>
                <Icon path={mdiClose} size={0.8} color="#757985" />
              </ButtonBase>
              <h3 style={{ marginTop: '-4px' }}>{title}</h3>
            </Box>
            <Box sx={{ paddingTop: '2rem' }}>
              <Tooltip title="Marcar todas as notificações como lidas" placement="left-start">
                <div>
                  <IconButton icon={mdiCheckAll} size={1} color={true} onClick={marcarComoLidas} />
                </div>
              </Tooltip>
            </Box>
          </T.HeaderNotificacao>
          {children}
        </T.WrapperNotification>
      </Drawer>
    </OptLayoutProvider>
  );
};

export default NotificacaoDrawer;
