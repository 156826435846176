import { mdiDelete, mdiPencil } from '@mdi/js';
import { OptGrid, OptGridColumn, OptGridOptions, OptGridRef, OptGridRequest, OptGridResponse } from '@optsol/react';
import { forwardRef } from 'react';
import MascaraTelefoneDuplo from '../../components/MascaraTelefone/MascaraTelefoneDuplo';
import { AdvogadoSearchResponse } from '../../models/dtos/AdvogadoSearchResponse.model';
import { PaginatedSearchRequest } from '../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    AdvogadoSearchRequest?: PaginatedSearchRequest,
  ) => Promise<OptGridResponse<AdvogadoSearchResponse>>;
  removerAdvogado(advogadoId: string): Promise<void>;
  editarAdvogado(advogadoId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridAdvogados = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, removerAdvogado, editarAdvogado }, ref) => {
    const columns: OptGridColumn<AdvogadoSearchResponse>[] = [
      {
        title: 'id',
        field: 'id',
        hidden: true,
      },
      {
        title: 'Nome do Advogado',
        field: 'nome',
      },
      {
        title: 'Nome do Advogado',
        field: 'ufOab',
        hidden: true,
      },
      {
        title: 'OAB',
        field: 'oab',
        render: (data: AdvogadoSearchResponse) => {
          return <>{`${data.ufOab}${data.oab}`}</>;
        },
      },
      {
        title: 'Telefone',
        field: 'telefone',
        render: (data) => {
          return <MascaraTelefoneDuplo telefone={data.telefone} />;
        },
      },
    ];

    return (
      <>
        <OptGrid
          title=""
          ref={ref as any}
          data={carregar}
          options={options}
          columns={columns}
          headerTitlePosition="start"
          actionsPosition={'end'}
          actions={[
            (rowData) => ({
              icon: { path: mdiPencil },
              tooltip: 'Editar',
              onClick: () => editarAdvogado(rowData.id),
              disabled: false,
            }),
            (rowData) => ({
              icon: { path: mdiDelete },
              tooltip: 'Deletar',
              onClick: () => removerAdvogado(rowData.id),
              disabled: false,
            }),
          ]}
        />
      </>
    );
  },
);
