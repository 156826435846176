import { Box, Grid, Typography } from '@mui/material';
import { FinanceiroSearchResponse } from '../../models/dtos/FinanceiroSearchResponse.model';

import { Colors } from '../../shared/colors';
import toBRCurrency from '../../shared/functions';
import { StatusTextPagamentos } from '../StatusText/StatusTextFinanceiro';

interface Props {
  data: FinanceiroSearchResponse;
}

const InfoFinanceiro = ({ data }: Props) => {
  const quitacao = new Date(data.dataQuitacao);
  const vencimento = new Date(data.dataVencimento);
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid
        container
        item
        columnSpacing={'10vw'}
        px={2.5}
        py={1}
        bgcolor={Colors.gray10}
        display={'flex'}
        justifyContent={'space-between'}>
        <Grid item xs={6}>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Status:</Typography>
            <Typography>
              <StatusTextPagamentos statusDoPagamento={data.status} />
            </Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Forma de Pagamento:</Typography>
            <Typography>
              {data.formaDePagamento === 1
                ? 'Boleto'
                : data.formaDePagamento === 2
                ? 'Pix'
                : data.formaDePagamento === 3
                ? 'Crédito'
                : 'Débito'}
            </Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Vencimento:</Typography>
            <Typography>
              {vencimento.toLocaleString('pt-Br', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Valor Original:</Typography>
            <Typography>{toBRCurrency(Number(data.valorOriginal))}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Parcelas:</Typography>
            <Typography>{data.parcelas}</Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Quitação:</Typography>
            <Typography>
              {quitacao.toLocaleString('pt-Br', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }) === '01/01/1'
                ? '-'
                : quitacao.toLocaleString('pt-Br', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
            </Typography>
          </Box>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Valor Pago:</Typography>
            <Typography>{toBRCurrency(Number(data.valorPago))}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3.8}></Grid>
        <Grid item xs={12}>
          <Box display={'flex'} gap={'10px'} justifyContent="space-between">
            <Typography fontWeight={500}>Descrição:</Typography>
            <Typography>{data.descricao}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoFinanceiro;
