import { Autocomplete, TextField } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete';
import { useState } from 'react';
import { Controller, ControllerProps, FieldError, FieldValues, get, useFormState } from 'react-hook-form';

interface Props<
  T extends FieldValues,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<
      AutocompleteProps<{ [x: string]: any }, Multiple, DisableClearable, FreeSolo>,
      'defaultValue' | 'name' | 'renderInput'
    >,
    Omit<ControllerProps<T>, 'render'> {
  label?: string;
  placeholder?: string;
  errors?: FieldError | string;
}

export const AutoComplete = <T extends FieldValues>({
  placeholder,
  control,
  errors,
  name,
  label,
  options,
  ...rest
}: Props<T>) => {
  const [open, setOpen] = useState(false);
  const { errors: formErros } = useFormState<T>({ control });
  const error = get(formErros, name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: onInputChange, value } }) => (
          <Autocomplete
            fullWidth
            open={open}
            options={options}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            noOptionsText="Sem dados a exibir"
            getOptionLabel={(option: any) => option.value && option.value}
            value={options.find((option) => option.value === value) ?? null}
            onChange={onInputChange}
            onInputChange={(e) => {
              if (e) onInputChange(e);
            }}
            isOptionEqualToValue={(option) => option.value === value}
            {...rest}
            renderInput={(params) => {
              return <TextField {...params} fullWidth variant="outlined" placeholder={placeholder} />;
            }}
          />
        )}
      />
    </>
  );
};
