import { OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useState } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { ConfiguracoesForm } from '../../features/ConfiguracoesForm/ConfiguracoesForm';
import { Escritorio } from '../../models/Escritorio/Escritorio.model';
import useEscritorioService from '../../services/escritorio.service';

export const Configuracoes = () => {
  const [defaultEscritorio, setDefaultEscritorio] = useState<Escritorio>();

  const { obter } = useEscritorioService();

  async function editarEscritorios(escritorioId: string) {
    try {
      const escritorioData = await obter(escritorioId);
      if (escritorioData.data.dadosFinanceiros === null) {
        escritorioData.data.dadosFinanceiros = {
          chavePix: '',
          tipoConta: 0,
          nomeBanco: '',
          nomeTitular: '',
          agencia: '',
          numeroConta: '',
        };
      }
      setDefaultEscritorio(escritorioData.data);
    } catch {}
  }

  const {
    state: { userInfo },
  } = useAuthenticationContext();

  useEffect(() => {
    if (userInfo.data && userInfo.data.escritorio) {
      editarEscritorios(userInfo.data.escritorio.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptSideLayoutContent>
      <Toolbar title="Configurações do Sistema" />
      {defaultEscritorio ? (
        <ConfiguracoesForm defaultEscritorio={defaultEscritorio} label={'Alterar imagem'} />
      ) : (
        <OptLoading />
      )}
    </OptSideLayoutContent>
  );
};

