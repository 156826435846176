import { Box } from '@mui/material';
import { OptGridRef, OptLayoutProvider, OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import { GeralWrapper } from '../../../components/Layout/Layout';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { DocumentoResponse } from '../../../models/dtos/DocumentoResponse.model';
import { Routes } from '../../../routes';
import useProcessoService from '../../../services/processo.service';
import { objectToFormData } from '../../../shared/functions';
import Theme from '../../../shared/theme';
import { GridProcessosArquivos } from './GridProcessosArquivos';
import * as S from './styles/index';

export const ProcessosArquivos = () => {
  const { documentoId, processoId, id } = useParams<{ documentoId: string; processoId: string; id: string }>();
  const [arquivosGrid, setArquivosGrid] = useState<DocumentoResponse[]>();
  const idNumber = parseInt(id);

  const { obterTodosDocumentos, enviarDocumento, removerDocumento, obterDocumento } = useProcessoService();

  const { insideTheme } = Theme();

  async function obterDocumentos() {
    try {
      const documentosData = await obterTodosDocumentos(processoId);
      const result = documentosData.data.filter((props) => props.tipo === idNumber);
      setArquivosGrid(result);
    } finally {
    }
  }

  const handleChange = (files: File[]) => {
    const newFiles = files.map(async (data: File) => {
      const formData = objectToFormData({
        tipo: parseInt(id),
        arquivo: data,
      });
      return enviarDocumento(processoId, formData);
    });
    Promise.all(newFiles).then(() => obterDocumentos());
  };

  async function removerDocumentos(idDocumento: string) {
    try {
      await removerDocumento(processoId, idDocumento);
      obterDocumentos();
    } catch (error) {
      console.log(error);
    }
  }

  async function baixarDocumento(idDocumento: string, documentoNome: string) {
    try {
      obterDocumento(processoId, idDocumento, documentoNome);
    } catch (error) {
      console.log(error);
    }
  }

  const ref = useRef<OptGridRef>();

  useEffect(() => {
    if (ref.current) {
      ref.current.refresh();
    }
  }, [arquivosGrid]);

  useEffect(() => {
    obterDocumentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptSideLayoutContent>
      <Toolbar
        title="Gerenciador eletrônico de documentos"
        goBackRoute={Routes.Documentos.Processos(documentoId, processoId)}
      />
      <OptLayoutProvider theme={insideTheme}>
        <GeralWrapper>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', margin: ' 25px 0 ' }}>
            <S.InputDiv>
              <input
                accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx, .xls, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.zip,.rar,.7zip  "
                type="file"
                style={{ display: 'none' }}
                id="contained-button-file"
                multiple={true}
                onChange={(e) => {
                  if (e.target.files) {
                    const fileArray = Array.from(e.target.files);
                    handleChange(fileArray);
                  }
                }}
              />
              <label htmlFor="contained-button-file">
                <Button span label="Adicionar documentos" variant="primary" />
              </label>
            </S.InputDiv>
          </Box>
          {arquivosGrid ? (
            <GridProcessosArquivos
              baixarDocumento={baixarDocumento}
              removerDocumento={removerDocumentos}
              ref={ref}
              data={arquivosGrid}
            />
          ) : (
            <OptLoading size={50} color={'primary'} />
          )}
        </GeralWrapper>
      </OptLayoutProvider>
    </OptSideLayoutContent>
  );
};
