import { yupResolver } from '@hookform/resolvers/yup';
import {
  CircularProgress,
  debounce,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldErrors, get, useForm } from 'react-hook-form';
import ButtonMui from '../../components/Button/ButtonMui';
import { ErrorMessage, FormNumberFormat, FormSelect, FormTextField } from '../../components/Form';
import { SubmitWrapper } from '../../components/Form/DrawerForm';
import { FormAutoComplete } from '../../components/Form/FormAutocomplete';
import { FullscreenLoading } from '../../components/FullscreenLoading/FullscreenLoading';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { SectionsClaims } from '../../contexts/claims/sectionsClaims';
import { USER_CLIENTE_DEFAULT } from '../../models/Cliente/UserCliente.model';
import { EscritorioSearchResponse } from '../../models/dtos/EscritorioSearchResponse.model';
import { ENDERECO_DEFAULT } from '../../models/Endereco.model';
import { GrupoUsuarioFormModel } from '../../models/Usuario/grupoUsuario.model';
import { UsuarioFormModel, UsuarioFormModelSchema } from '../../models/Usuario/usuario.model';
import useEscritorioService from '../../services/escritorio.service';
import useGrupoUsuarioService from '../../services/grupoUsuario.service';
import useUsuarioService from '../../services/usuario.service';
import { ufArray } from '../../shared/mock';
import Theme from '../../shared/theme';
import { useCPNJ } from '../../shared/utils/functions';

interface UsuarioFormProps {
  defaultUsuario: UsuarioFormModel;
  toggleDrawer: () => void;
  refreshGrid(): void;
}

export function UsuarioForm({ defaultUsuario, toggleDrawer, refreshGrid }: UsuarioFormProps) {
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [tabValue, setTabValue] = useState(0);
  const [tabDisabled, setTabDisabled] = useState({
    tab1: false,
    tab2: false,
  });
  const [grupoUsuarios, setGrupoUsuarios] = useState<GrupoUsuarioFormModel[]>();
  const [responseOptions, setResponseOptions] = useState<readonly EscritorioSearchResponse[]>([]);
  const [escritorioIdDisabled, setEscritorioIdDisabled] = useState(false);
  const { enviar, alterar } = useUsuarioService();
  const { obterTodosGrupoUsuarios } = useGrupoUsuarioService();
  const [tipoCadastro, setTipoCadastro] = useState('');
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, getValues, formState, setValue, watch } = useForm<UsuarioFormModel>({
    defaultValues: defaultUsuario,
    resolver: yupResolver(UsuarioFormModelSchema),
  });
  const { buscarCNPJ } = useCPNJ();

  const { obterGridEscritorios } = useEscritorioService();
  const {
    state: { userInfo },
  } = useAuthenticationContext();
  useEffect(() => {
    obterGridEscritorios({
      page: 0,
      pageSize: 50,
      search: { termoBuscado: searchValue ?? '' },
    })
      .then((res) => {
        setResponseOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setResponseOptions([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const cadastroAdvogado = tipoCadastro === 'Advogado' || tipoCadastro === 'Advogado Sócio';
  const cadastroCliente = tipoCadastro === 'Cliente';

  const monitorarTelefone = watch('dadosAdvogado.telefone');
  const idUsuario = getValues('id');
  const idTipoUser = watch('grupoUsuarioId');
  const clienteCNPJ = getValues('dadosCliente.cnpj');
  const clienteCPF = getValues('cpf');

  const watchTipoPessoa = watch('dadosCliente.tipoPessoa');

  const changeHandler = useCallback((value: any) => {
    setSearchValue(value);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeHandler = useCallback(debounce(changeHandler, 300), []);
  const regexNonNumbers = /[^0-9]/g;

  const onSubmit = async (data: UsuarioFormModel) => {
    setBotaoDesabilitado(true);
    if (userInfo.data?.escritorio && userInfo.data?.escritorio.id !== null) {
      const submitData = { ...data, escritorioId: userInfo.data?.escritorio.id };
      if (data.dadosCliente?.cnpj) data.dadosCliente.cnpj = data.dadosCliente.cnpj.replace(regexNonNumbers, '');
      if (data.dadosCliente?.cpf) data.dadosCliente.cpf = data.dadosCliente.cpf.replace(regexNonNumbers, '');
      try {
        await enviar(submitData);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    } else {
      const formData: any = data;
      if (formData.escritorioId && formData.escritorioId.id) {
        const submitData = { ...data, escritorioId: formData.escritorioId.id };
        try {
          await enviar(submitData);
          toggleDrawer();
          refreshGrid();
        } catch {
          console.log('error');
        } finally {
          setBotaoDesabilitado(false);
        }
      } else {
        const submitData = { ...data, escritorioId: null };
        try {
          await enviar(submitData);
          toggleDrawer();
          refreshGrid();
        } catch {
          console.log('error');
        } finally {
          setBotaoDesabilitado(false);
        }
      }
    }
  };

  const onEdit = async (data: UsuarioFormModel) => {
    const formData: any = data;
    console.log(formData);
    const submitData = {
      ...data,
      escritorioId:
        formData.escritorioId && formData.escritorioId.id !== null
          ? formData.escritorioId.id
          : userInfo.data?.escritorio.id,
      cpf: formData.cpf ? formData.cpf : formData.dadosCliente.cnpj,
    };

    if (idUsuario) {
      try {
        setBotaoDesabilitado(true);
        await alterar(idUsuario, submitData);
        toggleDrawer();
        refreshGrid();
      } catch {
        console.log('error');
      } finally {
        setBotaoDesabilitado(false);
      }
    }
  };

  const onErrors = (data: FieldErrors<UsuarioFormModel>) => {
    console.log('onErrors', data);
  };

  useEffect(() => {
    const gruposRequest = async () => {
      try {
        const userGroups = await obterTodosGrupoUsuarios();

        if (userInfo.data && userInfo.data.grupoUsuario.nome !== SectionsClaims.ADMIN_MASTER) {
          const gruposOrdenados = userGroups.data
            .sort((a, b) => (a.nome > b.nome ? 1 : -1))
            .filter((grupo) => grupo.nome !== 'Administrador Master');
          setGrupoUsuarios(gruposOrdenados);
        } else {
          const gruposOrdenados = userGroups.data.sort((a, b) => (a.nome > b.nome ? 1 : -1));
          setGrupoUsuarios(gruposOrdenados);
        }
      } catch (error) {
        console.log(error);
      }
    };
    gruposRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkCEP = (e: any) => {
    setLoading(true);
    const cep = e.target.value.replace(/\D/g, '');
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue('dadosCliente.endereco', {
          cep: data.cep,
          bairro: data.bairro,
          rua: data.logradouro,
          cidade: data.localidade,
          estado: data.uf,
          complemento: data.complemento,
        });
      })
      .finally(() => setLoading(false));
  };

  const checkCNPJ = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      setLoading(true);
      const cnpj = e.target.value;
      buscarCNPJ(cnpj)
        .then((res) => {
          if (res) {
            setValue('dadosCliente.endereco', res.endereco);
            setValue('dadosCliente.nomeFantasia', res.nomeEmpresa);
            setValue('dadosCliente.nomeEmpresa', res.razaoSocial);
            setValue('cpf', cnpj.replace(regexNonNumbers, ''));
            if (res.telefone.length === 10) setValue('dadosCliente.telefone.fixo', res.telefone);
            if (res.telefone.length > 10) setValue('dadosCliente.telefone.celular', res.telefone);
          }
        })
        .catch(() => {
          setValue('dadosCliente.endereco', { ...ENDERECO_DEFAULT });
        })
        .finally(() => setLoading(false));
    },
    [buscarCNPJ, setValue],
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setValue('dadosCliente', USER_CLIENTE_DEFAULT);
  };

  useEffect(() => {
    if (userInfo.data?.grupoUsuario.id === idTipoUser) {
      setValue('escritorioId', null);
    }
  }, [idTipoUser, setValue, userInfo.data?.grupoUsuario.id]);

  useEffect(() => {
    const grupo = grupoUsuarios?.find((data) => data.id === idTipoUser);
    if (!grupo) return;
    if (grupo.nome === 'Advogado Sócio') {
      setTipoCadastro(grupo.nome ?? '');
    } else if (grupo.nome === 'Advogado') {
      setTipoCadastro(grupo.nome ?? '');
    } else if (grupo.nome === 'Cliente') {
      setTipoCadastro(grupo.nome ?? '');
    } else {
      setTipoCadastro('');
    }

    setValue('grupoUsuarioTipo', grupo.nome);
  }, [grupoUsuarios, idTipoUser, setValue]);

  useEffect(() => {
    if (clienteCNPJ) {
      setTabDisabled({ ...tabDisabled, tab1: true });
      setTabValue(1);
    } else if (clienteCPF) {
      setTabDisabled({ ...tabDisabled, tab2: true });
      setValue('dadosCliente.cpf', clienteCPF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleDrawer]);

  useEffect(() => {
    if (watchTipoPessoa === 2) {
      setTabValue(1);
    }
  }, [toggleDrawer, watchTipoPessoa]);

  useEffect(() => {
    if (tabValue === 0) {
      setValue('dadosCliente.tipoPessoa', 1);
    } else {
      setValue('dadosCliente.tipoPessoa', 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  return (
    <>
      {loading && <FullscreenLoading />}
      <Grid container item rowSpacing={3} columnSpacing={5} px={2.5} pt={2.5}>
        {grupoUsuarios && (
          <Grid item xs={6}>
            <Controller
              name="grupoUsuarioId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <InputLabel id="tipoUsuario">Tipo de Usuário</InputLabel>
                  <Select
                    onChange={(data) => {
                      const escritorioIdDisabled = data.target.value === userInfo.data?.grupoUsuario.id;
                      setEscritorioIdDisabled(escritorioIdDisabled);
                      onChange(data.target.value);
                    }}
                    labelId="tipoUsuario"
                    value={value ?? ''}
                    label="Tipo de usuário"
                    variant="outlined">
                    {grupoUsuarios.map((grupo) => (
                      <MenuItem key={grupo.id} value={grupo.id}>
                        {grupo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <ErrorMessage error={get(formState.errors, 'grupoUsuarioId.id')} />
          </Grid>
        )}
        <Grid item xs={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField onChange={onChange} value={value ?? ''} label="Nome" variant="outlined" fullWidth />
              </>
            )}
          />
          <ErrorMessage error={get(formState.errors, 'nome')} />
        </Grid>
        {!cadastroCliente && (
          <Grid item xs={6}>
            <FormNumberFormat
              disabled={idUsuario ? true : false}
              control={control}
              type="text"
              format="###.###.###-##"
              name="cpf"
              label="CPF"
              placeholder="xxx.xxx.xxx-xx"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormTextField control={control} name="email" label="E-mail" placeholder="email@email.com" />
        </Grid>

        {userInfo.data && !escritorioIdDisabled && userInfo.data.grupoUsuario.nome === SectionsClaims.ADMIN_MASTER ? (
          <Grid item xs={6}>
            <Controller
              name="escritorioId"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <FormAutoComplete
                  options={responseOptions}
                  disabled={escritorioIdDisabled}
                  noOptionsText="Nenhum resuldado encontrado"
                  value={value ?? null}
                  onBlur={onBlur}
                  onChange={(event, value) => {
                    onChange(value);
                  }}
                  onInputChange={(event, value) => {
                    debounceChangeHandler(value);
                  }}
                  getOptionLabel={(option: any) => option.nome}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="Buscar por Escritório"
                      label="Buscar por Escritório"
                    />
                  )}
                />
              )}
            />
          </Grid>
        ) : null}
        {cadastroAdvogado && (
          <>
            <Grid item xs={6}>
              <FormSelect control={control} name="dadosAdvogado.ufOab" label="UF OAB" placeholder="UF OAB">
                {ufArray.map((props, i) => {
                  return (
                    <MenuItem key={i} value={props}>
                      {props}
                    </MenuItem>
                  );
                })}
              </FormSelect>
            </Grid>
            <Grid item xs={6}>
              <FormNumberFormat
                control={control}
                format={'######'}
                name="dadosAdvogado.oab"
                label="Número OAB "
                placeholder="000000"
              />
            </Grid>
            <Grid item xs={6}>
              <FormNumberFormat
                control={control}
                format={String(monitorarTelefone).length === 11 ? '(##) # ####-####' : '(##) ####-#####'}
                name="dadosAdvogado.telefone"
                label="Telefone "
                placeholder="(00) 0000-0000"
              />
            </Grid>
          </>
        )}
        {cadastroCliente && (
          <>
            <Tabs
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example">
              <Tab disabled={tabDisabled.tab1} sx={{ width: '45%', marginLeft: '50px' }} label="Pessoa Física (CPF)" />
              <Tab disabled={tabDisabled.tab2} sx={{ width: '45%' }} label="Pessoa Jurídica (CNPJ)" />
            </Tabs>
            {tabValue === 0 ? (
              <>
                <Grid item xs={6}>
                  <FormNumberFormat
                    control={control}
                    type="text"
                    format="###.###.###-##"
                    disabled={idUsuario ? true : false}
                    name="dadosCliente.cpf"
                    onBlur={(data) => {
                      setValue('cpf', data.target.value.replace(regexNonNumbers, ''));
                    }}
                    label="CPF"
                    placeholder="xxx.xxx.xxx-xx"
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dadosCliente.dataNascimento"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <DatePicker
                          label="Data de Nascimento"
                          value={value}
                          onChange={onChange}
                          slotProps={{
                            textField: {
                              sx: {
                                width: '100%',
                              },
                            },
                          }}
                        />
                      </>
                    )}
                  />
                  <ErrorMessage error={get(formState.errors, 'dadosCliente.dataNascimento')} />
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    control={control}
                    name="dadosCliente.estadoCivil"
                    label="Estado civil"
                    placeholder="Estado civil">
                    <MenuItem value={1}>Solteiro</MenuItem>
                    <MenuItem value={2}>Casado</MenuItem>
                    <MenuItem value={3}>Separado</MenuItem>
                    <MenuItem value={4}>Viúvo</MenuItem>
                    <MenuItem value={5}>União estável</MenuItem>
                  </FormSelect>
                </Grid>
                <Grid item xs={6}>
                  <FormNumberFormat
                    control={control}
                    format="(##) #####-####"
                    name="dadosCliente.telefone.celular"
                    label="Celular"
                    placeholder="(00) 0 0000-0000"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormNumberFormat
                    control={control}
                    format="(##) ####-####"
                    name="dadosCliente.telefone.fixo"
                    label="Telefone Fixo"
                    placeholder="(00) 0000-0000"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormNumberFormat
                    control={control}
                    format="#####-###"
                    name="dadosCliente.endereco.cep"
                    label="CEP"
                    placeholder="CEP"
                    onBlur={checkCEP}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.endereco.estado"
                    label="Estado"
                    placeholder="Estado"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.endereco.cidade"
                    label="Cidade"
                    placeholder="Cidade"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.endereco.bairro"
                    label="Bairro"
                    placeholder="Bairro"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField control={control} name="dadosCliente.endereco.rua" label="Rua" />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.endereco.complemento"
                    label="Complemento"
                    placeholder="Complemento"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <FormNumberFormat
                    name="dadosCliente.cnpj"
                    control={control}
                    type="text"
                    disabled={idUsuario ? true : false}
                    format="##.###.###/####-##"
                    fullWidth
                    customInput={TextField}
                    label="CNPJ"
                    onBlur={checkCNPJ}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="dadosCliente.nomeEmpresa"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          value={value ?? ''}
                          label="Nome da empresa"
                          variant="outlined"
                          fullWidth
                        />
                      </>
                    )}
                  />
                  <ErrorMessage error={get(formState.errors, 'nomeEmpresa')} />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.nomeFantasia"
                    label="Nome fantasia"
                    placeholder="Nome fantasia"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormNumberFormat
                    control={control}
                    format="(##) # ####-####"
                    name="dadosCliente.telefone.celular"
                    label="Celular"
                    placeholder="(00) 0 0000-0000"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormNumberFormat
                    control={control}
                    format="(##) ####-####"
                    name="dadosCliente.telefone.fixo"
                    label="Telefone fixo"
                    placeholder="(00)  0000-0000"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormNumberFormat
                    control={control}
                    format="#####-###"
                    name="dadosCliente.endereco.cep"
                    label="CEP"
                    placeholder="CEP"
                    onBlur={checkCEP}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.endereco.estado"
                    label="Estado"
                    placeholder="Estado"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.endereco.cidade"
                    label="Cidade"
                    placeholder="Cidade"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.endereco.bairro"
                    label="Bairro"
                    placeholder="Bairro"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField control={control} name="dadosCliente.endereco.rua" label="Rua" />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="dadosCliente.endereco.complemento"
                    label="Complemento"
                    placeholder="Complemento"
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <SubmitWrapper>
        <ButtonMui
          $backgroundColor={Theme().insideTheme.light?.primary}
          onClick={handleSubmit(idUsuario ? onEdit : onSubmit, onErrors)}
          loadingIndicator={<CircularProgress color={'warning'} size={16} />}
          loading={botaoDesabilitado || loading}
          disabled={loading}>
          Salvar
        </ButtonMui>
      </SubmitWrapper>
    </>
  );
}
