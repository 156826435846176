import { Box, Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import { polosProcesso } from '../../models/dtos/ProcessoSearchResponse.model';
import { Colors } from '../../shared/colors';

interface Props {
  polos: polosProcesso[] | undefined;
}

const InfoPartesProcesso = ({ polos }: Props) => {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid
        container
        item
        columnSpacing={5}
        px={2.5}
        pt={1}
        pb={2}
        bgcolor={Colors.gray10}
        display={'flex'}
        justifyContent={'space-between'}>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography fontWeight={500}>Polo ativo:</Typography>
            {polos?.length
              ? polos.map((item, index) => {
                  return (
                    <>
                      {item.tipoPolo === 1 ? (
                        <Fragment key={index}>
                          <p style={{ margin: 0 }}>{item.nome}</p>
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })
              : null}
          </Box>
          <Box display={'flex'} flexDirection={'column'} mt={1}>
            <Typography fontWeight={500}>Polo passivo:</Typography>
            {polos?.length
              ? polos.map((item, index) => {
                  return (
                    <>
                      {item.tipoPolo === 2 ? (
                        <Fragment key={index}>
                          <p style={{ margin: 0 }}>{item.nome}</p>
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })
              : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoPartesProcesso;
