import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { PropsWithChildren } from 'react';
import { Colors } from '../../shared/colors';

interface ButtonMuiProps extends LoadingButtonProps {
  tipo: 'cancel' | 'confirm';
  $backgroundColor: string | undefined;
}

export default function ButtonModal({ children, tipo, $backgroundColor, ...props }: PropsWithChildren<ButtonMuiProps>) {
  const THEME = {
    confirm: {
      bg: $backgroundColor,
      color: '#FFFFFF',
      border: $backgroundColor,
      onHover: `
          box-shadow: 0 3px 6px rgba(0,0,0,.2);
          `,
    },
    cancel: {
      bg: 'transparent',
      color: '#757985',
      border: Colors.gray7,
      onHover: `
         color: ${$backgroundColor};
          `,
    },
  };

  return (
    <LoadingButton
      sx={{
        color: THEME[tipo].color,
        backgroundColor: THEME[tipo].bg,
        textTransform: 'capitalize',
        fontSize: '15px',
        width: '50%',
        border: `1px solid ${THEME[tipo].border}`,
        '&:hover': {
          backgroundColor: $backgroundColor,
        },
        '&:disabled': {
          backgroundColor: Colors.gray8,
        },
      }}
      {...props}>
      {children}
    </LoadingButton>
  );
}
