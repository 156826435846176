import * as Yup from 'yup';
import { validarCPF } from '../../shared/utils/functions';
import { UserAdvogadoFormModel, UserAdvogadoFormModelSchema } from '../Advogado/UserAdvogado.model';
import { UserClienteFormModel, UserClienteFormModelSchema } from '../Cliente/UserCliente.model';
import { Escritorio } from '../Escritorio/Escritorio.model';

export interface Usuario {
  email: string;
  nome: string;
  grupoUsuarioId: string;
  escritorioId: string | null | Escritorio;
  dadosAdvogado?: UserAdvogadoFormModel | undefined;
  dadosCliente?: UserClienteFormModel | undefined;
  id?: string;
  cpf: string;
  grupoUsuarioTipo: string;
}
export type UsuarioFormModel = Partial<Usuario>;

export const USUARIO_DEFAULT: UsuarioFormModel = {
  nome: '',
  email: '',
  grupoUsuarioId: '',
  escritorioId: null,
  cpf: '',
  dadosAdvogado: undefined,
  dadosCliente: undefined,
  grupoUsuarioTipo: '',
};

export const UsuarioFormModelSchema: Yup.SchemaOf<UsuarioFormModel> = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório').typeError('Campo obrigatório'),
  grupoUsuarioId: Yup.string().required('Campo Obrigatório'),
  cpf: Yup.string()
    .typeError('CPF Inválido')
    .when('dadosCliente.cnpj', {
      is: (cnpj: string) => !cnpj,
      then: Yup.string().test('cpfTest', 'CPF Inválido', (value) => !value || validarCPF(value)),
      otherwise: Yup.string().notRequired(),
    }),
  escritorioId: Yup.mixed().notRequired(),
  id: Yup.string().notRequired(),
  dadosAdvogado: Yup.object().when('grupoUsuarioTipo', {
    is: (value: string) => {
      if (value === 'Advogado') return value === 'Advogado';
      if (value === 'Advogado Sócio') return value === 'Advogado Sócio';
    },
    then: UserAdvogadoFormModelSchema.required(),
    otherwise: Yup.object().notRequired(),
  }),
  dadosCliente: Yup.object().when('grupoUsuarioTipo', {
    is: (value: string) => {
      return value === 'Cliente';
    },
    then: UserClienteFormModelSchema.required(),
    otherwise: Yup.object().notRequired(),
  }),
  grupoUsuarioTipo: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
});
