import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, ButtonBase, Drawer, Typography } from '@mui/material';
import { OptDialog, OptLayoutProvider } from '@optsol/react';
import { PropsWithChildren, useState } from 'react';
import { Colors } from '../../shared/colors';
import Theme from '../../shared/theme';
import ButtonModal from '../Button/ButtonModal';
import * as T from './Drawer.styles';

interface DrawerProps {
  title: string;
  isOpen: boolean;
  toggleDrawer: () => void;
  width?: string;
}

const FormDrawer = ({ title, isOpen, toggleDrawer, children, width }: PropsWithChildren<DrawerProps>) => {
  const [openDialog, setOpenDialog] = useState(false);

  const toggleDialog = () => {
    setOpenDialog((prevState) => !prevState);
  };

  function handleCloseAll() {
    toggleDrawer();
    toggleDialog();
  }

  return (
    <OptLayoutProvider theme={Theme().insideTheme}>
      <Drawer open={isOpen} onClose={toggleDialog} anchor="right" sx={{ zIndex: '1250' }}>
        <Box
          sx={{
            padding: '10px',
            display: 'flex',
            maxWidth: '750px',
            width: `${width} ? ${width} : '48vw'`,
            flexDirection: 'column',
            gap: '15px',
          }}>
          <OptDialog title="Tem certeza que deseja fechar?" open={openDialog} onClose={toggleDialog}>
            <T.DialogWrapper>
              <Typography mt={'-10px'} color={Colors.gray} fontSize={'14px'}>
                As informações de cadastro não serão salvas!
              </Typography>
              <Box display={'flex'} width="100%" gap={'10px'}>
                <ButtonModal $backgroundColor={Theme().insideTheme.light?.primary} tipo="cancel" onClick={toggleDialog}>
                  Cancelar
                </ButtonModal>
                <ButtonModal
                  $backgroundColor={Theme().insideTheme.light?.primary}
                  tipo="confirm"
                  onClick={handleCloseAll}>
                  Fechar
                </ButtonModal>
              </Box>
            </T.DialogWrapper>
          </OptDialog>
          <T.Header>
            <ButtonBase onClick={toggleDialog} sx={{ borderRadius: '50%', padding: '2px' }}>
              <Icon path={mdiClose} size={0.8} color="#757985" />
            </ButtonBase>
            <h3 style={{ marginTop: '-4px' }}>{title}</h3>
          </T.Header>
          {children}
        </Box>
      </Drawer>
    </OptLayoutProvider>
  );
};

export default FormDrawer;
