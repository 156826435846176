import { LoadingButton } from '@mui/lab';
import { ButtonBase } from '@mui/material';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { Colors } from '../../shared/colors';

const COLORS = {
  red: '#F84735',
  primary: Colors.primaryContrast,
  foreground: '#274060',
};

const THEME = {
  danger: {
    bg: '#F84735',
    color: '#FFFFFF',
    border: '',
    onHover: `
    box-shadow: 0 3px 6px rgba(0,0,0,.2);
    `,
    disabled: {
      bg: transparentize(0.75, COLORS.red),
      color: COLORS.red,
    },
  },
  primary: {
    bg: Colors.primaryContrast,
    color: '#FFFFFF',
    border: Colors.primaryContrast,
    onHover: `
    box-shadow: 0 3px 6px rgba(0,0,0,.2);
    `,
    disabled: {
      bg: transparentize(0.44, COLORS.primary),
      color: '#fff',
    },
  },
  text: {
    bg: 'transparent',
    color: '#757985',
    border: Colors.gray7,
    onHover: `
   color: ${Colors.primaryContrast};
    `,
    disabled: {
      bg: transparentize(0.44, '#508AC9'),
      color: COLORS.foreground,
    },
  },
};

export const Wrapper = styled.button<{
  variant: 'danger' | 'primary' | 'text';
}>`
  padding: 6px 8px;

  font-size: 15px;

  height: 40px;

  cursor: pointer;

  border-radius: 4px;

  border: 1px solid ${(p) => THEME[p.variant].border};

  color: ${(p) => THEME[p.variant].color};
  background-color: ${(p) => THEME[p.variant].bg};

  &:hover,
  &:focus {
    ${(p) => THEME[p.variant].onHover};
  }
  &:disabled {
    background-color: ${(p) => THEME[p.variant].disabled.bg};
    color: ${(p) => THEME[p.variant].disabled.color};
    pointer-events: none;
    border-color: transparent;
    opacity: 0.5;
  }
`;

export const SpanWrapper = styled.span<{
  variant: 'danger' | 'primary' | 'text';
}>`
  padding: 9px 8px;
  font-size: 15px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${(p) => p.color};
  color: ${(p) => THEME[p.variant].color};
  background-color: ${(p) => p.color};

  &:hover,
  &:focus {
    ${(p) => THEME[p.variant].onHover};
  }
  &:disabled {
    background-color: ${(p) => THEME[p.variant].disabled.bg};
    color: ${(p) => THEME[p.variant].disabled.color};
    pointer-events: none;
    border-color: transparent;
    opacity: 0.5;
  }
`;

export const ButtonMui = styled(LoadingButton)`
  color: white;
  background-color: ${Colors.primaryContrast};
  text-transform: capitalize;
  font-size: 15px;
  &:hover {
    background-color: ${Colors.primaryTints.tint2};
  }
  &:disabled {
    background-color: ${Colors.gray8};
  }
`;

interface StyledPlusButtonProps {
  p?: string;
}
export const StyledPlusButton = styled(ButtonBase)<StyledPlusButtonProps>`
  padding: ${({ p }) => p};
  border-radius: 50%;
`;
export const StyledAppButton = styled(ButtonBase)<StyledPlusButtonProps>`
  border-radius: 15px;
  padding: 5px 0px;
  min-width: 13rem;
  border: 1px solid ${(p) => p.color};
`;

interface ButtonWrapperProps {
  pb?: string;
}
// TODO: Adicionar padding bottom de 9px padrão
export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  height: 100%;
  padding-bottom: ${({ pb }) => pb};
  align-items: center;
  justify-content: center;
`;
